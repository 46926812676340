import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import {
    Box,
    Container,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    useToast,
    Text,
    Spinner,
    VStack,
    FormErrorMessage,
    Select,
} from '@chakra-ui/react';
import { useAuth } from '../contexts/AuthContext';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useNavigate, useLocation } from "react-router-dom";

const GET_USER = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      firstName
      lastName
      phoneNumber
      inviteCode
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      id
      firstName
      lastName
      phoneNumber
      inviteCode
    }
  }
`;

interface User {
    id: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    inviteCode: string | null;
}


const countries = [
    { code: '+61', name: 'Australia' },
    { code: '+1', name: 'United States' },
    { code: '+44', name: 'United Kingdom' },
];

export const ProfileDetails: React.FC = () => {
    const { user, updateUserData } = useAuth();
    const toast = useToast();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    // const [phoneNumber, setPhoneNumber] = useState('');
    const [newInviteCode, setNewInviteCode] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const [countryCode, setCountryCode] = React.useState('+61');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [phoneError, setPhoneError] = React.useState('');

    const { loading, error, data } = useQuery<{ getUser: User }>(GET_USER, {
        variables: { id: user?.id },
        skip: !user?.id,
        onCompleted: (data) => {
            setFirstName(data.getUser.firstName || '');
            setLastName(data.getUser.lastName || '');
            setPhoneNumber(data.getUser.phoneNumber || '');
            setNewInviteCode(data.getUser.inviteCode || '');
        },
    });

    const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER, {
        onCompleted: () => {
            toast({
                title: "Profile updated",
                description: "Your profile has been successfully updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        },
        onError: (error) => {
            console.error('Update error:', error);
            console.error('GraphQL Errors:', error.graphQLErrors);
            console.error('Network Error:', error.networkError);
            toast({
                title: "Update failed",
                description: error.message || "An unknown error occurred",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const formatPhoneNumber = (countryCode: string, phoneNumber: string): string => {
        // Remove any leading zero
        const trimmedNumber = phoneNumber.replace(/^0+/, '');
        // Combine country code and trimmed number
        return `${countryCode}${trimmedNumber}`;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!user?.id) {
            toast({
                title: "Error",
                description: "User not found",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;


        }
        try {

            // Format the phone number
            const formattedPhoneNumber = formatPhoneNumber(countryCode, phoneNumber);

            const result = await updateUser({
                variables: {
                    id: user.id,
                    input: {
                        firstName,
                        lastName,
                        phoneNumber: formattedPhoneNumber,
                        inviteCode: newInviteCode || undefined
                    },
                },
            });
            if (result.data && result.data.updateUser) {
                updateUserData(result.data.updateUser);
                toast({
                    title: "Profile updated",
                    description: "Your profile has been successfully updated.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                // navigate('/marketplace');
                const startingProductURL = localStorage.getItem('startingProductURL') || '/marketplace';
                navigate(startingProductURL);
                localStorage.removeItem('startingProductURL'); // Clear the stored URL after use
            }
        } catch (error) {
            console.error('Caught error:', error);
        }
    };

    if (loading) {
        return (
            <Box minHeight="100vh" display="flex" alignItems="center" justifyContent="center">
                <Spinner size="xl" />
            </Box>
        );
    }

    if (error) {
        return (
            <Box minHeight="100vh" display="flex" alignItems="center" justifyContent="center">
                <Text>Error: {error.message}</Text>
            </Box>
        );
    }




    const validatePhoneNumber = (number: string): boolean => {
        const phoneRegex = /^\d{9,10}$/;
        if (!phoneRegex.test(number)) {
            setPhoneError('Please enter a valid phone number');
            return false;
        }
        setPhoneError('');
        return true;
    };


    return (
        <>
            <NavbarWithCallToAction />
            <Container maxW="container.md" py={8}>
                <Heading as="h1" size="xl" mb={6}>
                    Profile Details
                </Heading>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4} align="stretch">
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input value={data?.getUser.email} isReadOnly />
                        </FormControl>
                        <FormControl>
                            <FormLabel>First Name</FormLabel>
                            <Input
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Last Name</FormLabel>
                            <Input
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </FormControl>
                        {/* <FormControl>
                            <FormLabel>Mobile Number</FormLabel>
                            <Input
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </FormControl> */}


                        <FormControl>
                            <FormLabel>Country</FormLabel>
                            <Select
                                value={countryCode}
                                onChange={(e) => setCountryCode(e.target.value)}
                            >
                                {countries.map((country) => (
                                    <option key={country.code} value={country.code}>
                                        {country.name} ({country.code})
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl isInvalid={!!phoneError}>
                            <FormLabel>Phone Number</FormLabel>
                            <Input
                                type="tel"
                                placeholder="Enter your phone number"
                                value={phoneNumber}
                                onChange={(e) => {
                                    setPhoneNumber(e.target.value);
                                    validatePhoneNumber(e.target.value);
                                }}
                            />
                            <FormErrorMessage>{phoneError}</FormErrorMessage>
                        </FormControl>
                        <FormControl id="newInviteCode" marginBottom={4}>
                            <FormLabel>New Invite Code (optional)</FormLabel>
                            <Input
                                value={newInviteCode}
                                onChange={(e) => setNewInviteCode(e.target.value)}
                                placeholder="Enter new invite code"
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            colorScheme="blue"
                            isLoading={updateLoading}
                            loadingText="Updating"
                        >
                            Update Profile
                        </Button>
                    </VStack>
                </form>
            </Container>
            <FooterWithFourColumns />
        </>
    );
};

export default ProfileDetails;
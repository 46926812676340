// src/pages/GroupBeefBuy.tsx

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box, Button, Container, Heading, Text, VStack, List, ListItem, ListIcon,
    useToast, Checkbox, Center, Flex, Image, Progress
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { PaymentStatusModal } from '../components/PaymentStatusModal';
import { useAuth } from '../contexts/AuthContext';
import { useInviteCode } from '../hooks/useInviteCode';
import { useMutation, useQuery, gql } from '@apollo/client';

// GraphQL mutation to create a beef purchase pledge
const CREATE_BEEF_PURCHASE_PLEDGE = gql`
    mutation CreateBeefPurchasePledge($userEmail: String!, $pledgeAmount: Float!) {
        createBeefPurchasePledge(userEmail: $userEmail, pledgeAmount: $pledgeAmount) {
            id
            status
        }
    }
`;

// GraphQL mutation to create a BTCPay invoice
const CREATE_BTCPAY_INVOICE = gql`
    mutation CreateBTCPayInvoice($amount: Float!, $currency: String!, $userId: String!, $mongodbProductId: ID!) {
        createBTCPayInvoice(amount: $amount, currency: $currency, userId: $userId, mongodbProductId: $mongodbProductId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;
// GraphQL query to get BTCPay invoice status
const GET_BTCPAY_INVOICE = gql`
    query GetBTCPayInvoice($invoiceId: String!) {
        getBTCPayInvoice(invoiceId: $invoiceId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;
const GroupBeefBuy = () => {
    // State variables
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [checkoutLink, setCheckoutLink] = useState('');
    const [invoiceStatus, setInvoiceStatus] = useState('');
    const [paymentComplete, setPaymentComplete] = useState(false);

    // Hooks
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const { user, isLoading } = useAuth();
    useInviteCode(); // Custom hook to handle invite codes

    // GraphQL mutations and queries
    const [createBeefPurchasePledge] = useMutation(CREATE_BEEF_PURCHASE_PLEDGE);
    const [createBTCPayInvoice] = useMutation(CREATE_BTCPAY_INVOICE);
    const { data: invoiceData, startPolling, stopPolling } = useQuery(GET_BTCPAY_INVOICE, {
        variables: { invoiceId },
        skip: !invoiceId,
        fetchPolicy: 'network-only',
    });

    // Effect to start/stop polling for invoice status
    useEffect(() => {
        if (invoiceId) {
            startPolling(5000); // Check every 5 seconds
        }
        return () => stopPolling();
    }, [invoiceId, startPolling, stopPolling]);
    /// Effect to handle invoice status changes
    useEffect(() => {
        if (invoiceData) {
            const status = invoiceData.getBTCPayInvoice.status;
            setInvoiceStatus(status);
            if (['Paid', 'Confirmed', 'Settled'].includes(status)) {
                setPaymentComplete(true);
                toast({
                    title: "Payment Completed",
                    description: "Your pledge has been successfully recorded.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                stopPolling();
            }
        }
    }, [invoiceData, stopPolling, toast]);

    // to track once the user has loggged int to take them baack to the previous page
    useEffect(() => {
        if (!user) {
            localStorage.setItem('startingProductURL', '/groubebeefbuy');
        }
    }, [user]);

    // Handler for pledge submission
    const handlePledgeSubmit = async () => {
        if (!agreeToTerms) {
            toast({
                title: "Agreement Required",
                description: "Please agree to the terms before submitting your pledge.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (user) {
            try {
                console.log('Submitting pledge for user:', user);
                const { data } = await createBTCPayInvoice({
                    variables: {
                        amount: 0.00001, // Test amount
                        currency: "BTC",
                        userId: user.id,
                        mongodbProductId: "66d5410dee37f9f12465468e" // Hardcoded MongoDB product ID
                    },
                });
                console.log('BTCPay invoice created:', data);
                setInvoiceId(data.createBTCPayInvoice.id);
                setCheckoutLink(data.createBTCPayInvoice.checkoutLink);
                setInvoiceStatus(data.createBTCPayInvoice.status);
                setIsModalOpen(true);
            } catch (error: any) {
                console.error('Error creating BTCPay invoice:', error);
                toast({
                    title: "Error",
                    description: `Failed to create invoice: ${error.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            navigate('/login', { state: { from: location } });
        }
    };

    // Handler for modal close
    const handleModalClose = () => {
        setIsModalOpen(false);
        if (paymentComplete) {
            toast({
                title: "Payment Completed",
                description: "Thank you for your payment. Your pledge has been recorded.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Invoice Created",
                description: "You can complete the payment at any time using the provided link.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // Render loading state
    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    // Main render
    return (
        <Box>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={8}>
                <VStack spacing={8} align="stretch">
                    {/* Header section */}
                    <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="space-between">
                        <Box flex={1}>
                            <Heading as="h1" size="2xl">Group Buy: Half a Beef</Heading>
                            <Heading as="h2" size="xl" mt={2}>Join Our Bulk Meat Purchase</Heading>
                        </Box>
                        <Box flex={1} ml={{ base: 0, md: 8 }} mt={{ base: 4, md: 0 }}>
                            <Image
                                src="/product_images/12BeefBox.png"
                                alt="Half a Beef"
                                maxWidth="100%"
                                borderRadius="md"
                                boxShadow="lg"
                            />
                        </Box>
                    </Flex>

                    {/* Description */}
                    <Text fontSize="xl">
                        Join our group purchase of half a beef! Split between 16 people, this is an excellent opportunity to stock up on high-quality, locally sourced beef at a great price.
                    </Text>

                    {/* Offer details */}
                    <Box>
                        <Heading as="h2" size="xl" mb={4}>About the Offer</Heading>
                        <List spacing={3}>
                            {/* ... (List items) */}
                        </List>
                    </Box>

                    {/* Box contents */}
                    <Box>
                        <Heading as="h2" size="xl" mb={4}>What's In The Box</Heading>
                        {/* ... (Box contents description and list) */}
                    </Box>

                    {/* Why Forage Farms Beef */}
                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Why Forage Farms Beef</Heading>
                        <List spacing={3}>
                            {/* ... (List items) */}
                        </List>
                    </Box>

                    {/* Progress section */}
                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Progress</Heading>
                        <Text fontSize="lg" mb={2}>2 out of 16 pledges received</Text>
                        <Progress value={12.5} size="lg" colorScheme="green" />
                    </Box>

                    {/* How It Works section */}
                    <Box>
                        <Heading as="h2" size="xl" mb={4}>How It Works</Heading>
                        <List spacing={3}>
                            {/* ... (List items) */}
                        </List>

                        <Text fontSize="xl" color={"green.500"}>
                            Join our test group purchase with a $1 contribution! This is a test to ensure our payment system works correctly.
                        </Text>
                    </Box>

                    {/* Pledge submission section */}
                    <Box>
                        {user ? (
                            <>
                                <Text fontSize="lg" mb={4}>
                                    By agreeing to participate, you're pledging to contribute $1 as a test payment for our group purchase system.
                                </Text>
                                <Checkbox
                                    isChecked={agreeToTerms}
                                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                                    mb={4}
                                >
                                    I agree to pay $1 as a test for this group purchase system
                                </Checkbox>

                                <Center>
                                    <Button colorScheme="blue" size="lg" mt={4} onClick={handlePledgeSubmit} isDisabled={!agreeToTerms}>
                                        Submit Pledge
                                    </Button>
                                </Center>
                            </>
                        ) : (
                            <Center>
                                <Button colorScheme="blue" size="lg" onClick={() => navigate('/login', { state: { from: location } })}>
                                    Login to Pledge
                                </Button>
                            </Center>
                        )}
                    </Box>
                </VStack>
            </Container>

            {/* Payment status modal */}
            <PaymentStatusModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                invoiceId={invoiceId}
                invoiceStatus={invoiceStatus}
                checkoutLink={checkoutLink}
                amount={invoiceData?.getBTCPayInvoice.amount}
                currency={invoiceData?.getBTCPayInvoice.currency}
            />

            <FooterWithFourColumns />
        </Box>
    );
};

export default GroupBeefBuy;
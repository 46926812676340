// src/pages/Home.tsx
import React from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    Button,
    Image,
    AspectRatio,
    SimpleGrid,
    Stack,
    Link as ChakraLink,
    Center,
    Flex,
    Grid,
    VStack,
    Code,
    Link
} from "@chakra-ui/react";
import { Main } from "../components/chakra/LayoutWithStickyNavbar/Main";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { useAuth } from "../contexts/AuthContext";
import { ShowcaseGrid } from "../components/chakra/ShowcaseGrid/ShowcaseGrid";
import { AboutSection } from "../components/AboutSection";

import { useInviteCode } from '../hooks/useInviteCode';




export const Home = () => {
    useInviteCode();
    const { user, logout } = useAuth();

    return (
        <>

            <Flex direction="column" minHeight="100vh">
                <NavbarWithCallToAction />

                <AboutSection />


                <Container maxW="max-w-7xl" bg={"#EFEDDC"} id="marketplace">
                    <ShowcaseGrid />
                </Container>
                <Flex direction="column" flex="1" >
                    <Main />



                </Flex>
                <FooterWithFourColumns />
            </Flex>



        </>
    );
};
export default Home
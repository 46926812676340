import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  SimpleGrid,
  Spacer,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'
import { categories } from './_data'
import { CategoryCard } from './CatetgoryCard'
import { useAuth } from '../../../contexts/AuthContext';

export const ShowcaseGrid = () => {
  const { user } = useAuth(); // Get the user from AuthContext


  return (<>

    <Box
      maxW="7xl"
      mx="auto"
      px={{ base: '4', md: '8', lg: '12' }}
      py={{ base: '6', md: '8', lg: '12' }}
      as='section'
      bg={"#EFEDDC"}
    >
      <Stack spacing={{ base: '6', md: '8', lg: '12' }}>
        <Flex
          justify="space-between"
          align={{ base: 'start', md: 'center' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Spacer />
          {/* <Heading size="lg" mb={{ base: '3', md: '0' }}>
            Shop by Categories
          </Heading> */}
         
        </Flex>
        <SimpleGrid columns={{ base: 2, md: 2, lg: 4 }} gap={{ base: '4', md: '6', lg: '8' }}>
          {categories.map((category) => (
            <CategoryCard key={category.name} category={category} isLoggedIn={!!user} />
          ))}
        </SimpleGrid>

        <Flex>
          
          <Spacer />
          <Box p='4' >
            <HStack spacing={{ base: '2', md: '3' }}>
              <Link fontWeight="semibold" color={useColorModeValue('blue.500', 'blue.300')}>
                See all categories
              </Link>
              <Icon
                as={FaArrowRight}
                color={useColorModeValue('blue.500', 'blue.300')}
                fontSize={{ base: 'sm', md: 'md' }}
              />
            </HStack>
          </Box>
        </Flex>


      </Stack>
    </Box>



  </>


  )
}

import React, { useEffect } from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    Circle,
    Flex,
    useColorModeValue,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

interface LeadershipLevelProps {
    number: number;
    title: string;
    description: string;
    color: string;
    angle: number;
}

const LeadershipLevel = ({ number, title, description, color, angle }: LeadershipLevelProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const bgColor = useColorModeValue(`${color}.50`, `${color}.900`);
    const textColor = useColorModeValue(`${color}.700`, `${color}.100`);

    return (
        <>
            <Circle
                size="120px"
                bg={bgColor}
                color={textColor}
                position="absolute"
                transform={`rotate(${angle}deg) translate(250px) rotate(-${angle}deg)`}
                cursor="pointer"
                onClick={onOpen}
                boxShadow="lg"
                transition="all 0.3s"
                _hover={{ transform: `rotate(${angle}deg) translate(260px) rotate(-${angle}deg) scale(1.1)` }}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    textAlign="center"
                    w="100%"
                    h="100%"
                    p={2}
                >
                    <Text fontWeight="bold" fontSize="2xl" lineHeight="1">
                        {number}
                    </Text>
                    <Text
                        fontWeight="bold"
                        fontSize="xs"
                        mt={1}
                        lineHeight="1.2"
                        wordBreak="break-word"
                    >
                        {title}
                    </Text>
                </Flex>
            </Circle>


            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb={4}>{description}</Text>
                        <Heading as="h4" size="md" mb={2}>Key Responsibilities:</Heading>
                        <Text mb={4}>
                            • Responsibility 1<br />
                            • Responsibility 2<br />
                            • Responsibility 3
                        </Text>
                        <Heading as="h4" size="md" mb={2}>Required Skills:</Heading>
                        <Text mb={4}>
                            • Skill 1<br />
                            • Skill 2<br />
                            • Skill 3
                        </Text>
                        <Heading as="h4" size="md" mb={2}>Learning Resources:</Heading>
                        <Text>
                            • Resource 1<br />
                            • Resource 2<br />
                            • Resource 3
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme={color} mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

const CultivatingResiliencePage = () => {
    const bgColor = useColorModeValue('green.50', 'green.900');
    const textColor = useColorModeValue('gray.800', 'white');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const levels = [
        { number: 1, title: "Soil Steward", description: "Understands soil health, biodiversity, and basic ecosystem principles. Learns about the foundation of healthy communities through healthy soil.", color: "yellow" },
        { number: 2, title: "Local Food Guardian", description: "Manages local food production. Understands nutrition and community needs. Ensures the connection between healthy soil and healthy people.", color: "green" },
        { number: 3, title: "Community Connector", description: "Facilitates local networks and knowledge sharing. Organizes community events and educational programs. Builds connections between different aspects of community resilience.", color: "teal" },
        { number: 4, title: "Resilience Technologist", description: "Implements and maintains transparent, resilient technologies. Ensures no 'black boxes' in community systems. Integrates technology with natural systems for enhanced resilience.", color: "blue" },
        { number: 5, title: "Ecosystem Harmonizer", description: "Integrates various aspects of resilience (food, technology, biodiversity). Ensures balance and harmony in the community ecosystem. Facilitates decision-making that considers all aspects of community health.", color: "purple" },
    ];

    return (
        <Box>
            <NavbarWithCallToAction />

            <Box bg={bgColor} minHeight="100vh" py={12}>
                <Container maxW="container.xl">
                    <VStack spacing={12} align="stretch">
                        <Box textAlign="center">
                            <Heading as="h1" size="2xl" mb={4} color={textColor}>
                                Cultivating Resilience: Community Leadership Pipeline
                            </Heading>
                            <Text fontSize="xl" mb={6} color={textColor}>
                                A non-hierarchical, nature-based community leadership structure
                            </Text>
                        </Box>

                        <Flex justify="center" align="center" position="relative" h="600px">
                            <Circle size="500px" border="2px dashed" borderColor={textColor} position="relative">
                                {levels.map((level, index) => (
                                    <LeadershipLevel
                                        key={index}
                                        number={level.number}
                                        title={level.title}
                                        description={level.description}
                                        color={level.color}
                                        angle={(360 / levels.length) * index}
                                    />
                                ))}
                            </Circle>
                        </Flex>

                        <Box textAlign="center" mt={12}>
                            <Text fontSize="lg" color={textColor}>
                                This structure emphasizes that all levels are interconnected peers, rooted in nature's wisdom.
                                The progression is not about gaining power over others, but about expanding one's understanding
                                and ability to support the entire community ecosystem.
                            </Text>
                        </Box>
                    </VStack>
                </Container>
            </Box>

            <FooterWithFourColumns />
        </Box>
    );
};

export default CultivatingResiliencePage;
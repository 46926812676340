import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box, Button, Container, Heading, Text, VStack, List, ListItem, ListIcon,
    useToast, Checkbox, Center, Flex, Image, Progress,
    HStack, useColorModeValue, WrapItem, Badge, Wrap, Grid,
    Spacer, Link, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
} from '@chakra-ui/react';
import { CheckCircleIcon, PhoneIcon, EmailIcon, ExternalLinkIcon, DownloadIcon, CalendarIcon, TimeIcon, InfoIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { PaymentStatusModal } from '../components/PaymentStatusModal';
import { useAuth } from '../contexts/AuthContext';
import { useInviteCode } from '../hooks/useInviteCode';
import { useMutation, useQuery, gql } from '@apollo/client';

import CountdownTimer from '../components/CountdownTimer';
import SMSNotificationSignup from '../components/SMSNotificationSignup';

import { calculateBTCAmount } from '../utils/helpers';



const GET_CAMPAIGN = gql`
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      name
      description
      currentQuantity
      goalQuantity
      status
    }
  }
`;


// GraphQL Queries and Mutations
const GET_BITCOIN_PRICE = gql`
  query GetBitcoinPrice {
    getBitcoinPrice
  }
`;

const CREATE_BTCPAY_INVOICE = gql`
    mutation CreateBTCPayInvoice($amount: Float!, $currency: String!, $userId: String!, $mongodbProductId: ID!, $mongodbCampaignId: ID!, $quantity: Int!) {
        createBTCPayInvoice(amount: $amount, currency: $currency, userId: $userId, mongodbProductId: $mongodbProductId, mongodbCampaignId: $mongodbCampaignId, quantity: $quantity) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const GET_BTCPAY_INVOICE = gql`
    query GetBTCPayInvoice($invoiceId: String!) {
        getBTCPayInvoice(invoiceId: $invoiceId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const OrganicChickenGroupBuy = () => {
    // State variables
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [checkoutLink, setCheckoutLink] = useState('');
    const [invoiceStatus, setInvoiceStatus] = useState('');
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [pledgeCount, setPledgeCount] = useState(0);
    const [btcPrice, setBtcPrice] = useState(0);
    const [btcAmount, setBtcAmount] = useState(0);
    const [unitCount, setUnitCount] = useState(1); // New state for unit count
    const [priceLoaded, setPriceLoaded] = useState(false);

    // Constants
    const AUD_PRICE = 23.8; // 23.80 AUD per kilogram
    const GROUP_BUY_GOAL = 20; // KG
    const MAX_UNITS_PER_HEAD = 5;
    const CAMPAIGN_ID = "66de64d60feda6ecd54dd5fb";
    const PRODUCT_ID = "66de64890feda6ecd54dd5f2";

    // Chakra UI color mode values
    const bgColor = useColorModeValue('orange.50', 'orange.900');
    const cardBgColor = useColorModeValue('white', 'orange.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const accentColor = useColorModeValue('orange.500', 'orange.300');
    const borderColor = useColorModeValue('orange.200', 'orange.600');
    const orange = useColorModeValue('orange.100', 'orange.700');

    // Hooks
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const { user, isLoading } = useAuth();
    useInviteCode();

    // GraphQL Mutations and Queries
    const [createBTCPayInvoice] = useMutation(CREATE_BTCPAY_INVOICE);
    const { data: invoiceData, startPolling, stopPolling } = useQuery(GET_BTCPAY_INVOICE, {
        variables: { invoiceId },
        skip: !invoiceId,
        fetchPolicy: 'network-only',
    });

    const { data: btcPriceData, loading: btcPriceLoading } = useQuery(GET_BITCOIN_PRICE, {
        pollInterval: 60000, // Poll every minute
    });

    // Effects

    // Fetch BTC price once on component mount
    useEffect(() => {
        if (btcPriceData) {
            setBtcPrice(btcPriceData.getBitcoinPrice);
            const newBtcAmount = calculateBTCAmount(AUD_PRICE * unitCount, btcPriceData.getBitcoinPrice);
            setBtcAmount(newBtcAmount);
            setPriceLoaded(true);
        }
    }, [btcPriceData, unitCount]);

    // Update BTC amount when unit count changes
    useEffect(() => {
        if (priceLoaded) {
            const newBtcAmount = calculateBTCAmount(AUD_PRICE * unitCount, btcPrice);
            setBtcAmount(newBtcAmount);
        }
    }, [unitCount, btcPrice, priceLoaded]);

    // Start polling for invoice status when invoiceId is available
    useEffect(() => {
        if (invoiceId) {
            startPolling(1000);
        }
        return () => stopPolling();
    }, [invoiceId, startPolling, stopPolling]);

    // Handle invoice status changes
    useEffect(() => {
        if (invoiceData) {
            const status = invoiceData.getBTCPayInvoice.status;
            setInvoiceStatus(status);
            if (['Paid', 'Confirmed', 'Settled'].includes(status)) {
                setPaymentComplete(true);

                toast({
                    title: "Payment Completed",
                    description: "Your pledge for the Kangaroo Meat Group Buy has been processed.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                stopPolling();
            }
        }
    }, [invoiceData, stopPolling, toast, unitCount]);

    // todo, get the pledge count from the database
    const { data: campaignData, loading: campaignLoading, refetch: refetchCampaign } = useQuery(GET_CAMPAIGN, {
        variables: { id: CAMPAIGN_ID },
        pollInterval: 30000, // Poll every 30 seconds
    });


    useEffect(() => {
        if (campaignData && campaignData.getCampaign) {
            setPledgeCount(campaignData.getCampaign.currentQuantity);
        }
    }, [campaignData]);

    // Handler Functions

    // Handle pledge button click
    const handlePledge = async () => {
        if (!agreeToTerms) {
            toast({
                title: "Agreement Required",
                description: "Please agree to the terms before pledging your support.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (user) {
            try {
                const { data } = await createBTCPayInvoice({
                    variables: {
                        amount: btcAmount,
                        currency: "BTC",
                        userId: user.id,
                        mongodbProductId: PRODUCT_ID,
                        mongodbCampaignId: CAMPAIGN_ID,
                        quantity: unitCount
                    },
                });
                setInvoiceId(data.createBTCPayInvoice.id);
                setCheckoutLink(data.createBTCPayInvoice.checkoutLink);
                setInvoiceStatus(data.createBTCPayInvoice.status);
                setIsModalOpen(true);
                refetchCampaign();
            } catch (error: any) {
                console.error('Error creating BTCPay invoice:', error);
                toast({
                    title: "Error",
                    description: `Failed to process pledge: ${error.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            // navigate('/login', { state: { from: location } });
        }
    };

    // Handle modal close
    const handleModalClose = () => {
        setIsModalOpen(false);
        if (paymentComplete) {
            toast({
                title: "Pledge Confirmed",
                description: "Thank you for your pledge to the Kangaroo Meat Group Buy!",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Pledge Pending",
                description: "Complete your payment to finalize your pledge.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // Render loading state
    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    // Main component render
    return (
        <Box bg={bgColor} minHeight="100vh">
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    {/* Hero Section */}
                    <Box
                        borderRadius="xl"
                        overflow="hidden"
                        boxShadow="2xl"
                        bg={cardBgColor}
                        position="relative"
                    >
                        <Image
                            src="/product_images/chicken.jpg"
                            alt="Organic Whole Chicken"
                            width="100%"
                            height="300px"
                            objectFit="cover"
                            objectPosition="center"
                        />
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            right="0"
                            bottom="0"
                            bg="rgba(0,0,0,0.4)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            textAlign="center"
                            p={8}
                        >
                            <Heading as="h1" size="2xl" color="white" mb={4}>
                                Organic Whole Chicken
                            </Heading>
                            <Text fontSize="xl" color="white">
                                Fresh, Free-Range, and Certified Organic
                            </Text>
                        </Box>
                    </Box>

                    {/* Introduction Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Text fontSize="xl" lineHeight="tall">
                            Experience the superior taste and quality of our organic whole chickens. Raised on our family farm on the Darling Downs, Queensland, these chickens are certified organic and completely gluten-free.
                        </Text>
                    </Box>

                    {/* Why Choose Our Chicken Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Why Choose Our Organic Chicken?</Heading>
                        <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} gap={6}>
                            {[
                                "Certified Organic",
                                "Free-Range and Ethically Raised",
                                "No Antibiotics or Growth Hormones",
                                "Superior Taste and Texture",
                                "Supports Sustainable Farming",
                                "Locally Grown in Queensland"
                            ].map((item, index) => (
                                <Box
                                    key={index}
                                    p={4}
                                    borderRadius="md"
                                    bg={bgColor}
                                    boxShadow="md"
                                >
                                    <HStack spacing={4} align="center">
                                        <CheckCircleIcon color={accentColor} />
                                        <Text fontWeight="bold" fontSize={["sm", "md"]}>
                                            {item}
                                        </Text>
                                    </HStack>
                                </Box>
                            ))}
                        </Grid>
                    </Box>

                    {/* Product Details Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Product Details</Heading>
                        <VStack align="start" spacing={4}>
                            <Text><strong>Product:</strong> Organic Whole Chicken</Text>
                            <Text><strong>Weight:</strong> Ranging from 1.25KG to 2.5KG</Text>
                            <Text><strong>Price:</strong> ${AUD_PRICE} AUD ({btcAmount.toFixed(8)} BTC)</Text>
                            <Text><strong>Group Buy Goal:</strong> {campaignData?.getCampaign.goalQuantity || GROUP_BUY_GOAL} KG</Text>
                            <Text><strong>Current Progress:</strong> {pledgeCount} out of {campaignData?.getCampaign.goalQuantity || GROUP_BUY_GOAL} pledges</Text>
                            <Box w="100%">
                                <Progress
                                    value={(pledgeCount / (campaignData?.getCampaign.goalQuantity || GROUP_BUY_GOAL)) * 100}
                                    colorScheme="orange"
                                />
                            </Box>
                        </VStack>
                    </Box>

                    {/* Our Commitment Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Our Commitment</Heading>
                        <Text fontSize="lg" mb={4}>
                            At Greenag, we're committed to:
                        </Text>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Providing the highest quality organic chicken
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Maintaining ethical and sustainable farming practices
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Supporting local agriculture and communities
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Ensuring transparency in our production process
                            </ListItem>
                        </List>
                    </Box>

                    {/* Order Now Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Order Now</Heading>
                        <Box mb={6}>
                            <Text fontSize="lg" mb={4}>
                                Choose the number of chickens:
                            </Text>
                            {/* Keep the Slider component as is */}
                            <Text fontSize="lg" mt={6} mb={2}>
                                Your order: ${AUD_PRICE * unitCount} AUD ≈ {btcAmount.toFixed(8)} BTC
                            </Text>
                            <Text fontSize="sm" mb={2}>
                                Current BTC Price: 1 BTC = ${btcPriceLoading ? 'Loading...' : btcPrice.toFixed(2)} AUD
                            </Text>
                            <Text fontSize="xs" fontStyle="italic" mb={4}>
                                (Price updates every minute to ensure accuracy)
                            </Text>
                        </Box>
                        {/* Keep the rest of the order section (Checkbox, Button, etc.) as is */}
                    </Box>

                    {/* Join the Trial Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Join the Trial</Heading>
                        <Box mb={6}>
                            <Text fontSize="lg" mb={4}>
                                Choose the weight of your chicken:
                            </Text>
                            <Slider
                                aria-label="chicken-weight-slider"
                                defaultValue={1.25}
                                min={1.25}
                                max={2.5}
                                step={0.25}
                                onChange={(val) => setUnitCount(val)}
                            >
                                <SliderMark value={1.25} mt='1' ml='-2.5' fontSize='sm'>
                                    1.25KG
                                </SliderMark>
                                <SliderMark value={2.5} mt='1' ml='-2.5' fontSize='sm'>
                                    2.5KG
                                </SliderMark>
                                <SliderMark
                                    value={unitCount}
                                    textAlign='center'
                                    bg='blue.500'
                                    color='white'
                                    mt='-10'
                                    ml='-5'
                                    w='12'
                                >
                                    {unitCount.toFixed(2)}
                                </SliderMark>
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                            <Text fontSize="lg" mt={6} mb={2}>
                                Your pledge: ${(AUD_PRICE * unitCount).toFixed(2)} AUD ≈ {btcAmount.toFixed(8)} BTC
                            </Text>
                            <Text fontSize="sm" mb={2}>
                                Current BTC Price: 1 BTC = ${btcPriceLoading ? 'Loading...' : btcPrice.toFixed(2)} AUD
                            </Text>
                            <Text fontSize="xs" fontStyle="italic" mb={4}>
                                (Price updates every minute to ensure accuracy)
                            </Text>
                        </Box>
                        {user ? (
                            <VStack spacing={4} align="stretch">
                                <Checkbox
                                    isChecked={agreeToTerms}
                                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                                    colorScheme="orange"
                                >
                                    I agree to the trial terms and conditions
                                </Checkbox>
                                <Button
                                    colorScheme="orange"
                                    size="lg"
                                    onClick={handlePledge}
                                    isDisabled={!agreeToTerms || campaignData?.getCampaign.status !== 'ACTIVE'}
                                    width="full"
                                    boxShadow="md"
                                    _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                    transition="all 0.2s"
                                >
                                    Pledge Now with Bitcoin Lightning
                                </Button>
                            </VStack>
                        ) : (
                            <Button
                                colorScheme="orange"
                                size="lg"
                                onClick={() => navigate('/login', { state: { from: location } })}
                                width="full"
                                boxShadow="md"
                                _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                transition="all 0.2s"
                            >
                                Login to Pledge
                            </Button>
                        )}
                        <Spacer m={10} />
                        <SMSNotificationSignup buttonText="Get Trial Updates via SMS" buttonColorScheme="orange" />
                    </Box>
                </VStack>
            </Container>

            {/* Payment Status Modal */}
            <PaymentStatusModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                invoiceId={invoiceId}
                invoiceStatus={invoiceStatus}
                checkoutLink={checkoutLink}
                amount={invoiceData?.getBTCPayInvoice.amount}
                currency={invoiceData?.getBTCPayInvoice.currency}
            />

            <FooterWithFourColumns />
        </Box>
    );
};

export default OrganicChickenGroupBuy;
// src/pages/AllUsersPurchases.tsx
import React, { useEffect } from 'react';
import { useMutation, useQuery, ApolloQueryResult } from '@apollo/client';
import { gql } from '@apollo/client';
import {
    Box,
    Container,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Center,
    Text,
    TableContainer,
    useColorModeValue,
    Button,
    Select,
    useToast,
} from '@chakra-ui/react';
import { BuyBitcoinPurchaseSchema } from '../types';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';


type AllBuyBitcoinPurchasesData = {
    getAllBuyBitcoinPurchases: BuyBitcoinPurchaseSchema[];
};
const GET_ALL_BUY_BITCOIN_PURCHASES = gql`
    query GetAllBuyBitcoinPurchases {
        getAllBuyBitcoinPurchases {
            id
            amountAUD
            amountBTC
            status
            createdAt
            updatedAt
            walletAddress
            userEmail
            bsb
            accountNumber
            paymentReference
        }
    }
`;

const UPDATE_BUY_BITCOIN_PURCHASE_STATUS = gql`
    mutation UpdateBuyBitcoinPurchaseStatus($id: ID!, $status: BuyBitcoinPurchaseStatus!) {
        updateBuyBitcoinPurchaseStatus(id: $id, status: $status) {
            id
            status
        }
    }
`;

export const AllUsersPurchases = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { loading, error, data, refetch } = useQuery<AllBuyBitcoinPurchasesData>(
        GET_ALL_BUY_BITCOIN_PURCHASES,
        {
            fetchPolicy: 'network-only', // This ensures we always fetch fresh data
        }
    );
    const [updateStatus] = useMutation(UPDATE_BUY_BITCOIN_PURCHASE_STATUS);
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const toast = useToast();



    const handleStatusUpdate = async (id: string, newStatus: string) => {
        try {
            await updateStatus({
                variables: { id, status: newStatus },
            });
            await refetch();
            toast({
                title: "Status updated",
                description: "The purchase status has been successfully updated.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update the purchase status.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        if (!user || !user.permissions?.includes('admin')) {
            navigate('/');
        }
    }, [user, navigate]);


    if (loading) {
        return (
            <Center height="100vh">
                <Spinner size="xl" />
            </Center>
        );
    }

    if (error) {
        return (
            <Center height="100vh">
                <Text>Error: {error.message}</Text>
            </Center>
        );
    }

    const purchases = data?.getAllBuyBitcoinPurchases || [];
    console.log(purchases)

    return (
        <>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={8}>
                <Box
                    bg={bgColor}
                    boxShadow="lg"
                    borderRadius="lg"
                    p={6}
                    borderWidth={1}
                    borderColor={borderColor}
                >
                    <Heading as="h1" size="xl" mb={6}>
                        All Users Bitcoin Purchases
                    </Heading>
                    {purchases.length > 0 ? (
                        <TableContainer>
                            <Table variant="simple" size="sm">
                                <Thead>
                                    <Tr>
                                        <Th>User Email</Th>
                                        <Th>Amount AUD</Th>
                                        <Th>Amount BTC</Th>
                                        <Th>Status</Th>
                                        <Th>Actions</Th>
                                        <Th>Created At</Th>
                                        <Th>Updated At</Th>
                                        <Th>Wallet Address</Th>
                                        <Th>BSB</Th>
                                        <Th>Account Number</Th>
                                        <Th>Payment Reference</Th>

                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {purchases.map((purchase: BuyBitcoinPurchaseSchema) => (
                                        <Tr key={purchase.id}>
                                            <Td>{purchase?.userEmail}</Td>
                                            <Td>{purchase.amountAUD}</Td>
                                            <Td>{purchase.amountBTC}</Td>
                                            <Td>{purchase.status}</Td>
                                            <Td>
                                                <Select
                                                    value={purchase.status}
                                                    onChange={(e) => handleStatusUpdate(purchase.id, e.target.value)}
                                                >
                                                    <option value="PENDING">PENDING</option>
                                                    <option value="AUD_RECEIVED">AUD_RECEIVED</option>
                                                    <option value="COMPLETED">COMPLETED</option>
                                                    <option value="FAILED">FAILED</option>
                                                </Select>
                                            </Td>
                                            <Td>{new Date(parseInt(purchase.createdAt)).toLocaleString()}</Td>
                                            <Td>{new Date(parseInt(purchase.updatedAt)).toLocaleString()}</Td>
                                            <Td>{purchase.walletAddress}</Td>
                                            <Td>{purchase.bsb}</Td>
                                            <Td>{purchase.accountNumber}</Td>
                                            <Td>{purchase.paymentReference}</Td>

                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Text>No purchases found.</Text>
                    )}
                </Box>
            </Container>
            <FooterWithFourColumns />
        </>
    );
};

export default AllUsersPurchases;
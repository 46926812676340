import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box, Button, Container, Heading, Text, VStack, List, ListItem, ListIcon,
    useToast, Checkbox, Center, Flex, Image, Progress,
    HStack, useColorModeValue, WrapItem, Badge, Wrap, Grid,
    Spacer, Link, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
} from '@chakra-ui/react';
import { CheckCircleIcon, PhoneIcon, EmailIcon, ExternalLinkIcon, DownloadIcon, CalendarIcon, TimeIcon, InfoIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { PaymentStatusModal } from '../components/PaymentStatusModal';
import { useAuth } from '../contexts/AuthContext';
import { useInviteCode } from '../hooks/useInviteCode';
import { useMutation, useQuery, gql } from '@apollo/client';
import { calculateBTCAmount } from '../utils/helpers';

const GET_CAMPAIGN = gql`
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      name
      description
      currentQuantity
      goalQuantity
      status
    }
  }
`;

const GET_BITCOIN_PRICE = gql`
  query GetBitcoinPrice {
    getBitcoinPrice
  }
`;

const CREATE_BTCPAY_INVOICE = gql`
    mutation CreateBTCPayInvoice($amount: Float!, $currency: String!, $userId: String!, $mongodbProductId: ID!, $mongodbCampaignId: ID!, $quantity: Int!) {
        createBTCPayInvoice(amount: $amount, currency: $currency, userId: $userId, mongodbProductId: $mongodbProductId, mongodbCampaignId: $mongodbCampaignId, quantity: $quantity) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const GET_BTCPAY_INVOICE = gql`
    query GetBTCPayInvoice($invoiceId: String!) {
        getBTCPayInvoice(invoiceId: $invoiceId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const EmpoweredCommunities = () => {
    // State variables
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [checkoutLink, setCheckoutLink] = useState('');
    const [invoiceStatus, setInvoiceStatus] = useState('');
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [audAmount, setAudAmount] = useState(1000); // Default to 1000 AUD
    const [btcPrice, setBtcPrice] = useState(0);
    const [btcAmount, setBtcAmount] = useState(0);
    const [priceLoaded, setPriceLoaded] = useState(false);

    // Chakra UI color mode values
    const bgColor = useColorModeValue('orange.50', 'orange.900');
    const cardBgColor = useColorModeValue('white', 'orange.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const accentColor = useColorModeValue('orange.500', 'orange.300');

    // Hooks
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const { user, isLoading } = useAuth();
    useInviteCode();

    // Constants
    const AUD_PRICE = 1000; // Set the minimum AUD price here

    // GraphQL Mutations and Queries
    const [createBTCPayInvoice] = useMutation(CREATE_BTCPAY_INVOICE);
    const { data: invoiceData, startPolling, stopPolling } = useQuery(GET_BTCPAY_INVOICE, {
        variables: { invoiceId },
        skip: !invoiceId,
        fetchPolicy: 'network-only',
    });

    const { data: btcPriceData, loading: btcPriceLoading } = useQuery(GET_BITCOIN_PRICE, {
        pollInterval: 60000, // Poll every minute
    });

    // Effects

    // Fetch BTC price once on component mount
    useEffect(() => {
        if (btcPriceData) {
            setBtcPrice(btcPriceData.getBitcoinPrice);
            const newBtcAmount = calculateBTCAmount(audAmount, btcPriceData.getBitcoinPrice);
            setBtcAmount(newBtcAmount);
        }
    }, [btcPriceData, audAmount]);

    // Update BTC amount when AUD amount changes
    useEffect(() => {
        if (priceLoaded) {
            const newBtcAmount = calculateBTCAmount(audAmount, btcPrice);
            setBtcAmount(newBtcAmount);
        }
    }, [audAmount, btcPrice, priceLoaded]);

    // Store starting product URL if user is not logged in
    useEffect(() => {
        if (!user) {
            localStorage.setItem('startingProductURL', '/golden-hill-community-butcher-final');
        }
    }, [user]);

    // Start polling for invoice status when invoiceId is available
    useEffect(() => {
        if (invoiceId) {
            startPolling(1000);
        }
        return () => stopPolling();
    }, [invoiceId, startPolling, stopPolling]);

    // Handle invoice status changes
    useEffect(() => {
        if (invoiceData) {
            const status = invoiceData.getBTCPayInvoice.status;
            setInvoiceStatus(status);
            if (['Paid', 'Confirmed', 'Settled'].includes(status)) {
                setPaymentComplete(true);
                toast({
                    title: "Payment Completed",
                    description: "Your support for Golden Hill Community Butcher has been processed.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                stopPolling();
            }
        }
    }, [invoiceData, stopPolling, toast]);

    // Update BTC price and amount when new data is received
    useEffect(() => {
        if (btcPriceData && btcPriceData.getBitcoinPrice) {
            const price = btcPriceData.getBitcoinPrice;
            setBtcPrice(price);
            const newBtcAmount = calculateBTCAmount(audAmount, price);
            setBtcAmount(newBtcAmount);
        }
    }, [btcPriceData, audAmount]);

    // Handler Functions

    // Handle support button click
    const handleSupport = async () => {
        if (!agreeToTerms) {
            toast({
                title: "Agreement Required",
                description: "Please agree to the terms before pledging your support.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (user) {
            try {
                const { data } = await createBTCPayInvoice({
                    variables: {
                        amount: btcAmount,
                        currency: "BTC",
                        userId: user.id,
                        mongodbProductId: "66d6612070d2d94c266f3342"
                    },
                });
                setInvoiceId(data.createBTCPayInvoice.id);
                setCheckoutLink(data.createBTCPayInvoice.checkoutLink);
                setInvoiceStatus(data.createBTCPayInvoice.status);
                setIsModalOpen(true);
            } catch (error: any) {
                console.error('Error creating BTCPay invoice:', error);
                toast({
                    title: "Error",
                    description: `Failed to process support: ${error.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            // navigate('/login', { state: { from: location } });
        }
    };

    // Handle modal close
    const handleModalClose = () => {
        setIsModalOpen(false);
        if (paymentComplete) {
            toast({
                title: "Support Confirmed",
                description: "Thank you for supporting Golden Hill Community Butcher!",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Support Pending",
                description: "Complete your payment to finalize your support.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // Render loading state
    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    return (
        <Box bg={bgColor} minHeight="100vh">
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    {/* Hero Section */}
                    <Box
                        borderRadius="xl"
                        overflow="hidden"
                        boxShadow="2xl"
                        bg={cardBgColor}
                        position="relative"
                    >
                        <Image
                            src="/images/empowered-community.jpg"
                            alt="Empowered Communities"
                            width="100%"
                            height="400px"
                            objectFit="cover"
                            objectPosition="center"
                        />
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            right="0"
                            bottom="0"
                            bg="rgba(0,0,0,0.6)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            textAlign="center"
                            p={8}
                        >
                            <Heading as="h1" size="2xl" color="white" mb={4}>
                                Build Resilient Communities with Web3 Technologies
                            </Heading>
                            <Text fontSize="xl" color="white">
                                Empower yourself and your community in the digital age
                            </Text>
                        </Box>
                    </Box>

                    {/* Introduction Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Introducing the "Empowered Communities" Course</Heading>
                        <Text fontSize="xl" lineHeight="tall">
                            Are you concerned about the future of your community? Do you want to empower yourself and others to create sustainable, accountable systems that work for everyone? It's time to harness the power of Web3 technologies and build the resilient future we all deserve.
                        </Text>
                    </Box>

                    {/* What You'll Learn Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>What You'll Learn</Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                The fundamentals of blockchain, cryptography, and decentralized systems
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                How to build and manage your own decentralized applications
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Techniques for creating accountable and transparent community systems
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Ways to manage resources effectively without relying on traditional monetary systems
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                The integration of AI with Web3 for smarter, more efficient communities
                            </ListItem>
                        </List>
                    </Box>

                    {/* Why This Course Matters Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Why This Course Matters</Heading>
                        <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} gap={6}>
                            {[
                                "Cultivate Self-Reliance: Learn to build and manage systems that put the power back in your community's hands.",
                                "Enhance Privacy and Security: Understand how to protect your data and communications in an increasingly digital world.",
                                "Foster Innovation: Discover new ways to solve old problems using cutting-edge technologies.",
                                "Strengthen Community Bonds: Create systems that encourage collaboration and mutual support.",
                                "Prepare for the Future: Equip yourself with the skills needed to thrive in a rapidly changing world."
                            ].map((item, index) => (
                                <Box
                                    key={index}
                                    p={4}
                                    borderRadius="md"
                                    bg={bgColor}
                                    boxShadow="md"
                                >
                                    <Text fontWeight="bold" fontSize={["sm", "md"]}>
                                        {item}
                                    </Text>
                                </Box>
                            ))}
                        </Grid>
                    </Box>

                    {/* Who Is This For Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Who Is This For?</Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Community leaders looking to implement innovative solutions
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Parents concerned about their children's future
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Professionals seeking to understand the next wave of technological change
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Anyone interested in creating more resilient, self-sufficient communities
                            </ListItem>
                        </List>
                    </Box>

                    {/* Enroll Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Enroll Now</Heading>
                        <Text fontSize="lg" mb={4}>
                            Don't wait for others to shape your future. Take control and start building the resilient community you envision today!
                        </Text>
                        <Button
                            colorScheme="orange"
                            size="lg"
                            // onClick={handleEnroll}
                            width="full"
                            boxShadow="md"
                            _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                            transition="all 0.2s"
                        >
                            Enroll Now
                        </Button>
                    </Box>

                    {/* Testimonial Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>What Our Students Say</Heading>
                        <Text fontSize="lg" fontStyle="italic">
                            "This course opened my eyes to the possibilities of decentralized technologies. I now have the tools to make a real difference in my community."
                        </Text>
                        <Text fontWeight="bold" mt={2}>- Sarah K., Community Organizer</Text>
                    </Box>

                    {/* FAQ Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Frequently Asked Questions</Heading>
                        <VStack align="stretch" spacing={4}>
                            <Box>
                                <Text fontWeight="bold">Q: Do I need programming experience?</Text>
                                <Text>A: No prior programming experience is required. We'll guide you through everything step-by-step.</Text>
                            </Box>
                            <Box>
                                <Text fontWeight="bold">Q: How long does the course take to complete?</Text>
                                <Text>A: The course is self-paced, but typically takes 8-10 weeks to complete if you dedicate 3-5 hours per week.</Text>
                            </Box>
                            <Box>
                                <Text fontWeight="bold">Q: Will this help me in my current job?</Text>
                                <Text>A: Absolutely! The skills you learn are increasingly valuable in many fields, from tech to community development.</Text>
                            </Box>
                        </VStack>
                    </Box>
                </VStack>
            </Container>

            <FooterWithFourColumns />
        </Box>
    );
};

export default EmpoweredCommunities;
import React from 'react';
import { AspectRatio, Box, BoxProps, Image, Link, Skeleton, Text } from '@chakra-ui/react';
import { Category } from './_data';
import { useNavigate } from 'react-router-dom';

interface Props {
  category: Category;
  isLoggedIn: boolean;
  rootProps?: BoxProps;
}

export const CategoryCard = (props: Props) => {
  const { category, isLoggedIn, rootProps } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    console.log("linked clicked" + isLoggedIn);
    if (isLoggedIn) {
      navigate(category.loggedin_url);
    } else {
      navigate('/login');
    }
  };

  return (
    <Box
      position="relative"
      key={category.name}
      borderRadius="xl"
      overflow="hidden"
      onClick={handleClick}
      cursor="pointer"
      {...rootProps}
    >
      <AspectRatio ratio={1 / 1}>
  
        <Image src={category.imageUrl} alt={category.name} fallback={<Skeleton />} />
      </AspectRatio>
      <Box
        position="absolute"
        inset="0"
        bgGradient="linear(to-b, transparent 60%, gray.900)"
        boxSize="full"
      />
      <Box position="absolute" bottom="6" width="full" textAlign="center">
        <Text color="white" fontSize="lg" fontWeight="semibold">
          {category.name}
        </Text>
      </Box>
    </Box>
  );
};
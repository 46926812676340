import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
    Box, Button, Container, Heading, Text, VStack, List, ListItem, ListIcon,
    useToast, Checkbox, Center, Flex, Image, Progress,
    HStack, useColorModeValue, Grid, Spacer, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark,
    Badge,
} from '@chakra-ui/react';
import { CheckCircleIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { PaymentStatusModal } from '../../components/PaymentStatusModal';
import { useAuth } from '../../contexts/AuthContext';
import { useInviteCode } from '../../hooks/useInviteCode';
import { useMutation, useQuery, gql } from '@apollo/client';

import CountdownTimer from '../../components/CountdownTimer';
import SMSNotificationSignup from '../../components/SMSNotificationSignup';

import { calculateBTCAmount } from '../../utils/helpers';

// GraphQL Queries and Mutations
const GET_BITCOIN_PRICE = gql`
  query GetBitcoinPrice {
    getBitcoinPrice
  }
`;

const CREATE_BTCPAY_INVOICE = gql`
    mutation CreateBTCPayInvoice($amount: Float!, $currency: String!, $userId: String!, $mongodbProductId: ID!, $mongodbCampaignId: ID!, $quantity: Int!) {
        createBTCPayInvoice(amount: $amount, currency: $currency, userId: $userId, mongodbProductId: $mongodbProductId, mongodbCampaignId: $mongodbCampaignId, quantity: $quantity) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const GET_BTCPAY_INVOICE = gql`
    query GetBTCPayInvoice($invoiceId: String!) {
        getBTCPayInvoice(invoiceId: $invoiceId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const GET_CAMPAIGN = gql`
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      name
      description
      currentQuantity
      goalQuantity
      status
    }
  }
`;

const GrouHalfBeefBuy = () => {
    // State variables
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [checkoutLink, setCheckoutLink] = useState('');
    const [invoiceStatus, setInvoiceStatus] = useState('');
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [pledgeCount, setPledgeCount] = useState(0);
    const [btcPrice, setBtcPrice] = useState(0);
    const [btcAmount, setBtcAmount] = useState(0);
    const [unitCount, setUnitCount] = useState(1);
    const [priceLoaded, setPriceLoaded] = useState(false);

    // Constants
    const AUD_PRICE = 500; // $500 AUD per unit
    const GROUP_BUY_GOAL = 4; // 25 people for the group buy
    const MAX_UNITS_PER_HEAD = 1;
    const CAMPAIGN_ID = "half_beef_group_buy";
    const PRODUCT_ID = "66d5410dee37f9f12465468e";

    // Chakra UI color mode values
    const bgColor = useColorModeValue('orange.50', 'orange.900');
    const cardBgColor = useColorModeValue('white', 'orange.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const accentColor = useColorModeValue('orange.500', 'orange.300');
    const bannerBgColor = useColorModeValue('green.50', 'green.900');

    // Chakra UI color mode values

    const badgeColor = useColorModeValue('green.500', 'green.300');

    // Hooks
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const { user, isLoading } = useAuth();
    useInviteCode();

    // GraphQL Mutations and Queries
    const [createBTCPayInvoice] = useMutation(CREATE_BTCPAY_INVOICE);
    const { data: invoiceData, startPolling, stopPolling } = useQuery(GET_BTCPAY_INVOICE, {
        variables: { invoiceId },
        skip: !invoiceId,
        fetchPolicy: 'network-only',
    });

    const { data: btcPriceData, loading: btcPriceLoading } = useQuery(GET_BITCOIN_PRICE, {
        pollInterval: 60000, // Poll every minute
    });

    const { data: campaignData, loading: campaignLoading, refetch: refetchCampaign } = useQuery(GET_CAMPAIGN, {
        variables: { id: CAMPAIGN_ID },
        pollInterval: 30000, // Poll every 30 seconds
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Effects
    useEffect(() => {
        if (btcPriceData) {
            setBtcPrice(btcPriceData.getBitcoinPrice);
            const newBtcAmount = calculateBTCAmount(AUD_PRICE * unitCount, btcPriceData.getBitcoinPrice);
            setBtcAmount(newBtcAmount);
            setPriceLoaded(true);
        }
    }, [btcPriceData, unitCount]);

    useEffect(() => {
        if (priceLoaded) {
            const newBtcAmount = calculateBTCAmount(AUD_PRICE * unitCount, btcPrice);
            setBtcAmount(newBtcAmount);
        }
    }, [unitCount, btcPrice, priceLoaded]);

    useEffect(() => {
        if (invoiceId) {
            startPolling(1000);
        }
        return () => stopPolling();
    }, [invoiceId, startPolling, stopPolling]);

    useEffect(() => {
        if (invoiceData) {
            const status = invoiceData.getBTCPayInvoice.status;
            setInvoiceStatus(status);
            if (['Paid', 'Confirmed', 'Settled'].includes(status)) {
                setPaymentComplete(true);
                toast({
                    title: "Payment Completed",
                    description: "Your pledge for the Group Beef Buy has been processed.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                stopPolling();
            }
        }
    }, [invoiceData, stopPolling, toast, unitCount]);

    useEffect(() => {
        if (campaignData && campaignData.getCampaign) {
            setPledgeCount(campaignData.getCampaign.currentQuantity);
        }
    }, [campaignData]);

    // Handler Functions
    const handlePledge = async () => {
        if (!agreeToTerms) {
            toast({
                title: "Agreement Required",
                description: "Please agree to the terms before pledging your support.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (user) {
            try {
                const { data } = await createBTCPayInvoice({
                    variables: {
                        amount: btcAmount,
                        currency: "BTC",
                        userId: user.id,
                        mongodbProductId: PRODUCT_ID,
                        mongodbCampaignId: CAMPAIGN_ID,
                        quantity: unitCount
                    },
                });
                setInvoiceId(data.createBTCPayInvoice.id);
                setCheckoutLink(data.createBTCPayInvoice.checkoutLink);
                setInvoiceStatus(data.createBTCPayInvoice.status);
                setIsModalOpen(true);
                refetchCampaign();
            } catch (error: any) {
                console.error('Error creating BTCPay invoice:', error);
                toast({
                    title: "Error",
                    description: `Failed to process pledge: ${error.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            navigate('/login', { state: { from: location } });
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        if (paymentComplete) {
            toast({
                title: "Pledge Confirmed",
                description: "Thank you for your pledge to the Group Beef Buy!",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Pledge Pending",
                description: "Complete your payment to finalize your pledge.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleLearnMoreAboutProducerClick = () => {
        navigate('/forage-farms');
    };

    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    return (
        <Box bg={bgColor} minHeight="100vh">
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    {/* Hero Section */}
                    <Box
                        borderRadius="xl"
                        overflow="hidden"
                        boxShadow="2xl"
                        bg={cardBgColor}
                        position="relative"
                    >
                        <Image
                            src="/product_images/12BeefBox.png"
                            alt="Group Beef Buy"
                            width="100%"
                            height="300px"
                            objectFit="cover"
                            objectPosition="center"
                        />
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            right="0"
                            bottom="0"
                            bg="rgba(0,0,0,0.4)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            textAlign="center"
                            p={8}
                        >
                            <Heading as="h1" size="2xl" color="white" mb={4}>
                                1/2 Cow Share Group Buy
                            </Heading>
                            <Text fontSize="xl" color="white">
                                Join our bulk purchase for high-quality, locally sourced beef - everyone gets the same great cuts!
                            </Text>
                        </Box>
                    </Box>

                    {/* Introduction Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Text fontSize="xl" lineHeight="tall">
                            Participate in our group beef purchase and enjoy premium, locally sourced beef at a great price. This is an excellent opportunity to stock up on high-quality meat while supporting local farmers.
                        </Text>
                    </Box>

                    {/* Why Participate Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Why Join Our Group Buy?</Heading>
                        <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} gap={6}>
                            {[
                                "Access high-quality, locally sourced beef",
                                "Save money through bulk purchasing",
                                "Support local farmers and sustainable practices",
                                "Enjoy a variety of premium beef cuts",
                                "Know the source and quality of your meat",
                                "Participate in a community-driven initiative"
                            ].map((item, index) => (
                                <Box
                                    key={index}
                                    p={4}
                                    borderRadius="md"
                                    bg={bgColor}
                                    boxShadow="md"
                                >
                                    <HStack spacing={4} align="center">
                                        <CheckCircleIcon color={accentColor} />
                                        <Text fontWeight="bold" fontSize={["sm", "md"]}>
                                            {item}
                                        </Text>
                                    </HStack>
                                </Box>
                            ))}
                        </Grid>
                    </Box>

                    {/* How It Works Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Group Buy Details</Heading>
                        <VStack align="start" spacing={4}>
                            <Text><strong>Product:</strong> Premium Beef Box</Text>
                            <Text><strong>Price:</strong> ${AUD_PRICE} AUD per unit ({btcAmount.toFixed(8)} BTC)</Text>
                            <Text><strong>Group Buy Goal:</strong> {campaignData?.getCampaign.goalQuantity || GROUP_BUY_GOAL} participants</Text>
                            <Text><strong>Current Progress:</strong> {pledgeCount} out of {campaignData?.getCampaign.goalQuantity || GROUP_BUY_GOAL} pledges</Text>
                            <Box w="100%">
                                <Progress
                                    value={(pledgeCount / (campaignData?.getCampaign.goalQuantity || GROUP_BUY_GOAL)) * 100}
                                    colorScheme="orange"
                                />
                            </Box>
                            <Text><strong>Pickup Area:</strong> Local distribution points (TBA)</Text>
                        </VStack>
                    </Box>

                    {/* Join the Group Buy Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Join the Group Buy</Heading>
                        <Box mb={6}>
                            <Text fontSize="lg" mb={4}>
                                Choose the number of units:
                            </Text>
                            <Slider
                                aria-label="slider-ex-1"
                                defaultValue={1}
                                min={1}
                                max={MAX_UNITS_PER_HEAD}
                                step={1}
                                onChange={(val) => setUnitCount(val)}
                            >
                                <SliderMark value={1} mt='1' ml='-2.5' fontSize='sm'>
                                    1
                                </SliderMark>
                                <SliderMark value={MAX_UNITS_PER_HEAD} mt='1' ml='-2.5' fontSize='sm'>
                                    {MAX_UNITS_PER_HEAD}
                                </SliderMark>
                                <SliderMark
                                    value={unitCount}
                                    textAlign='center'
                                    bg='blue.500'
                                    color='white'
                                    mt='-10'
                                    ml='-5'
                                    w='12'
                                >
                                    {unitCount}
                                </SliderMark>
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                            <Text fontSize="lg" mt={6} mb={2}>
                                Your pledge: ${AUD_PRICE * unitCount} AUD ≈ {btcAmount.toFixed(8)} BTC
                            </Text>
                            <Text fontSize="sm" mb={2}>
                                Current BTC Price: 1 BTC = ${btcPriceLoading ? 'Loading...' : btcPrice.toFixed(2)} AUD
                            </Text>
                            <Text fontSize="xs" fontStyle="italic" mb={4}>
                                (Price updates every minute to ensure accuracy)
                            </Text>
                        </Box>
                        {user ? (
                            <VStack spacing={4} align="stretch">
                                <Checkbox
                                    isChecked={agreeToTerms}
                                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                                    colorScheme="orange"
                                >
                                    I agree to the group buy terms and conditions
                                </Checkbox>
                                <Button
                                    colorScheme="orange"
                                    size="lg"
                                    onClick={handlePledge}
                                    isDisabled={!agreeToTerms || campaignData?.getCampaign.status !== 'ACTIVE'}
                                    width="full"
                                    boxShadow="md"
                                    _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                    transition="all 0.2s"
                                >
                                    Pledge Now with Bitcoin Lightning
                                </Button>
                            </VStack>
                        ) : (
                            <Button
                                colorScheme="orange"
                                size="lg"
                                onClick={() => navigate('/login', { state: { from: location } })}
                                width="full"
                                boxShadow="md"
                                _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                transition="all 0.2s"
                            >
                                Login to Pledge
                            </Button>
                        )}
                        <Spacer m={10} />
                        <SMSNotificationSignup buttonText="Get Group Buy Updates via SMS" buttonColorScheme="orange" />
                    </Box>
                    {/* What's Included Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>What's Included in Your 1/2 Beef Box</Heading>
                        <Text fontSize="lg" mb={4}>
                            This is our 1/2 box of grass-fed and finished beef. <strong>Save 20% off the retail value.</strong>
                        </Text>
                        <Text fontSize="lg" mb={4}>
                            In this group buy, we're dividing a half cow (approximately 90kg) among 4 members. Each member will receive about 22.5kg of beef, comprising a variety of cuts. Please note that due to natural variations between animals, the exact weight may differ slightly, but we strive to ensure fair distribution.
                        </Text>
                        <Text fontSize="lg" mb={4}>
                            In the box, you will receive a mix of cuts that consists of:
                        </Text>
                        <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} gap={6}>
                            {[
                                "Scotch Fillet Steak",
                                "Rump Steak",
                                "Eye Fillet Steak",
                                "Sirloin Steak",
                                "Stir Fry Strips",
                                "BBQ Steak",
                                "Y-Bone Steak",
                                "Mince",
                                "Sausages",
                                "Blade Roast",
                                "Osso Bucco",
                                "Diced Beef",
                                "Nitrate-Free Corned Beef",
                                "Brisket Roast"
                            ].map((item, index) => (
                                <Box
                                    key={index}
                                    p={4}
                                    borderRadius="md"
                                    bg={bgColor}
                                    boxShadow="md"
                                >
                                    <HStack spacing={4} align="center">
                                        <CheckCircleIcon color={accentColor} />
                                        <Text fontWeight="bold" fontSize={["sm", "md"]}>
                                            {item}
                                        </Text>
                                    </HStack>
                                </Box>
                            ))}
                        </Grid>
                        <Text fontSize="sm" mt={4} fontStyle="italic">
                            Note: Exact quantities may vary slightly due to the natural variation in cattle size.
                        </Text>
                    </Box>

                    {/* About Our Beef Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>About Our Beef</Heading>
                        <Text fontSize="lg" mb={4}>
                            Our beef is sourced from local, ethical farms committed to sustainable practices:
                        </Text>
                        <List spacing={3} mb={6}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                100% grass-fed and grass-finished
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                No hormones or antibiotics used
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Raised on open pastures with humane treatment
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Supports local farmers and economies
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color={accentColor} />
                                Lower environmental impact compared to industrial farming
                            </ListItem>
                        </List>

                        {/* Producer Banner Card */}
                        <Box
                            borderRadius="lg"
                            overflow="hidden"
                            bg={bannerBgColor}
                            boxShadow="md"
                            mt={8}
                        >
                            <Flex direction={{ base: "column", md: "row" }} alignItems="center">
                                <Image
                                    src="/producer_images/forage_frm.jpeg"
                                    alt="Forage Farms"
                                    objectFit="cover"
                                    width={{ base: "100%", md: "200px" }}
                                    height={{ base: "200px", md: "100%" }}
                                />
                                <Box p={6} flex="1">
                                    <HStack spacing={4} mb={2}>
                                        <Heading as="h3" size="lg">Forage Farms</Heading>
                                        <Badge colorScheme="green" fontSize="md" px={2} py={1}>
                                            Verified Producer
                                        </Badge>
                                    </HStack>
                                    <Text fontSize="md" mb={4}>
                                        Family-owned regenerative farm dedicated to sustainable food production and ecosystem restoration.
                                    </Text>
                                    <Button
                                        // as={RouterLink}
                                        // to="/forage-farms"
                                        onClick={handleLearnMoreAboutProducerClick}
                                        colorScheme="green"
                                        rightIcon={<ExternalLinkIcon />}
                                    >
                                        Learn More About Our Producer
                                    </Button>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                </VStack>
            </Container>

            {/* Payment Status Modal */}
            <PaymentStatusModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                invoiceId={invoiceId}
                invoiceStatus={invoiceStatus}
                checkoutLink={checkoutLink}
                amount={invoiceData?.getBTCPayInvoice.amount}
                currency={invoiceData?.getBTCPayInvoice.currency}
            />

            <FooterWithFourColumns />
        </Box >
    );
};

export default GrouHalfBeefBuy;
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Heading,
    useToast,
    Flex,
    Textarea,
    Select,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from '@chakra-ui/react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';

const GET_PRODUCTS = gql`
  query GetProducts {
    getAllProducts {
      id
      name
      description
    }
  }
`;

const ADD_CAMPAIGN = gql`
  mutation AddCampaign($input: CampaignInput!) {
    createCampaign(input: $input) {
      id
      name
      description
      productId
      startDate
      endDate
      goalQuantity
      maxUnitsPerPurchase
      status
      campaignLocation
    }
  }
`;

const GET_ALL_CAMPAIGNS = gql`
  query GetAllCampaigns {
    getAllCampaigns {
      id
      name
      description
      productId
      startDate
      endDate
      goalQuantity
      currentQuantity
      maxUnitsPerPurchase
      status
      campaignLocation
    }
  }
`;

const AddCampaign = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [productId, setProductId] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [goalQuantity, setGoalQuantity] = useState('');
    const [maxUnitsPerPurchase, setMaxUnitsPerPurchase] = useState('');
    const [campaignLocation, setCampaignLocation] = useState('');

    const { user } = useAuth();
    const toast = useToast();

    const { loading: productsLoading, error: productsError, data: productsData } = useQuery(GET_PRODUCTS);
    const { loading: campaignsLoading, error: campaignsError, data: campaignsData, refetch: refetchCampaigns } = useQuery(GET_ALL_CAMPAIGNS);
    const [addCampaign, { loading: addCampaignLoading }] = useMutation(ADD_CAMPAIGN);


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const campaignInput = {
                name,
                description,
                productId,
                startDate,
                endDate,
                goalQuantity: parseInt(goalQuantity),
                maxUnitsPerPurchase: parseInt(maxUnitsPerPurchase),
                campaignLocation,
            };

            const { data } = await addCampaign({
                variables: {
                    input: campaignInput,
                },
            });
            console.log('Campaign added:', data.createCampaign);
            toast({
                title: "Campaign created",
                description: "Your new campaign has been successfully created.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            refetchCampaigns();
            // Clear form
            setName('');
            setDescription('');
            setProductId('');
            setStartDate('');
            setEndDate('');
            setGoalQuantity('');
            setMaxUnitsPerPurchase('');
            setCampaignLocation('');
        } catch (err) {
            console.error('Error adding campaign:', err);
            toast({
                title: "Error",
                description: "Failed to create campaign. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };


    // Utility function to format dates
    const formatDate = (dateString: string) => {
        const date = new Date(parseInt(dateString));
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };


    useEffect(() => {
        if (campaignsData && campaignsData.getAllCampaigns) {
            campaignsData.getAllCampaigns.forEach((campaign: any) => {
                console.log(`Campaign: ${campaign.name}`);
                console.log(`Start Date: ${campaign.startDate}`);
                console.log(`End Date: ${campaign.endDate}`);
                console.log('-------------------');
            });
        }
    }, [campaignsData]);

    return (
        <Flex direction="column" minHeight="100vh">
            <NavbarWithCallToAction />
            <Box flex={1} padding={8}>
                <VStack spacing={8} width="full" maxWidth="500px" margin="auto">
                    <Heading>Add New Campaign</Heading>
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <FormControl id="name" marginBottom={4} isRequired>
                            <FormLabel>Campaign Name</FormLabel>
                            <Input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="description" marginBottom={4}>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="productId" marginBottom={4} isRequired>
                            <FormLabel>Product</FormLabel>
                            <Select
                                placeholder="Select product"
                                value={productId}
                                onChange={(e) => setProductId(e.target.value)}
                            >
                                {productsData?.getAllProducts.map((product: any) => (
                                    <option key={product.id} value={product.id}>
                                        {product.name} - {product.id}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl id="startDate" marginBottom={4} isRequired>
                            <FormLabel>Start Date</FormLabel>
                            <Input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="endDate" marginBottom={4} isRequired>
                            <FormLabel>End Date</FormLabel>
                            <Input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="goalQuantity" marginBottom={4} isRequired>
                            <FormLabel>Goal Quantity</FormLabel>
                            <Input
                                type="number"
                                value={goalQuantity}
                                onChange={(e) => setGoalQuantity(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="maxUnitsPerPurchase" marginBottom={4} isRequired>
                            <FormLabel>Max Units Per Purchase</FormLabel>
                            <Input
                                type="number"
                                value={maxUnitsPerPurchase}
                                onChange={(e) => setMaxUnitsPerPurchase(e.target.value)}
                            />
                        </FormControl>

                        <FormControl id="campaignLocation" marginBottom={4} isRequired>
                            <FormLabel>Campaign Location</FormLabel>
                            <Select
                                placeholder="Select location"
                                value={campaignLocation}
                                onChange={(e) => setCampaignLocation(e.target.value)}
                            >
                                <option value="Noosa">Noosa</option>
                                <option value="South Brisbane">South Brisbane</option>
                            </Select>
                        </FormControl>

                        <Button type="submit" colorScheme="blue" width="full" isLoading={addCampaignLoading}>
                            Add Campaign
                        </Button>
                    </form>
                </VStack>

                <Box marginTop={12}>
                    <Heading size="lg" marginBottom={4}>All Campaigns</Heading>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Product ID</Th>
                                <Th>Start Date</Th>
                                <Th>End Date</Th>
                                <Th>Goal Quantity</Th>
                                <Th>Current Quantity</Th>
                                <Th>Status</Th>
                                <Th>Location</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {campaignsData?.getAllCampaigns.map((campaign: any) => (
                                <Tr key={campaign.id}>
                                    <Td>{campaign.name}</Td>
                                    <Td>{campaign.productId}</Td>
                                    <Td>{formatDate(campaign.startDate)}</Td>
                                    <Td>{formatDate(campaign.endDate)}</Td>
                                    <Td>{campaign.goalQuantity}</Td>
                                    <Td>{campaign.currentQuantity}</Td>
                                    <Td>{campaign.status}</Td>
                                    <Td>{campaign.campaignLocation}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Box>
            <FooterWithFourColumns />
        </Flex>
    );
};

export default AddCampaign;
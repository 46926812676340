import React, { useEffect } from 'react';
import {
    Box, Container, Heading, Text, VStack, Button, Image, Grid, GridItem,
    useColorModeValue, Flex, SimpleGrid, Avatar, Input,
    AspectRatio,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const ProducerForageFarms = () => {
    const bgColor = useColorModeValue('green.50', 'green.900');
    const cardBgColor = useColorModeValue('white', 'green.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const accentColor = useColorModeValue('green.500', 'green.300');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box bg={bgColor} minHeight="100vh">
            <NavbarWithCallToAction />

            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    {/* Hero Section with Image */}
                    <Box position="relative" height="500px" width="100%">
                        <Image
                            src="/producer_images/forage_frm.jpeg"
                            alt="Forage Farms landscape"
                            objectFit="cover"
                            width="100%"
                            height="100%"
                        />
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            bg="rgba(0,0,0,0.4)"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                            color="white"
                        >
                            <Heading as="h1" size="2xl" mb={4}>
                                Forage Farms
                            </Heading>
                            <Text fontSize="xl" mb={6}>
                                Regenerative Agriculture in Practice
                            </Text>
                            <Button colorScheme="green" size="lg">
                                Learn About Our Methods
                            </Button>
                        </Box>
                    </Box>

                    {/* About Us Section */}
                    <Box>
                        <Heading as="h2" size="xl" mb={6}>About Us</Heading>
                        <Text fontSize="lg" lineHeight="tall">
                            Forage Farms is a family-owned regenerative farm dedicated to producing high-quality, sustainable food while restoring our local ecosystem. We believe in working with nature, not against it.
                        </Text>
                    </Box>


                    {/* Our Story Section */}
                    <Box>
                        <Heading as="h2" size="xl" mb={6}>Our Story</Heading>
                        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={8}>
                            <VStack align="stretch" spacing={4}>
                                <Text fontSize="lg" lineHeight="tall">
                                    Forage Farms is a family operation located south of Gympie in the beautiful Mary Valley. The story behind Forage Farms begins in the Bylong Valley where three generations of Andrews have lived on "Tarwyn Park" the celebrated home of Natural Sequence Farming, as developed by Stuart's father Peter Andrews.
                                </Text>
                                <Text fontSize="lg" lineHeight="tall">
                                    For over 40 years the family has been dedicated to understanding, practising and teaching methods of land rehabilitation. Natural Sequence Farming is used as a system of farming focused on implementing natural landscape functions back into degraded landscapes.
                                </Text>
                                <Text fontSize="lg" lineHeight="tall">
                                    As a way of broadening the understanding of Natural Sequence Farming, Forage Farms was born to merge food production with landscape restoration. We added some foragers to the system to give us a helping hand in implementing natural landscape functions.
                                </Text>
                            </VStack>
                            <VStack align="stretch" spacing={4}>
                                {/* Placeholder for image */}
                                <Image
                                    src="/producer_images/forage_family.jpeg"
                                    alt="Forage Farms family"
                                    objectFit="cover"
                                    width="100%"
                                    height="300px"
                                />
                                {/* YouTube Video */}
                                <AspectRatio ratio={16 / 9}>
                                    <iframe
                                        src="https://www.youtube.com/embed/0DUQKUlXJ9s"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                </AspectRatio>
                            </VStack>
                        </Grid>
                        <Text fontSize="lg" lineHeight="tall" mt={4}>
                            We have created a Grow + Forage + Feed experience for the community:
                        </Text>
                        <UnorderedList mt={2} spacing={2}>
                            <ListItem><strong>Grow</strong> our produce regeneratively ensuring that our animals are the best around, living in the ultimate pastured and natural environment.</ListItem>
                            <ListItem><strong>Forage</strong> our produce fresh from the paddock as it comes with nothing added but the pure goodness that comes naturally with only the best produce.</ListItem>
                            <ListItem><strong>Feed</strong> our produce to the community so they can enjoy, along with us, the best pastured produce around.</ListItem>
                        </UnorderedList>
                        <Text fontSize="lg" lineHeight="tall" mt={4}>
                            So, the new chapter begins and we welcome you to join us as we start a new chapter of regenerative agriculture.
                        </Text>
                    </Box>

                    {/* What We Do Section */}
                    <Box>
                        <Heading as="h2" size="xl" mb={6}>What we do</Heading>
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                            {[
                                {
                                    title: 'Pasture-Raised Pork',
                                    description: 'Ethically raised pork with superior taste and quality.',
                                    link: '/products/pork',
                                    image: '/producer_images/forage_farms/pig.png'
                                },
                                {
                                    title: 'Grass-Fed Beef',
                                    description: 'Lean, flavorful beef from cattle raised on open pastures.',
                                    link: '/products/beef',
                                    image: '/producer_images/forage_farms/beef.png'
                                },
                                {
                                    title: 'Smoked Meats',
                                    description: 'Artisanal smoked meats using traditional techniques.',
                                    link: '/products/smoked-meats',
                                    image: '/producer_images/forage_farms/bacon.png'
                                },
                                {
                                    title: 'Sausages',
                                    description: 'Handcrafted sausages made with our quality meats and spices.',
                                    link: '/products/sausages',
                                    image: '/producer_images/forage_farms/sausages.png'
                                },
                                {
                                    title: 'Mince',
                                    description: 'Versatile, high-quality mince from our pasture-raised animals.',
                                    link: '/products/mince',
                                    image: '/producer_images/forage_farms/mince.png'
                                },
                                {
                                    title: 'Chicken & Eggs',
                                    description: 'Free-range chicken and eggs from happy, healthy hens.',
                                    link: '/products/poultry',
                                    image: '/producer_images/forage_farms/eggs.png'
                                }
                            ].map((item, index) => (
                                <Box key={index} p={5} shadow="md" borderWidth="1px" borderRadius="md" overflow="hidden">
                                    <Image
                                        src={item.image}
                                        alt={item.title}
                                        objectFit="cover"
                                        width="100%"
                                        height="200px"
                                        mb={4}
                                    />
                                    <Heading fontSize="xl" mb={2}>{item.title}</Heading>
                                    <Text mb={4}>{item.description}</Text>
                                    <Button as="a" href={item.link} colorScheme="green" size="sm">
                                        Learn More
                                    </Button>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box>

                    {/* Articles Section */}
                    <Box>
                        <Heading as="h2" size="xl" mb={6}>Latest Articles</Heading>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                            {['The Benefits of Rotational Grazing', 'Soil Health: The Foundation of Our Farm'].map((title, index) => (
                                <Box key={index} p={5} shadow="md" borderWidth="1px" borderRadius="md">
                                    <Heading fontSize="xl">{title}</Heading>
                                    <Text mt={4}>Brief excerpt from the article...</Text>
                                    <Button mt={4} colorScheme="green" variant="outline">Read More</Button>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box>

                    {/* Events Section */}
                    <Box>
                        <Heading as="h2" size="xl" mb={6}>Upcoming Produce Group Buys</Heading>
                        <VStack spacing={4} align="stretch">
                            {['Farm Tour - June 15th', 'Regenerative Agriculture Workshop - July 8th'].map((event, index) => (
                                <Box key={index} p={5} shadow="md" borderWidth="1px" borderRadius="md">
                                    <Heading fontSize="xl">{event}</Heading>
                                    <Text mt={2}>Brief description of the event...</Text>
                                    <Button mt={4} colorScheme="green">Learn More</Button>
                                </Box>
                            ))}
                        </VStack>
                    </Box>

                    {/* Testimonials Section */}
                    <Box>
                        <Heading as="h2" size="xl" mb={6}>What Our Customers Say</Heading>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                            {[
                                { name: 'John Doe', text: 'The quality of produce from Green Meadows Farm is unmatched. You can taste the difference!' },
                                { name: 'Jane Smith', text: 'I love supporting a farm that cares so much about the environment. Their practices are truly inspiring.' }
                            ].map((testimonial, index) => (
                                <Box key={index} p={5} shadow="md" borderWidth="1px" borderRadius="md">
                                    <Text fontSize="md" fontStyle="italic">"{testimonial.text}"</Text>
                                    <Flex align="center" mt={4}>
                                        <Avatar size="sm" mr={2} />
                                        <Text fontWeight="bold">{testimonial.name}</Text>
                                    </Flex>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box>

                    {/* Newsletter Signup */}
                    <Box bg={cardBgColor} p={8} borderRadius="lg" boxShadow="xl">
                        <Heading as="h3" size="lg" mb={4}>Join Our Community</Heading>
                        <Text mb={4}>Sign up to receive the latest news, articles, and event announcements.</Text>
                        <Flex>
                            <Input placeholder="Enter your email" mr={2} />
                            <Button colorScheme="green">Subscribe</Button>
                        </Flex>
                    </Box>
                </VStack>
            </Container>

            <FooterWithFourColumns />
        </Box>
    );
};

export default ProducerForageFarms;
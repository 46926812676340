// src/pages/Wallet.tsx

import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    HStack,
    Input,
    Button,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Tooltip,
    useClipboard,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    useColorModeValue,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    SimpleGrid,
    TableContainer,
    StatGroup,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';
import QRCode from 'qrcode.react';

interface Transaction {
    id: string;
    type: 'INVOICE' | 'PAYMENT';
    amount: number;
    status: 'PENDING' | 'COMPLETED' | 'FAILED';
    timestamp: string;
    paymentRequest?: string;
}

const GET_USER_BALANCE = gql`
  query GetUserBalance($userId: ID!) {
    getUserBalance(userId: $userId) {
      balance
      lastUpdated
    }
  }
`;

const GET_USER_TRANSACTIONS = gql`
  query GetUserTransactions($userId: ID!, $limit: Int, $offset: Int) {
    getUserTransactions(userId: $userId, limit: $limit, offset: $offset) {
      id
      type
      amount
      status
      timestamp
      paymentRequest
    }
  }
`;


const CREATE_INVOICE = gql`
  mutation CreateInvoice($userId: ID!, $amount: Float!, $memo: String) {
    createInvoice(userId: $userId, amount: $amount, memo: $memo) {
      id
      status
      lndInvoiceId
    }
  }
`;

const PAY_INVOICE = gql`
  mutation PayInvoice($userId: ID!, $paymentRequest: String!, $amount: Float!) {
    payInvoice(userId: $userId, paymentRequest: $paymentRequest, amount: $amount) {
      id
      status
    }
  }
`;

const Wallet: React.FC = () => {
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const pageBgColor = useColorModeValue('gray.50', 'gray.900');

    const [selectedPaymentRequest, setSelectedPaymentRequest] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [invoiceAmount, setInvoiceAmount] = useState('');
    const [invoiceMemo, setInvoiceMemo] = useState('');
    const [paymentRequest, setPaymentRequest] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [copyValue, setCopyValue] = useState('');

    const { onCopy, hasCopied } = useClipboard(copyValue);

    const toast = useToast();
    const { user } = useAuth();

    const { data: balanceData, loading: balanceLoading, error: balanceError, refetch: refetchBalance } = useQuery(GET_USER_BALANCE, {
        variables: { userId: user?.id },
        skip: !user?.id,
    });

    // const { data: transactionsData, loading: transactionsLoading, error: transactionsError, refetch: refetchTransactions } = useQuery(GET_USER_TRANSACTIONS, {
    //     variables: { userId: user?.id, limit: 10, offset: 0 },
    //     skip: !user?.id,
    // });

    const { data: transactionsData, loading: transactionsLoading, error: transactionsError, fetchMore } = useQuery(GET_USER_TRANSACTIONS, {
        variables: { userId: user?.id, limit: 20, offset: 0 },
        skip: !user?.id,
    });

    const loadMoreTransactions = () => {
        fetchMore({
            variables: {
                offset: transactionsData.getUserTransactions.length,
                limit: 20,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    getUserTransactions: [...prev.getUserTransactions, ...fetchMoreResult.getUserTransactions]
                });
            }
        });
    };


    const handleShowQRCode = (paymentRequest: string) => {
        setSelectedPaymentRequest(paymentRequest);
        onOpen();
    };

    const [createInvoice, { loading: createInvoiceLoading }] = useMutation(CREATE_INVOICE);
    const [payInvoice, { loading: payInvoiceLoading }] = useMutation(PAY_INVOICE);

    const handleCreateInvoice = async () => {
        if (!user?.id || !invoiceAmount) return;
        try {
            const { data } = await createInvoice({
                variables: { userId: user.id, amount: parseFloat(invoiceAmount), memo: invoiceMemo },
            });
            toast({
                title: 'Invoice Created',
                description: `Invoice ID: ${data.createInvoice.lndInvoiceId}`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            setInvoiceAmount('');
            setInvoiceMemo('');
            refetchBalance();

            fetchMore({
                variables: { userId: user?.id, limit: 20, offset: 0 },
                updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to create invoice',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handlePayInvoice = async () => {
        if (!user?.id || !paymentRequest || !paymentAmount) return;
        try {
            await payInvoice({
                variables: { userId: user.id, paymentRequest, amount: parseFloat(paymentAmount) },
            });
            toast({
                title: 'Payment Sent',
                description: 'Invoice paid successfully',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            setPaymentRequest('');
            setPaymentAmount('');
            refetchBalance();
            fetchMore({
                variables: { userId: user?.id, limit: 20, offset: 0 },
                updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to pay invoice',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleCopyInvoiceURL = (paymentRequest: string) => {
        setCopyValue(paymentRequest);
        onCopy();
        toast({
            title: "Invoice URL copied to clipboard",
            status: "success",
            duration: 2000,
            isClosable: true,
        });
    };

    // Add this conversion function
    const convertSatsToAUD = (sats: number) => {
        const satsToBTC = sats / 100000000; // 1 BTC = 100,000,000 sats
        const BTCToAUD = 79821.14; // Current exchange rate (you might want to fetch this dynamically)
        return (satsToBTC * BTCToAUD).toFixed(2);
    };

    if (!user) {
        return (
            <Box bg={pageBgColor}>
                <NavbarWithCallToAction />
                <Container maxW="container.xl" py={8}>
                    <Text>Please log in to access your wallet.</Text>
                </Container>
                <FooterWithFourColumns />
            </Box>
        );
    }

    return (
        <Box bg={pageBgColor}>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={10}>
                <VStack spacing={10} align="stretch">
                    <Box
                        bg={bgColor}
                        boxShadow="xl"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h1" size="xl" mb={6}>Wallet</Heading>

                        {/* Balance Section */}
                        <StatGroup>
                            <Stat>
                                <StatLabel>Balance</StatLabel>
                                {balanceLoading ? (
                                    <Spinner />
                                ) : balanceError ? (
                                    <Text color="red.500">Error loading balance</Text>
                                ) : (
                                    <>
                                        <StatNumber>{balanceData?.getUserBalance.balance} sats</StatNumber>
                                        <StatHelpText>
                                            AUD ${convertSatsToAUD(balanceData?.getUserBalance.balance)}
                                        </StatHelpText>
                                    </>
                                )}
                            </Stat>
                        </StatGroup>
                    </Box>

                    <Box
                        bg={bgColor}
                        boxShadow="xl"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h2" size="lg" mb={4}>Create Invoice</Heading>
                        <VStack spacing={4}>
                            <Input
                                placeholder="Amount (sats)"
                                value={invoiceAmount}
                                onChange={(e) => setInvoiceAmount(e.target.value)}
                                type="number"
                            />
                            <Input
                                placeholder="Memo (optional)"
                                value={invoiceMemo}
                                onChange={(e) => setInvoiceMemo(e.target.value)}
                            />
                            <Button
                                onClick={handleCreateInvoice}
                                isLoading={createInvoiceLoading}
                                loadingText="Creating Invoice"
                                colorScheme="blue"
                                width="full"
                            >
                                Create Invoice
                            </Button>
                        </VStack>
                    </Box>

                    <Box
                        bg={bgColor}
                        boxShadow="xl"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h2" size="lg" mb={4}>Pay Invoice</Heading>
                        <VStack spacing={4}>
                            <Input
                                placeholder="Payment Request"
                                value={paymentRequest}
                                onChange={(e) => setPaymentRequest(e.target.value)}
                            />
                            <Input
                                placeholder="Amount (sats)"
                                value={paymentAmount}
                                onChange={(e) => setPaymentAmount(e.target.value)}
                                type="number"
                            />
                            <Button
                                onClick={handlePayInvoice}
                                isLoading={payInvoiceLoading}
                                loadingText="Paying Invoice"
                                colorScheme="green"
                                width="full"
                            >
                                Pay Invoice
                            </Button>
                        </VStack>
                    </Box>

                    <Box
                        bg={bgColor}
                        boxShadow="xl"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h2" size="lg" mb={4}>Transaction History</Heading>
                        {transactionsLoading && <Spinner />}
                        {transactionsError && <Text color="red.500">Error loading transactions: {transactionsError.message}</Text>}
                        {transactionsData && (
                            <TableContainer>
                                <Table variant="simple" size="sm">
                                    <Thead>
                                        <Tr>
                                            <Th>Type</Th>
                                            <Th>Amount</Th>
                                            <Th>Status</Th>
                                            <Th>Date</Th>
                                            <Th>Payment Request</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {transactionsData.getUserTransactions.map((tx: Transaction) => (
                                            <Tr key={tx.id}>
                                                <Td>{tx.type}</Td>
                                                <Td>
                                                    {tx.amount} sats
                                                    <br />
                                                    (AUD ${convertSatsToAUD(tx.amount)})
                                                </Td>
                                                <Td>{tx.status}</Td>
                                                <Td>{new Date(parseInt(tx.timestamp)).toLocaleString()}</Td>
                                                <Td>
                                                    {tx.type === 'INVOICE' && tx.paymentRequest && (
                                                        <VStack spacing={2} align="start">
                                                            <Tooltip
                                                                label={hasCopied ? "Copied!" : "Click to copy"}
                                                                placement="top"
                                                            >
                                                                <Text
                                                                    isTruncated
                                                                    maxWidth="150px"
                                                                    cursor="pointer"
                                                                    onClick={() => handleCopyInvoiceURL(tx.paymentRequest!)}
                                                                >
                                                                    {tx.paymentRequest}
                                                                </Text>
                                                            </Tooltip>
                                                            <HStack>
                                                                <Button
                                                                    size="xs"
                                                                    onClick={() => handleShowQRCode(tx.paymentRequest!)}
                                                                >
                                                                    Show QR
                                                                </Button>
                                                                <Button
                                                                    size="xs"
                                                                    onClick={() => handleCopyInvoiceURL(tx.paymentRequest!)}
                                                                >
                                                                    Copy URL
                                                                </Button>
                                                            </HStack>
                                                        </VStack>
                                                    )}



                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        )}
                        {transactionsData && transactionsData.getUserTransactions.length >= 20 && (
                            <Button onClick={loadMoreTransactions} mt={4}>
                                Load More
                            </Button>
                        )}
                    </Box>
                </VStack>
            </Container>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Invoice QR Code</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box display="flex" justifyContent="center" alignItems="center" p={4}>
                            <QRCode value={selectedPaymentRequest} size={256} />
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <FooterWithFourColumns />
        </Box>
    );
};

export default Wallet;
import React from 'react';
import {
    Box, Container, Heading, Text, VStack, SimpleGrid, Stat, StatLabel, StatNumber, StatHelpText,
    Progress, Table, Thead, Tbody, Tr, Th, Td, useColorModeValue, Tooltip, Button,
    TableContainer
} from '@chakra-ui/react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import { InfoIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';



const weeklyComparisonData2024 = [
    { financialWeek: 1, dateRange: 'Jul 1, 2023 - Jul 7, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 2, dateRange: 'Jul 8, 2023 - Jul 14, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 3, dateRange: 'Jul 15, 2023 - Jul 21, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 4, dateRange: 'Jul 22, 2023 - Jul 28, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 5, dateRange: 'Jul 29, 2023 - Aug 4, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 6, dateRange: 'Aug 5, 2023 - Aug 11, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 7, dateRange: 'Aug 12, 2023 - Aug 18, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 8, dateRange: 'Aug 19, 2023 - Aug 25, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 9, dateRange: 'Aug 26, 2023 - Sep 1, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 10, dateRange: 'Sep 2, 2023 - Sep 8, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 11, dateRange: 'Sep 9, 2023 - Sep 15, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 12, dateRange: 'Sep 16, 2023 - Sep 22, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 13, dateRange: 'Sep 23, 2023 - Sep 29, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 14, dateRange: 'Sep 30, 2023 - Oct 6, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 15, dateRange: 'Oct 7, 2023 - Oct 13, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 16, dateRange: 'Oct 14, 2023 - Oct 20, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 17, dateRange: 'Oct 21, 2023 - Oct 27, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 18, dateRange: 'Oct 28, 2023 - Nov 3, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 19, dateRange: 'Nov 4, 2023 - Nov 10, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 20, dateRange: 'Nov 11, 2023 - Nov 17, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 21, dateRange: 'Nov 18, 2023 - Nov 24, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 22, dateRange: 'Nov 25, 2023 - Dec 1, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 23, dateRange: 'Dec 2, 2023 - Dec 8, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 24, dateRange: 'Dec 9, 2023 - Dec 15, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 25, dateRange: 'Dec 16, 2023 - Dec 22, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 26, dateRange: 'Dec 23, 2023 - Dec 29, 2023', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 27, dateRange: 'Dec 30, 2023 - Jan 5, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 28, dateRange: 'Jan 6, 2024 - Jan 12, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 29, dateRange: 'Jan 13, 2024 - Jan 19, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 30, dateRange: 'Jan 20, 2024 - Jan 26, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 31, dateRange: 'Jan 27, 2024 - Feb 2, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 32, dateRange: 'Feb 3, 2024 - Feb 9, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 33, dateRange: 'Feb 10, 2024 - Feb 16, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 34, dateRange: 'Feb 17, 2024 - Feb 23, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 35, dateRange: 'Feb 24, 2024 - Mar 1, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 36, dateRange: 'Mar 2, 2024 - Mar 8, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 37, dateRange: 'Mar 9, 2024 - Mar 15, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 38, dateRange: 'Mar 16, 2024 - Mar 22, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 39, dateRange: 'Mar 23, 2024 - Mar 29, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 40, dateRange: 'Mar 30, 2024 - Apr 5, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 41, dateRange: 'Apr 6, 2024 - Apr 12, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 42, dateRange: 'Apr 13, 2024 - Apr 19, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 43, dateRange: 'Apr 20, 2024 - Apr 26, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 44, dateRange: 'Apr 27, 2024 - May 3, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 45, dateRange: 'May 4, 2024 - May 10, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 46, dateRange: 'May 11, 2024 - May 17, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 47, dateRange: 'May 18, 2024 - May 24, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 48, dateRange: 'May 25, 2024 - May 31, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 49, dateRange: 'Jun 1, 2024 - Jun 7, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 50, dateRange: 'Jun 8, 2024 - Jun 14, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 51, dateRange: 'Jun 15, 2024 - Jun 21, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 52, dateRange: 'Jun 22, 2024 - Jun 28, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 53, dateRange: 'Jun 29, 2024 - Jun 30, 2024', woolworthsSales: 372.91, colesSales: 285.71, aldiSales: 142.86, igaSales: 71.43, pledgeSales: 0 }
];











const weeklyComparisonData2025 = [
    { financialWeek: 1, dateRange: 'Jul 1, 2024 - Jul 7, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 2, dateRange: 'Jul 8, 2024 - Jul 14, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 3, dateRange: 'Jul 15, 2024 - Jul 21, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 4, dateRange: 'Jul 22, 2024 - Jul 28, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0 },
    { financialWeek: 5, dateRange: 'Jul 29, 2024 - Aug 4, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0.0005 },
    { financialWeek: 6, dateRange: 'Aug 5, 2024 - Aug 11, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0.0006 },
    { financialWeek: 7, dateRange: 'Aug 12, 2024 - Aug 18, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0.0007 },
    { financialWeek: 8, dateRange: 'Aug 19, 2024 - Aug 25, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0.0008 },
    { financialWeek: 9, dateRange: 'Aug 26, 2024 - Sep 1, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0.0009 },
    { financialWeek: 10, dateRange: 'Sep 2, 2024 - Sep 8, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0.002 },
    { financialWeek: 11, dateRange: 'Sep 9, 2024 - Sep 15, 2024', woolworthsSales: 1306.19, colesSales: 1000.00, aldiSales: 500.00, igaSales: 250.00, pledgeSales: 0.0021 },
    { financialWeek: 12, dateRange: 'Sep 16, 2024 - Sep 22, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 13, dateRange: 'Sep 23, 2024 - Sep 29, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    // ... Weeks 14-52 with all sales at 0 ...
    { financialWeek: 14, dateRange: 'Sep 30, 2024 - Oct 6, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 15, dateRange: 'Oct 7, 2024 - Oct 13, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 16, dateRange: 'Oct 14, 2024 - Oct 20, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 17, dateRange: 'Oct 21, 2024 - Oct 27, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 18, dateRange: 'Oct 28, 2024 - Nov 3, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 19, dateRange: 'Nov 4, 2024 - Nov 10, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 20, dateRange: 'Nov 11, 2024 - Nov 17, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 21, dateRange: 'Nov 18, 2024 - Nov 24, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 22, dateRange: 'Nov 25, 2024 - Dec 1, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 23, dateRange: 'Dec 2, 2024 - Dec 8, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 24, dateRange: 'Dec 9, 2024 - Dec 15, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 25, dateRange: 'Dec 16, 2024 - Dec 22, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 26, dateRange: 'Dec 23, 2024 - Dec 29, 2024', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 27, dateRange: 'Dec 30, 2024 - Jan 5, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 28, dateRange: 'Jan 6, 2025 - Jan 12, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 29, dateRange: 'Jan 13, 2025 - Jan 19, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 30, dateRange: 'Jan 20, 2025 - Jan 26, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 31, dateRange: 'Jan 27, 2025 - Feb 2, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 32, dateRange: 'Feb 3, 2025 - Feb 9, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 33, dateRange: 'Feb 10, 2025 - Feb 16, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 34, dateRange: 'Feb 17, 2025 - Feb 23, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 35, dateRange: 'Feb 24, 2025 - Mar 2, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 36, dateRange: 'Mar 3, 2025 - Mar 9, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 37, dateRange: 'Mar 10, 2025 - Mar 16, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 38, dateRange: 'Mar 17, 2025 - Mar 23, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 39, dateRange: 'Mar 24, 2025 - Mar 30, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 40, dateRange: 'Mar 31, 2025 - Apr 6, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 41, dateRange: 'Apr 7, 2025 - Apr 13, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 42, dateRange: 'Apr 14, 2025 - Apr 20, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 43, dateRange: 'Apr 21, 2025 - Apr 27, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 44, dateRange: 'Apr 28, 2025 - May 4, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 45, dateRange: 'May 5, 2025 - May 11, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 46, dateRange: 'May 12, 2025 - May 18, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 47, dateRange: 'May 19, 2025 - May 25, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 48, dateRange: 'May 26, 2025 - Jun 1, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 49, dateRange: 'Jun 2, 2025 - Jun 8, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 50, dateRange: 'Jun 9, 2025 - Jun 15, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 51, dateRange: 'Jun 16, 2025 - Jun 22, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 52, dateRange: 'Jun 23, 2025 - Jun 29, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 },
    { financialWeek: 53, dateRange: 'Jun 30, 2025', woolworthsSales: 0, colesSales: 0, aldiSales: 0, igaSales: 0, pledgeSales: 0 }
];



// Add this new data
const chartData = [
    { name: 'Sep 9-15', farmSales: 30, supermarketSales: 100 },
    { name: 'Sep 16-22', farmSales: 40, supermarketSales: 98 },
    { name: 'Sep 23-29', farmSales: 45, supermarketSales: 95 },
    { name: 'Sep 30-Oct 6', farmSales: 50, supermarketSales: 90 },
];

// Add this new data
const retailerData = [
    { name: 'Woolworths Group', weeklySales: 1306.19, monthlySales: 5660.17 },
    { name: 'Coles Group', weeklySales: 1000, monthlySales: 4333.33 },
    { name: 'Aldi', weeklySales: 500, monthlySales: 2166.67 },
    { name: 'IGA', weeklySales: 250, monthlySales: 1083.33 },
];


// Updated static data
const salesData = {
    weeklyFarmSales: 50,
    totalFarmSales: 1000,
    estimatedSupermarketSales: 5000,
    moneyKeptInCommunity: 23800,
    carbonFootprintSaved: 500
};

// New data for weekly and monthly comparisons
const weeklyComparisonData = [
    { week: 1, date: 'Sep 9-15', pledgeSales: 0, supermarketSales: 1306.19 },
    { week: 2, date: 'Sep 16-22', pledgeSales: 0.002, supermarketSales: 1306.19 },
    { week: 3, date: 'Sep 23-29', pledgeSales: 0.0025, supermarketSales: 1306.19 },
    { week: 4, date: 'Sep 30-Oct 6', pledgeSales: 0.003, supermarketSales: 1306.19 },
    // Add more weeks as needed
];

const monthlyComparisonData = [
    { month: 'September', pledgeSales: 0.0045, supermarketSales: 5660.17 },
    { month: 'October', pledgeSales: 0.013, supermarketSales: 5660.17 },
    { month: 'November', pledgeSales: 0, supermarketSales: 5660.17 },
    { month: 'December', pledgeSales: 0, supermarketSales: 5660.17 },
    // Add more months as needed
];

const FarmSalesDashboard = () => {
    const bgColor = useColorModeValue('green.50', 'green.900');
    const cardBgColor = useColorModeValue('white', 'green.800');

    // Helper function for tooltips
    const InfoTooltip = ({ label, content }: any) => (
        <Tooltip label={content}>
            <Button size="sm" variant="ghost" ml={2}>
                <InfoIcon />
            </Button>
        </Tooltip>
    );

    return (
        <Box bg={bgColor} minHeight="100vh">
            <NavbarWithCallToAction />
            <Box bg={bgColor} minHeight="100vh" py={12}>
                <Container maxW="container.xl">
                    <VStack spacing={8} align="stretch">
                        <Heading as="h1" size="2xl" textAlign="center">Farm Sales Impact Dashboard</Heading>
                        <Text textAlign="center">
                            This dashboard tracks the impact of direct producer-to-consumer transactions,
                            showing how we're shifting market share from large corporations back to local producers.
                        </Text>

                        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
                            <Stat bg={cardBgColor} p={4} borderRadius="lg" boxShadow="md">
                                <StatLabel>Weekly Farm Sales</StatLabel>
                                <StatNumber>{salesData.weeklyFarmSales}</StatNumber>
                                <StatHelpText>chickens sold directly</StatHelpText>
                                <InfoTooltip content="Number of chickens sold directly to consumers in the past week" label={undefined} />
                            </Stat>
                            <Stat bg={cardBgColor} p={4} borderRadius="lg" boxShadow="md">
                                <StatLabel>Total Farm Sales</StatLabel>
                                <StatNumber>{salesData.totalFarmSales}</StatNumber>
                                <StatHelpText>chickens sold to date</StatHelpText>
                                <InfoTooltip content="Total number of chickens sold directly to consumers since the start of this initiative" label={undefined} />
                            </Stat>
                            <Stat bg={cardBgColor} p={4} borderRadius="lg" boxShadow="md">
                                <StatLabel>Money Kept in Community</StatLabel>
                                <StatNumber>${salesData.moneyKeptInCommunity.toLocaleString()}</StatNumber>
                                <StatHelpText>vs. supermarket sales</StatHelpText>
                                <InfoTooltip content="Estimated amount of money that stayed in the local community instead of going to large supermarkets" label={undefined} />
                            </Stat>
                            <Stat bg={cardBgColor} p={4} borderRadius="lg" boxShadow="md">
                                <StatLabel>Carbon Footprint Saved</StatLabel>
                                <StatNumber>{salesData.carbonFootprintSaved} kg</StatNumber>
                                <StatHelpText>CO2 equivalent</StatHelpText>
                                <InfoTooltip content="Estimated reduction in carbon emissions due to shorter supply chains and sustainable farming practices" label={undefined} />
                            </Stat>
                        </SimpleGrid>

                        <Box bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
                            <Heading as="h3" size="lg" mb={4}>Sales Comparison</Heading>
                            <Text mb={4}>
                                This chart compares direct farm sales to estimated supermarket sales over time,
                                showing the shift in market share towards local producers.
                            </Text>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={chartData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <RechartsTooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="farmSales" stroke="#82ca9d" name="Farm Sales" />
                                    <Line type="monotone" dataKey="supermarketSales" stroke="#8884d8" name="Supermarket Sales" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Box>

                        <Box bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
                            <Heading as="h3" size="lg" mb={4}>Community Impact</Heading>
                            <Text mb={4}>Percentage of local market share captured:</Text>
                            <Progress
                                value={(salesData.totalFarmSales / (salesData.totalFarmSales + salesData.estimatedSupermarketSales)) * 100}
                                colorScheme="green"
                                size="lg"
                                mb={4}
                            />
                            <InfoTooltip content="This progress bar shows the percentage of the local chicken market that direct farm sales have captured from supermarkets, (we only know what we know)." label={undefined} />
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Metric</Th>
                                        <Th isNumeric>Value</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>Jobs Supported</Td>
                                        <Td isNumeric>{Math.floor(salesData.totalFarmSales / 100)}</Td>
                                        <Td>
                                            <InfoTooltip content="Estimated number of local jobs supported by direct farm sales" label={undefined} />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Local Businesses Impacted</Td>
                                        <Td isNumeric>{Math.floor(salesData.totalFarmSales / 50)}</Td>
                                        <Td>
                                            <InfoTooltip content="Estimated number of local businesses positively impacted by the increase in direct farm sales" label={undefined} />
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </Box>
                        <Box bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
                            <Heading as="h3" size="lg" mb={4}>Major Retailer Sales Comparison</Heading>
                            <Text mb={4}>
                                This table compares the weekly and monthly sales of major Australian retailers.
                            </Text>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Retailer</Th>
                                        <Th isNumeric>Weekly Sales (Millions AUD)</Th>
                                        <Th isNumeric>Monthly Sales (Millions AUD)</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {retailerData.map((retailer: { name: string; weeklySales: number; monthlySales: number }, index: number) => (
                                        <Tr key={index}>
                                            <Td>{retailer.name}</Td>
                                            <Td isNumeric>{retailer.weeklySales.toFixed(2)}</Td>
                                            <Td isNumeric>{retailer.monthlySales.toFixed(2)}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                            <Text mt={4} fontSize="sm">
                                Note: Data for Coles Group, Aldi, and IGA are placeholders and should be updated with actual figures when available.
                            </Text>
                        </Box>
                    </VStack>
                </Container>
            </Box>

            <Box bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
                <Heading as="h3" size="lg" mb={4}>Weekly Sales Comparison - Financial Year 2024</Heading>
                <Text mb={4}>
                    This table compares our weekly pledge sales to major Australian retailers' sales for the 2024 financial year.
                </Text>
                <TableContainer>
                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr>
                                <Th>Financial Week</Th>
                                <Th>Date Range</Th>
                                <Th isNumeric>Woolworths (Millions AUD)</Th>
                                <Th isNumeric>Coles (Millions AUD)</Th>
                                <Th isNumeric>Aldi (Millions AUD)</Th>
                                <Th isNumeric>IGA (Millions AUD)</Th>
                                <Th isNumeric>Pledge Sales (Millions AUD)</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {weeklyComparisonData2024.map((week, index) => (
                                <Tr key={index}>
                                    <Td>{week.financialWeek}</Td>
                                    <Td>{week.dateRange}</Td>
                                    <Td isNumeric>{week.woolworthsSales.toFixed(2)}</Td>
                                    <Td isNumeric>{week.colesSales.toFixed(2)}</Td>
                                    <Td isNumeric>{week.aldiSales.toFixed(2)}</Td>
                                    <Td isNumeric>{week.igaSales.toFixed(2)}</Td>
                                    <Td isNumeric>{week.pledgeSales.toFixed(4)}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>

            <Box bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md" mt={8}>
                <Heading as="h3" size="lg" mb={4}>Projected Weekly Sales Based on 2024 Data</Heading>
                <Text mb={4}>
                    This table projects weekly sales based on the average weekly sales from the 2024 financial year.
                    <InfoTooltip content="Data is based on the 2024 Woolworths Group Annual Report. For more information, visit: https://www.woolworthsgroup.com.au/content/dam/wwg/investors/reports/f24/f24/Woolworths%20Group%202024%20Annual%20Report.pdf" />
                </Text>
                <TableContainer>
                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr>
                                <Th>Financial Week</Th>
                                <Th>Date Range</Th>
                                <Th isNumeric>Woolworths (Millions AUD)</Th>
                                <Th isNumeric>Coles (Millions AUD)</Th>
                                <Th isNumeric>Aldi (Millions AUD)</Th>
                                <Th isNumeric>IGA (Millions AUD)</Th>
                                <Th isNumeric>Pledge Sales (Millions AUD)</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {weeklyComparisonData2025.map((week, index) => (
                                <Tr key={index}>
                                    <Td>{week.financialWeek}</Td>
                                    <Td>{week.dateRange}</Td>
                                    <Td isNumeric>{week.woolworthsSales.toFixed(2)}</Td>
                                    <Td isNumeric>{week.colesSales.toFixed(2)}</Td>
                                    <Td isNumeric>{week.aldiSales.toFixed(2)}</Td>
                                    <Td isNumeric>{week.igaSales.toFixed(2)}</Td>
                                    <Td isNumeric>{week.pledgeSales.toFixed(4)}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>



            <Box bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
                <Heading as="h3" size="lg" mb={4}>Monthly Sales Comparison</Heading>
                <Text mb={4}>
                    This chart compares our monthly pledge sales to total supermarket sales.
                </Text>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={monthlyComparisonData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <RechartsTooltip />
                        <Legend />
                        <Line yAxisId="left" type="monotone" dataKey="pledgeSales" stroke="#82ca9d" name="Pledge Sales" />
                        <Line yAxisId="right" type="monotone" dataKey="supermarketSales" stroke="#8884d8" name="Supermarket Sales" />
                    </LineChart>
                </ResponsiveContainer>
            </Box>




            <FooterWithFourColumns />
        </Box>
    );
};

export default FarmSalesDashboard;
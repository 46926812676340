// src/pages/NodeInfo.tsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery, gql, useMutation } from '@apollo/client';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    Spinner,
    Table,
    Tbody,
    Tr,
    Th,
    Td,
    useColorModeValue,
    Tooltip,
    Flex,
    IconButton,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    Icon,
    Badge,
    FormControl,
    FormLabel,
    Input,
    Thead,
    Button,
    useToast,
    Select
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { FaBitcoin, FaBolt } from 'react-icons/fa';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';


const GET_NODE_INFO = gql`
  query GetNodeInfo {
    getNodeInfo {
      version
      commit_hash
      identity_pubkey
      alias
      color
      num_pending_channels
      num_active_channels
      num_inactive_channels
      num_peers
      block_height
      block_hash
      best_header_timestamp
      synced_to_chain
      synced_to_graph
      testnet
      chains {
        chain
        network
      }
      uris
      onchain_balance
      channel_balance
      deposit_address
    }
    getWalletBalance {
      total_balance
      confirmed_balance
      unconfirmed_balance
    }
    getChannelsBalance {
      balance
      pending_open_balance
    }
    getChannels {
      channelPoint
      remotePubkey
      capacity
      localBalance
      remoteBalance
      active
    }
  }
`;


const IMPORT_LND_ACCOUNT = gql`
  mutation ImportLndAccount($name: String!, $extended_public_key: String!, $master_key_fingerprint: String!, $address_type: String!) {
    importAccount(input: {
      name: $name,
      extended_public_key: $extended_public_key,
      master_key_fingerprint: $master_key_fingerprint,
      address_type: $address_type,
      dry_run: false
    }) {
      name
      address_type
      extended_public_key
      master_key_fingerprint
      derivation_path
      external_key_count
      internal_key_count
      watch_only
    }
  }
`;

const LIST_LND_ACCOUNTS = gql`
  query ListLndAccounts {
    listAccounts {
      name
      address_type
      extended_public_key
      master_key_fingerprint
      derivation_path
      external_key_count
      internal_key_count
      watch_only
    }
  }
`;



const NodeInfoRow: React.FC<{ label: string; value: any; description: string; isLongText?: boolean }> = ({ label, value, description, isLongText }) => (
    <Tr>
        <Tooltip label={description} placement="top-start" hasArrow>
            <Th width="30%">{label}</Th>
        </Tooltip>
        <Td>
            {isLongText ? (
                <Flex alignItems="center">
                    <Text isTruncated maxWidth="300px">{value}</Text>
                    <Tooltip label="Copy full text">
                        <IconButton
                            aria-label="Copy full text"
                            icon={<CopyIcon />}
                            size="sm"
                            ml={2}
                            onClick={() => navigator.clipboard.writeText(value)}
                        />
                    </Tooltip>
                </Flex>
            ) : (
                value
            )}
        </Td>
    </Tr>
);

const ChannelDisplay: React.FC<{ channel: any }> = ({ channel }) => (
    <Box
        borderWidth={1}
        borderRadius="lg"
        p={4}
        m={2}
        boxShadow="md"
        bg={useColorModeValue('white', 'gray.700')}
    >
        <Text fontWeight="bold">Channel Point: {channel.channelPoint}</Text>
        <Text>Remote Pubkey: {channel.remotePubkey}</Text>
        <Text>Capacity: {channel.capacity} sats</Text>
        <Text>Local Balance: {channel.localBalance} sats</Text>
        <Text>Remote Balance: {channel.remoteBalance} sats</Text>
        <Badge colorScheme={channel.active ? "green" : "red"}>
            {channel.active ? "Active" : "Inactive"}
        </Badge>
    </Box>
);

const NodeInfo: React.FC = () => {
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const pageBgColor = useColorModeValue('gray.50', 'gray.900');
    const statBgColor = useColorModeValue('white', 'gray.700');
    const toast = useToast();

    const [btcPrice, setBtcPrice] = useState<number | null>(null);

    const { loading, error, data } = useQuery(GET_NODE_INFO);


    const [accountName, setAccountName] = useState('');
    const [extendedPublicKey, setExtendedPublicKey] = useState('');
    const [masterKeyFingerprint, setMasterKeyFingerprint] = useState('');
    const [addressType, setAddressType] = useState('WITNESS_PUBKEY_HASH');

    const [importAccount, { loading: importLoading }] = useMutation(IMPORT_LND_ACCOUNT);
    const { data: accountsData, loading: accountsLoading, refetch: refetchAccounts } = useQuery(LIST_LND_ACCOUNTS);

    const handleImportAccount = async () => {
        try {
            await importAccount({
                variables: {
                    name: accountName,
                    extended_public_key: extendedPublicKey,
                    master_key_fingerprint: masterKeyFingerprint,
                    address_type: addressType,
                },
            });
            setAccountName('');
            setExtendedPublicKey('');
            setMasterKeyFingerprint('');
            setAddressType('WITNESS_PUBKEY_HASH');
            refetchAccounts();
            toast({
                title: "Account imported",
                description: `LND account "${accountName}" has been imported successfully.`,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error importing account:', error);
            toast({
                title: "Error",
                description: "Failed to import LND account. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        const fetchBtcPrice = async () => {
            try {
                const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=aud');
                setBtcPrice(response.data.bitcoin.aud);
            } catch (error) {
                console.error('Error fetching BTC price:', error);
            }
        };

        fetchBtcPrice();
        const interval = setInterval(fetchBtcPrice, 60000); // Update price every minute

        return () => clearInterval(interval);
    }, []);

    const formatSatsToAUD = (sats: number) => {
        if (btcPrice === null) return 'N/A';
        const btc = sats / 100000000;
        const aud = btc * btcPrice;
        return `$${aud.toFixed(2)} AUD`;
    };

    const BalanceDisplay: React.FC<{ label: string; satsValue: number; icon: React.ReactElement }> = ({ label, satsValue, icon }) => (
        <Stat
            px={{ base: 2, md: 4 }}
            py={'5'}
            shadow={'xl'}
            border={'1px solid'}
            borderColor={useColorModeValue('gray.800', 'gray.500')}
            rounded={'lg'}
            m={{ base: 5, sm: 4 }}
        >
            <Flex justifyContent={'space-between'}>
                <Box pl={{ base: 2, md: 4 }}>
                    <StatLabel fontWeight={'medium'} isTruncated>
                        {label}
                    </StatLabel>
                    <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
                        {satsValue} sats
                    </StatNumber>
                    <StatHelpText>
                        <StatArrow type="increase" />
                        {(satsValue / 100000000).toFixed(8)} BTC
                    </StatHelpText>
                    <Badge colorScheme="green" mt={2}>
                        {formatSatsToAUD(satsValue)}
                    </Badge>
                </Box>
                <Box
                    my={'auto'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    alignContent={'center'}
                >
                    {icon}
                </Box>
            </Flex>
        </Stat>
    );

    return (
        <Box bg={pageBgColor}>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={10}>
                <VStack spacing={10} align="stretch">
                    <Box
                        bg={bgColor}
                        boxShadow="xl"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h1" size="xl" mb={6}>Node Information</Heading>

                        {loading && <Spinner />}
                        {error && <Text color="red.500">Error loading node info: {error.message}</Text>}
                        {data && (
                            <>
                                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                                    <Box>
                                        <Heading size="lg" mb={4} color="yellow.400">
                                            Lightning Channels Balance
                                        </Heading>
                                        <BalanceDisplay
                                            label="Available"
                                            satsValue={data.getChannelsBalance.balance}
                                            icon={<Icon as={FaBolt} w={10} h={10} />}
                                        />
                                        <BalanceDisplay
                                            label="Pending Open"
                                            satsValue={data.getChannelsBalance.pending_open_balance}
                                            icon={<Icon as={FaBolt} w={10} h={10} />}
                                        />
                                    </Box>
                                    <Box>
                                        <Heading size="lg" mb={4} color="orange.400">
                                            On-chain Wallet Balance
                                        </Heading>
                                        <BalanceDisplay
                                            label="Total Balance"
                                            satsValue={data.getWalletBalance.total_balance}
                                            icon={<Icon as={FaBitcoin} w={10} h={10} />}
                                        />
                                        <BalanceDisplay
                                            label="Confirmed"
                                            satsValue={data.getWalletBalance.confirmed_balance}
                                            icon={<Icon as={FaBitcoin} w={10} h={10} />}
                                        />
                                        <BalanceDisplay
                                            label="Unconfirmed"
                                            satsValue={data.getWalletBalance.unconfirmed_balance}
                                            icon={<Icon as={FaBitcoin} w={10} h={10} />}
                                        />
                                    </Box>
                                </SimpleGrid>
                            </>

                        )}
                        {data && (
                            <Box overflowX="auto">
                                <Table variant="simple">
                                    <Tbody>
                                        <NodeInfoRow
                                            label="Version"
                                            value={data.getNodeInfo.version}
                                            description="The version of the LND software running on this node."
                                        />
                                        <NodeInfoRow
                                            label="Commit Hash"
                                            value={data.getNodeInfo.commit_hash}
                                            description="The Git commit hash of the LND version."
                                            isLongText
                                        />
                                        <NodeInfoRow
                                            label="Identity Pubkey"
                                            value={data.getNodeInfo.identity_pubkey}
                                            description="The public key used to identify this node on the Lightning Network."
                                            isLongText
                                        />
                                        <NodeInfoRow
                                            label="Alias"
                                            value={data.getNodeInfo.alias}
                                            description="A human-readable name for this node on the network."
                                        />
                                        <NodeInfoRow
                                            label="Color"
                                            value={data.getNodeInfo.color}
                                            description="The color associated with this node in network visualizations."
                                        />
                                        <NodeInfoRow
                                            label="Pending Channels"
                                            value={data.getNodeInfo.num_pending_channels}
                                            description="The number of channels that are in the process of being opened or closed."
                                        />
                                        <NodeInfoRow
                                            label="Active Channels"
                                            value={data.getNodeInfo.num_active_channels}
                                            description="The number of open and operational channels."
                                        />
                                        <NodeInfoRow
                                            label="Inactive Channels"
                                            value={data.getNodeInfo.num_inactive_channels}
                                            description="The number of channels that are open but not currently active."
                                        />
                                        <NodeInfoRow
                                            label="Peers"
                                            value={data.getNodeInfo.num_peers}
                                            description="The number of other nodes this node is directly connected to."
                                        />
                                        <NodeInfoRow
                                            label="Block Height"
                                            value={data.getNodeInfo.block_height}
                                            description="The current block height of the Bitcoin blockchain as seen by this node."
                                        />
                                        <NodeInfoRow
                                            label="Block Hash"
                                            value={data.getNodeInfo.block_hash}
                                            description="The hash of the most recent block seen by this node."
                                            isLongText
                                        />
                                        <NodeInfoRow
                                            label="Best Header Timestamp"
                                            value={new Date(parseInt(data.getNodeInfo.best_header_timestamp) * 1000).toLocaleString()}
                                            description="The timestamp of the most recent block header seen by this node."
                                        />
                                        <NodeInfoRow
                                            label="Synced to Chain"
                                            value={data.getNodeInfo.synced_to_chain ? 'Yes' : 'No'}
                                            description="Indicates whether this node is fully synced with the Bitcoin blockchain."
                                        />
                                        <NodeInfoRow
                                            label="Synced to Graph"
                                            value={data.getNodeInfo.synced_to_graph ? 'Yes' : 'No'}
                                            description="Indicates whether this node is fully synced with the Lightning Network graph."
                                        />
                                        <NodeInfoRow
                                            label="Testnet"
                                            value={data.getNodeInfo.testnet ? 'Yes' : 'No'}
                                            description="Indicates whether this node is operating on the Bitcoin testnet or mainnet."
                                        />
                                        <NodeInfoRow
                                            label="On-chain Balance"
                                            value={`${data.getNodeInfo.onchain_balance} sats`}
                                            description="The total balance of Bitcoin in the on-chain wallet."
                                        />
                                        <NodeInfoRow
                                            label="Channel Balance"
                                            value={`${data.getNodeInfo.channel_balance} sats`}
                                            description="The total balance across all Lightning channels."
                                        />
                                        <NodeInfoRow
                                            label="Deposit Address"
                                            value={data.getNodeInfo.deposit_address}
                                            description="The Bitcoin address to deposit funds into this node."
                                            isLongText
                                        />
                                        <Tr>
                                            <Tooltip label="The blockchain networks this node is connected to." placement="top-start" hasArrow>
                                                <Th width="30%">Chains</Th>
                                            </Tooltip>
                                            <Td>
                                                {data.getNodeInfo.chains.map((chain: any, index: number) => (
                                                    <Text key={index}>{chain.chain} - {chain.network}</Text>
                                                ))}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Tooltip label="The network addresses where this node can be reached." placement="top-start" hasArrow>
                                                <Th width="30%">URIs</Th>
                                            </Tooltip>
                                            <Td>
                                                {data.getNodeInfo.uris.map((uri: string, index: number) => (
                                                    <Flex key={index} alignItems="center" mb={1}>
                                                        <Text isTruncated maxWidth="300px">{uri}</Text>
                                                        <Tooltip label="Copy URI">
                                                            <IconButton
                                                                aria-label="Copy URI"
                                                                icon={<CopyIcon />}
                                                                size="sm"
                                                                ml={2}
                                                                onClick={() => navigator.clipboard.writeText(uri)}
                                                            />
                                                        </Tooltip>
                                                    </Flex>
                                                ))}
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Box>
                        )}
                    </Box>


                    {data && data.getChannels && data.getChannels.length > 0 && (
                        <Box
                            bg={bgColor}
                            boxShadow="xl"
                            borderRadius="lg"
                            p={6}
                            borderWidth={1}
                            borderColor={borderColor}
                        >
                            <Heading as="h2" size="lg" mb={4}>
                                Open Channels
                            </Heading>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                                {data.getChannels.map((channel: any, index: number) => (
                                    <ChannelDisplay key={index} channel={channel} />
                                ))}
                            </SimpleGrid>
                        </Box>
                    )}


                    <Box
                        bg={bgColor}
                        boxShadow="xl"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h2" size="lg" mb={4}>
                            Import LND Account
                        </Heading>
                        <FormControl>
                            <FormLabel>Account Name</FormLabel>
                            <Input
                                value={accountName}
                                onChange={(e) => setAccountName(e.target.value)}
                                placeholder="Enter account name"
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Extended Public Key</FormLabel>
                            <Input
                                value={extendedPublicKey}
                                onChange={(e) => setExtendedPublicKey(e.target.value)}
                                placeholder="Enter extended public key"
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Master Key Fingerprint</FormLabel>
                            <Input
                                value={masterKeyFingerprint}
                                onChange={(e) => setMasterKeyFingerprint(e.target.value)}
                                placeholder="Enter master key fingerprint"
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Address Type</FormLabel>
                            <Select value={addressType} onChange={(e) => setAddressType(e.target.value)}>
                                <option value="WITNESS_PUBKEY_HASH">WITNESS_PUBKEY_HASH</option>
                                <option value="NESTED_WITNESS_PUBKEY_HASH">NESTED_WITNESS_PUBKEY_HASH</option>
                                <option value="HYBRID_NESTED_WITNESS_PUBKEY_HASH">HYBRID_NESTED_WITNESS_PUBKEY_HASH</option>
                                <option value="TAPROOT_PUBKEY">TAPROOT_PUBKEY</option>
                            </Select>
                        </FormControl>
                        <Button
                            mt={4}
                            colorScheme="blue"
                            onClick={handleImportAccount}
                            isLoading={importLoading}
                        >
                            Import Account
                        </Button>

                        <Heading as="h3" size="md" mt={8} mb={4}>
                            LND Accounts
                        </Heading>
                        {accountsLoading ? (
                            <Spinner />
                        ) : accountsData && accountsData.listAccounts && accountsData.listAccounts.length > 0 ? (
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Account Name</Th>
                                        <Th>Address Type</Th>
                                        <Th>External Key Count</Th>
                                        <Th>Internal Key Count</Th>
                                        <Th>Watch Only</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {accountsData.listAccounts.map((account: any) => (
                                        <Tr key={account.name}>
                                            <Td>{account.name}</Td>
                                            <Td>{account.address_type}</Td>
                                            <Td>{account.external_key_count}</Td>
                                            <Td>{account.internal_key_count}</Td>
                                            <Td>{account.watch_only ? 'Yes' : 'No'}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        ) : (
                            <Text>No LND accounts found.</Text>
                        )}
                    </Box>




                    {/* <Box
                        bg={bgColor}
                        boxShadow="xl"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h2" size="lg" mb={4}>
                            LND Account Management
                        </Heading>
                        <FormControl>
                            <FormLabel>Account Name</FormLabel>
                            <Input
                                value={accountName}
                                onChange={(e) => setAccountName(e.target.value)}
                                placeholder="Enter account name"
                            />
                        </FormControl>
                        <Button
                            mt={4}
                            colorScheme="blue"
                            onClick={handleCreateAccount}
                            isLoading={accountsLoading}
                        >
                            Create Account
                        </Button>

                        <Heading as="h3" size="md" mt={8} mb={4}>
                            LND Accounts
                        </Heading>
                        {accountsLoading ? (
                            <Spinner />
                        ) : accountsData && accountsData.listLndAccounts.length > 0 ? (
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Account Name</Th>
                                        <Th>Balance</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {accountsData.listLndAccounts.map((account: { name: string; balance: string }) => (
                                        <Tr key={account.name}>
                                            <Td>{account.name}</Td>
                                            <Td>{account.balance} sats</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        ) : (
                            <Text>No LND accounts found.</Text>
                        )}
                    </Box> */}
                </VStack>
            </Container>
            <FooterWithFourColumns />
        </Box>
    );
};

export default NodeInfo;

function toast(arg0: { title: string; description: string; status: string; duration: number; isClosable: boolean; }) {
    throw new Error('Function not implemented.');
}

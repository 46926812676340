import React, { useEffect } from 'react';
import {
    Box, Container, Heading, Text, VStack, Button, Image,
    useColorModeValue, SimpleGrid, Link
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const OurProducers = () => {
    const bgColor = useColorModeValue('green.50', 'green.900');
    const cardBgColor = useColorModeValue('white', 'green.800');
    const textColor = useColorModeValue('gray.800', 'white');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const producers = [
        {
            name: "Forage Farms",
            description: "Family-owned regenerative farm dedicated to sustainable food production and ecosystem restoration.",
            image: "/producer_images/forage_frm.jpeg",
            link: "/forage-farms"
        },
        // {
        //     name: "Sunshine Valley Organics",
        //     description: "Certified organic farm specializing in heirloom vegetables and fruits.",
        //     image: "/producer_images/sunshine_valley.jpg",
        //     link: "/producers/sunshine-valley"
        // },
        // {
        //     name: "Green Pastures Dairy",
        //     description: "Grass-fed dairy farm producing artisanal cheeses and yogurts.",
        //     image: "/producer_images/green_pastures.jpg",
        //     link: "/producers/green-pastures"
        // },
        // {
        //     name: "Mountain View Orchards",
        //     description: "Family-run orchard offering a wide variety of apples, pears, and stone fruits.",
        //     image: "/producer_images/mountain_view.jpg",
        //     link: "/producers/mountain-view"
        // },
        // {
        //     name: "Riverside Honey Farm",
        //     description: "Sustainable apiary producing raw, unfiltered honey and beeswax products.",
        //     image: "/producer_images/riverside_honey.jpg",
        //     link: "/producers/riverside-honey"
        // },
        // {
        //     name: "Meadowbrook Free Range",
        //     description: "Ethical poultry farm raising free-range chickens and producing organic eggs.",
        //     image: "/producer_images/meadowbrook.jpg",
        //     link: "/producers/meadowbrook"
        // }
    ];

    return (
        <Box bg={bgColor} minHeight="100vh">
            <NavbarWithCallToAction />

            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    {/* Hero Section */}
                    <Box textAlign="center">
                        <Heading as="h1" size="2xl" mb={4}>
                            Our Producers
                        </Heading>
                        <Text fontSize="xl" mb={6}>
                            Discover the passionate farmers and artisans behind our quality products
                        </Text>
                    </Box>

                    {/* Producers Grid */}
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
                        {producers.map((producer, index) => (
                            <Box key={index} bg={cardBgColor} borderRadius="lg" overflow="hidden" boxShadow="md">
                                <Image
                                    src={producer.image}
                                    alt={producer.name}
                                    objectFit="cover"
                                    width="100%"
                                    height="200px"
                                />
                                <Box p={6}>
                                    <Heading as="h3" size="lg" mb={2}>
                                        {producer.name}
                                    </Heading>
                                    <Text mb={4}>{producer.description}</Text>
                                    <Button as={Link} href={producer.link} colorScheme="green">
                                        Learn More
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                    </SimpleGrid>

                    {/* Call to Action */}
                    <Box textAlign="center" mt={12}>
                        <Heading as="h2" size="xl" mb={4}>
                            Join Our Producer Network
                        </Heading>
                        <Text fontSize="lg" mb={6}>
                            Are you a local farmer or artisan producer? We'd love to hear from you!
                        </Text>
                        <Button colorScheme="green" size="lg">
                            Apply to Become a Producer
                        </Button>
                    </Box>
                </VStack>
            </Container>

            <FooterWithFourColumns />
        </Box>
    );
};

export default OurProducers;
// src/pages/OurLocationHubs.tsx

import React, { useState } from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    SimpleGrid,
    Progress,
    Badge,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useColorModeValue,
    Modal,
    Button,
    FormControl,
    FormLabel,
    Input,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Textarea,
    Tooltip,
    useDisclosure,
    Link
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { InfoIcon } from '@chakra-ui/icons';

interface Location {
    name: string;
    status: 'Awaiting Manager' | 'Getting Started' | 'First Round of Purchases' | 'Flourishing';
}

interface City {
    name: string;
    locations: Location[];
}

interface State {
    name: string;
    cities: City[];
}

const locationData: State[] = [
    {
        name: 'New South Wales',
        cities: [
            {
                name: 'Sydney',
                locations: [
                    { name: 'Sydney CBD', status: 'Awaiting Manager' },
                    { name: 'Parramatta', status: 'Awaiting Manager' },
                    { name: 'Blacktown', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Newcastle',
                locations: [
                    { name: 'Newcastle', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Central Coast',
                locations: [
                    { name: 'Gosford', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Wollongong',
                locations: [
                    { name: 'Wollongong', status: 'Awaiting Manager' },
                ],
            },
        ],
    },
    {
        name: 'Victoria',
        cities: [
            {
                name: 'Melbourne',
                locations: [
                    { name: 'Melbourne CBD', status: 'Awaiting Manager' },
                    { name: 'Geelong', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Ballarat',
                locations: [
                    { name: 'Ballarat', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Bendigo',
                locations: [
                    { name: 'Bendigo', status: 'Awaiting Manager' },
                ],
            },
        ],
    },
    {
        name: 'Queensland',
        cities: [
            {
                name: 'Brisbane',
                locations: [
                    { name: 'South Brisbane', status: 'Getting Started' },
                    { name: 'North Brisbane', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Gold Coast',
                locations: [
                    { name: 'Surfers Paradise', status: 'Awaiting Manager' },
                    { name: 'Broadbeach', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Sunshine Coast',
                locations: [
                    { name: 'Maroochydore', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Townsville',
                locations: [
                    { name: 'Townsville', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Cairns',
                locations: [
                    { name: 'Cairns', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Toowoomba',
                locations: [
                    { name: 'Toowoomba', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Mackay',
                locations: [
                    { name: 'Mackay', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Rockhampton',
                locations: [
                    { name: 'Rockhampton', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Bundaberg',
                locations: [
                    { name: 'Bundaberg', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Noosa',
                locations: [
                    { name: 'Noosa Heads', status: 'Getting Started' },
                ],
            },
        ],
    },
    {
        name: 'Western Australia',
        cities: [
            {
                name: 'Perth',
                locations: [
                    { name: 'Perth CBD', status: 'Awaiting Manager' },
                    { name: 'Fremantle', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Mandurah',
                locations: [
                    { name: 'Mandurah', status: 'Awaiting Manager' },
                ],
            },
        ],
    },
    {
        name: 'South Australia',
        cities: [
            {
                name: 'Adelaide',
                locations: [
                    { name: 'Adelaide CBD', status: 'Awaiting Manager' },
                ],
            },
        ],
    },
    {
        name: 'Tasmania',
        cities: [
            {
                name: 'Hobart',
                locations: [
                    { name: 'Hobart', status: 'Awaiting Manager' },
                ],
            },
            {
                name: 'Launceston',
                locations: [
                    { name: 'Launceston', status: 'Awaiting Manager' },
                ],
            },
        ],
    },
    {
        name: 'Australian Capital Territory',
        cities: [
            {
                name: 'Canberra',
                locations: [
                    { name: 'Canberra', status: 'Awaiting Manager' },
                ],
            },
        ],
    },
    {
        name: 'Northern Territory',
        cities: [
            {
                name: 'Darwin',
                locations: [
                    { name: 'Darwin', status: 'Awaiting Manager' },
                ],
            },
        ],
    },
];

const getStatusColor = (status: Location['status']) => {
    switch (status) {
        case 'Awaiting Manager':
            return 'red';
        case 'Getting Started':
            return 'yellow';
        case 'First Round of Purchases':
            return 'blue';
        case 'Flourishing':
            return 'green';
        default:
            return 'gray';
    }
};

const getStatusProgress = (status: Location['status']) => {
    switch (status) {
        case 'Awaiting Manager':
            return 25;
        case 'Getting Started':
            return 50;
        case 'First Round of Purchases':
            return 75;
        case 'Flourishing':
            return 100;
        default:
            return 0;
    }
};

interface ManagerApplicationModalProps {
    isOpen: boolean;
    onClose: () => void;
    location: string;
}

const ManagerTooltip = () => (
    <Tooltip label="Managers coordinate activities, enable the supply chain, manage pickup/drop-off locations, handle supplier payments, and maintain transparent accounts for the community.">
        <InfoIcon ml={2} />
    </Tooltip>
);

const ManagerApplicationModal = ({ isOpen, onClose, location }: ManagerApplicationModalProps) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [experience, setExperience] = useState('');
    const [selectedLocation, setSelectedLocation] = useState<string>('');




    const handleSubmit = () => {
        // Here you would typically send an email or make an API call
        console.log(`Application for ${location} submitted:`, { name, email, experience });
        // For now, we'll just log the data and close the modal
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{'Apply to be a Manager for ' + location}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Email</FormLabel>
                        <Input value={email} onChange={(e) => setEmail(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Relevant Experience</FormLabel>
                        <Textarea value={experience} onChange={(e) => setExperience(e.target.value)} />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                        Submit Application
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const OurLocationHubs: React.FC = () => {
    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBgColor = useColorModeValue('white', 'gray.800');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedLocation, setSelectedLocation] = useState('');


    // Move this line here
    const queenslandIndex = locationData.findIndex(state => state.name === 'Queensland');



    const handleApplyManager = (location: string) => {
        setSelectedLocation(location);
        onOpen();
    };

    const handleJoinWaitlist = (location: string) => {
        // Here you would typically add the user to a waitlist
        console.log(`User added to waitlist for ${location}`);
        // For now, we'll just log the action
        alert(`You've been added to the waitlist for ${location}!`);
    };

    return (
        <Box bg={bgColor} minHeight="100vh">
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={12}>
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="2xl" textAlign="center">
                        Our Location Hubs
                    </Heading>
                    <Text fontSize="xl" textAlign="center">
                        Explore our growing network of PledgeBuy hubs across Australia. Each location represents a step towards building resilient communities and sustainable supply chains.
                    </Text>


                    {/* New Section: Local REKO Model Adaptation */}
                    <Box bg={cardBgColor} p={6} borderRadius="md" boxShadow="md">
                        <Heading as="h2" size="lg" mb={4}>
                            Adapting the REKO Model Locally
                        </Heading>
                        <Text fontSize="md" mb={4}>
                            At PledgeBuy, we encourage each local supply chain community to adapt and enhance the REKO model to suit their unique needs and circumstances. This approach allows for:
                        </Text>
                        <SimpleGrid columns={[1, null, 2]} spacing={4} mb={6}>
                            <Box>
                                <Text fontWeight="bold">• Stronger Farmer Relationships</Text>
                                <Text>Building deep, lasting connections with local producers</Text>
                            </Box>
                            <Box>
                                <Text fontWeight="bold">• Community-Driven Innovation</Text>
                                <Text>Empowering locals to create solutions that work for them</Text>
                            </Box>
                            <Box>
                                <Text fontWeight="bold">• Cultural Sensitivity</Text>
                                <Text>Respecting and incorporating local traditions and preferences</Text>
                            </Box>
                            <Box>
                                <Text fontWeight="bold">• Flexible Distribution</Text>
                                <Text>Adapting to local infrastructure and geographical challenges</Text>
                            </Box>
                        </SimpleGrid>
                        <Text fontSize="md" mb={4}>
                            By allowing each hub to tailor the REKO model, we create a network of unique, resilient, and deeply rooted local food systems that collectively strengthen our national food security.
                        </Text>
                        <Button
                            as={Link}
                            href="/our-reko-model"
                            colorScheme="green"
                            size="lg"
                        >
                            Learn More About Our REKO Model
                        </Button>
                    </Box>






                    <Accordion defaultIndex={[queenslandIndex]} allowMultiple>
                        {locationData.map((state) => (
                            <AccordionItem key={state.name}>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            <Heading as="h2" size="lg">{state.name}</Heading>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <SimpleGrid columns={[1, null, 2]} spacing={6}>
                                        {state.cities.map((city) => (
                                            <Box key={city.name} bg={cardBgColor} p={6} borderRadius="md" boxShadow="md">
                                                <Heading as="h3" size="md" mb={4}>{city.name}</Heading>
                                                <VStack align="stretch" spacing={4}>
                                                    {city.locations.map((location) => (
                                                        <Box key={location.name}>
                                                            <Text fontWeight="bold">{location.name}</Text>
                                                            <Badge colorScheme={getStatusColor(location.status)} mb={2}>
                                                                {location.status}
                                                                {location.status === 'Awaiting Manager' && <ManagerTooltip />}
                                                            </Badge>
                                                            <Progress
                                                                value={getStatusProgress(location.status)}
                                                                colorScheme={getStatusColor(location.status)}
                                                                size="sm"
                                                                borderRadius="full"
                                                                mb={2}
                                                            />
                                                            <Button m={1} size="sm" colorScheme="blue" onClick={() => handleApplyManager(location.name)} mr={2}>
                                                                Apply as Manager
                                                            </Button>
                                                            <Button m={1} size="sm" colorScheme="green" onClick={() => handleJoinWaitlist(location.name)}>
                                                                Join Waitlist
                                                            </Button>
                                                        </Box>
                                                    ))}
                                                </VStack>
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </VStack>
            </Container>
            <ManagerApplicationModal isOpen={isOpen} onClose={onClose} location={selectedLocation} />
            <FooterWithFourColumns />
        </Box>
    );
};

export default OurLocationHubs;
// src/pages/Marketplace.tsx
import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    Image,
    Button,
    VStack,
    HStack,
    Tag,
    useToast,
    Input,
    InputGroup,
    InputLeftElement,
    Flex,
    Link,
    Divider,
    useColorModeValue,
    Icon,
} from '@chakra-ui/react';
import { SearchIcon, StarIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';
import { getApiUrl } from '../utils/helpers';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useInviteCode } from '../hooks/useInviteCode';
import BackendProducts from '../components/BackendProducts';
import { FaLeaf, FaHeart, FaLightbulb } from 'react-icons/fa';

// Updated product type
interface Product {
    id: number;
    name: string;
    price: number;
    category: string;
    image: string;
    url: string;
}

// Updated product list with the relationships course and URLs
const dummyProducts: Product[] = [
    { id: 9, name: 'Organic Whole Chicken', price: 33, category: 'Food', image: '/product_images/chicken.jpg', url: '/organic-chicken-group-buy' },
    { id: 8, name: 'Ethically Sourced Wild Kangaroo Meat', price: 33, category: 'Food', image: '/product_images/kangaroo.jpg', url: '/kangaroo-meat-group-buy' },
    { id: 7, name: 'Small Fruit & Veg Box', price: 33, category: 'Food', image: '/product_images/smallfruitandveg.png', url: '/small-fruit-and-veg-box' },
    // { id: 1, name: 'Eco-Friendly Water Bottle', price: 2500, category: 'Sustainability', image: '/product_images/waterbottle.png', url: '/product/1' },
    // { id: 2, name: 'Organic Cotton T-Shirt', price: 3000, category: 'Fashion', image: '/product_images/cotton_shirt.png', url: '/product/2' },
    { id: 3, name: 'Group Buy: Half a Beef Split 4 Ways', price: 1400, category: 'Food', image: '/product_images/12BeefBox.png', url: '/products/group-half-beef-buy' },
    { id: 4, name: 'Simulated Group Buy 55.74 acres in Canungra', price: 34000000, category: 'Land', image: '/product_images/canungra.png', url: '/LandPurchaseCanungra' },
    // { id: 4, name: 'Simulated Group Buy 55.74 acres in Canungra', price: 29929, category: 'Land', image: '/product_images/recycled_plastic_backpack.png', url: '/LandPurchaseCanungra' },
    { id: 5, name: 'Mystery Mastery Retreat', price: 34000000, category: 'Self Mastery', image: '/product_images/mystery-mastery.jpg', url: '/mysterymasterycourse' },

    { id: 6, name: '1080 Resilience and Self-Governance', price: 33, category: 'Onlinge Course', image: '/product_images/1080.png', url: '/resilience' },
   
   
    // { id: 9, name: 'Organic Whole Chicken', price: 33, category: 'Food', image: '/product_images/chicken.jpg', url: '/organic-chicken-group-buy' },
    // {
    //     id: 65,
    //     name: 'Transform Your Relationship Course',
    //     price: 690000, // 690 AUD in sats
    //     category: 'Relationships',
    //     image: '/product_images/relationship_course.png', // You'll need to add this image
    //     url: '/relationships'
    // },



];

export const Marketplace = () => {
    const [products, setProducts] = useState(dummyProducts);
    const [searchTerm, setSearchTerm] = useState('');
    const { user } = useAuth();
    const toast = useToast();
    const apiUrl = getApiUrl();
    useInviteCode(); // Add this line to handle invite codes
    const location = useLocation();
    const [inviteCode, setInviteCode] = useState('');
    const navigate = useNavigate();

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.600', 'gray.200');
    const headingColor = useColorModeValue('green.600', 'green.300');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('inviteCode');
        if (code) {
            setInviteCode(code);
            navigate(location.pathname, { replace: true });
        }
    }, [location, navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        // Filter products based on search term
        const filteredProducts = dummyProducts.filter(product =>
            product.name.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setProducts(filteredProducts);
    };

    const handleProductClick = (product: Product) => {
        // Navigate to the product URL
        navigate(product.url);
    };

    return (
        <Box bg={bgColor} minHeight="100vh">
            <NavbarWithCallToAction />

            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    <Box textAlign="center">
                        <Heading as="h1" size="2xl" mb={4} color={headingColor}>
                            PledgeBuy Marketplace
                        </Heading>
                        <Text fontSize="xl" mb={6} color={textColor}>
                            Discover sustainable products, connect with your community, and earn rewards through Lightning Network.
                        </Text>
                    </Box>

                    <InputGroup size="lg" maxW="600px" mx="auto">
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search for products..."
                            value={searchTerm}
                            onChange={handleSearch}
                            borderRadius="full"
                            bg={cardBgColor}
                        />
                    </InputGroup>

                    <Box>
                        <Heading as="h2" size="xl" mb={6} color={headingColor}>
                            Featured Products
                        </Heading>
                        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
                            {products.map(product => (
                                <Box
                                    key={product.id}
                                    borderWidth={1}
                                    borderRadius="lg"
                                    overflow="hidden"
                                    bg={cardBgColor}
                                    transition="all 0.3s"
                                    _hover={{ transform: 'translateY(-5px)', boxShadow: 'xl' }}
                                >
                                    <Image src={product.image} alt={product.name} height="200px" width="100%" objectFit="cover" />
                                    <Box p={6}>
                                        <HStack spacing={2} mb={2}>
                                            <Tag borderRadius="full" px={3} colorScheme="green">
                                                {product.category}
                                            </Tag>
                                            {product.price < 50000 && <Icon as={StarIcon} color="yellow.400" />}
                                        </HStack>
                                        <Heading as="h3" size="md" mb={2}>
                                            {product.name}
                                        </Heading>
                                        <HStack spacing={2} mb={4}>
                                            {product.category === 'Sustainability' && <Icon as={FaLeaf} color="green.500" />}
                                            {product.category === 'Relationships' && <Icon as={FaHeart} color="red.500" />}
                                            {product.category === 'Self Mastery' && <Icon as={FaLightbulb} color="yellow.500" />}
                                            {/* <Text color={textColor}>{product.price.toLocaleString()} sats</Text> */}
                                        </HStack>
                                        <Button
                                            colorScheme="green"
                                            width="100%"
                                            onClick={() => handleProductClick(product)}
                                        >
                                            Learn More
                                        </Button>
                                    </Box>
                                </Box>
                            ))}
                        </SimpleGrid>


                    </Box>
                </VStack>
            </Container>

            <FooterWithFourColumns />
        </Box>
    );
};

export default Marketplace;



// src/pages/Earn.tsx
import React, { useEffect, useState } from 'react';
import {
    Box,

    Heading,
    Text,
    Button,
    Input,
    useToast,
    Flex,

    SimpleGrid,
    Container,
    Image,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    VStack,
} from '@chakra-ui/react';
import { useAuth } from '../contexts/AuthContext';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { getApiUrl } from '../utils/helpers';
// import { generateAffiliateLink } from '../services/userService';
import { useNavigate } from 'react-router-dom';

import { useInviteCode } from '../hooks/useInviteCode';


export const Earn = () => {
    const { user } = useAuth();
    const [affiliateLink, setAffiliateLink] = useState('');
    const toast = useToast();
    const apiUrl = getApiUrl();
    const navigate = useNavigate();

    useInviteCode();


    useEffect(() => {
        if (!user) {
            localStorage.setItem('startingProductURL', '/earn');
        }
    }, [user]);

    const handleGenerateLink = async () => {
        if (!user || !user.email) {
            toast({
                title: 'Error',
                description: 'You must be logged in to generate an affiliate link.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            // const link = await generateAffiliateLink(apiUrl, user.email);
            // setAffiliateLink(link);
            toast({
                title: 'Success',
                description: 'Affiliate link generated successfully.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to generate affiliate link.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(affiliateLink);
        toast({
            title: 'Copied',
            description: 'Affiliate link copied to clipboard.',
            status: 'success',
            duration: 2000,
            isClosable: true,
        });
    };


    const handleExploreMarketplaceClick = () => {
        if (user) {
            navigate('/marketplace');
        } else {
            navigate('/login');
        }
    };


    const handleContinue = () => {
        if (!user) {
            navigate('/login');
        }
    };


    return (
        <Flex direction="column" minHeight="100vh">
            <NavbarWithCallToAction />

            {user ? (
                <>

                    <Container maxW="container.xl" p={10}>
                        <VStack spacing={8} align="stretch">
                            <Box textAlign="center">
                                <Heading as="h1" size="2xl" mb={4}>
                                    Reclaim Value for Our Community with Lightning-Fast Microtransactions
                                </Heading>
                                <Text fontSize="xl" mb={6}>
                                    Earn what big tech would take. Share products, not ads. Powered by private microtransactions on the Bitcoin Lightning Network.
                                </Text>
                            </Box>

                            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                                <Box>
                                    <Heading as="h2" size="xl" mb={4}>
                                        Our Lightning-Powered Philosophy
                                    </Heading>
                                    <Text mb={4}>
                                        At PledgeBuy, we're revolutionizing product promotion using the Bitcoin Lightning Network. Instead of giving marketing budgets to tech giants, we redirect that value back to you through instant, low-cost microtransactions.
                                    </Text>
                                    <Text mb={4}>
                                        Every product in our marketplace represents an opportunity to shift money from big tech into the pockets of real people, all facilitated by the speed and privacy of Lightning Network payments.
                                    </Text>
                                    <Button colorScheme="blue" size="lg">
                                        Start Earning with Lightning Now
                                    </Button>
                                </Box>
                                <Box>
                                    <Image src="/community_earning.png" alt="Community earning with Lightning Network" rounded="md" />
                                </Box>
                            </SimpleGrid>

                            <Box>
                                <Heading as="h2" size="xl" mb={6}>
                                    How It Works with Lightning
                                </Heading>
                                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                                    <VStack align="start">
                                        <Heading as="h3" size="md">1. Choose a Product</Heading>
                                        <Text>Browse our marketplace and select products you love to share.</Text>
                                    </VStack>
                                    <VStack align="start">
                                        <Heading as="h3" size="md">2. Generate Your Lightning Link</Heading>
                                        <Text>Create a unique sharing link or QR code tied to your Lightning wallet.</Text>
                                    </VStack>
                                    <VStack align="start">
                                        <Heading as="h3" size="md">3. Share & Earn Instantly</Heading>
                                        <Text>Share with your network and earn the "big tech cut" instantly via Lightning payments.</Text>
                                    </VStack>
                                </SimpleGrid>
                            </Box>

                            <Box bg="gray.100" p={8} rounded="md">
                                <Heading as="h2" size="xl" mb={6}>
                                    Lightning-Fast Community Impact
                                </Heading>
                                <Text mb={4} fontStyle="italic">
                                    *Sats (short for Satoshis) are the smallest unit of Bitcoin. 1 Bitcoin = 100 million sats.
                                    We use sats to make microtransactions more tangible and relatable.
                                </Text>
                                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                                    <Stat>
                                        <StatLabel>Total Reclaimed via Lightning</StatLabel>
                                        <StatNumber>1,234,567,890 sats</StatNumber>
                                        <StatHelpText>
                                            ≈ 12.34567 BTC (AUD $1,174,600.74)
                                        </StatHelpText>
                                        <StatHelpText>Value shifted from big tech to our community</StatHelpText>
                                    </Stat>
                                    <Stat>
                                        <StatLabel>Active Lightning Earners</StatLabel>
                                        <StatNumber>5,678</StatNumber>
                                        <StatHelpText>Community members earning with Lightning</StatHelpText>
                                    </Stat>
                                    <Stat>
                                        <StatLabel>Average Lightning Earnings</StatLabel>
                                        <StatNumber>217,000 sats</StatNumber>
                                        <StatHelpText>
                                            ≈ 0.00217 BTC (AUD $208.50) per active member this month
                                        </StatHelpText>
                                    </Stat>
                                </SimpleGrid>
                            </Box>

                            <Box>
                                <Heading as="h2" size="xl" mb={6}>
                                    Ready to Start with Lightning?
                                </Heading>
                                <Text mb={4}>
                                    Join our movement to reclaim value from big tech and redistribute it instantly to individuals and communities using the Bitcoin Lightning Network. Start by exploring our marketplace and sharing products you believe in.
                                </Text>
                                <Button colorScheme="green" size="lg" onClick={handleExploreMarketplaceClick}>
                                    Explore Lightning-Powered Marketplace
                                </Button>
                            </Box>
                        </VStack>
                    </Container>

                    <Container maxW="container.md" py={8} flex={1}>
                        <VStack spacing={6} align="stretch">
                            <Heading as="h1" size="xl">
                                Earn with Lightning-Fast Affiliate Program
                            </Heading>
                            <Text>
                                Generate your unique Lightning Network affiliate link and start earning instant rewards when people sign up using your link.
                            </Text>
                            <Box>
                                <Button colorScheme="blue" onClick={handleGenerateLink} mb={4}>
                                    Generate Lightning Affiliate Link
                                </Button>
                                {affiliateLink && (
                                    <Box borderWidth={1} borderRadius="md" p={4}>
                                        <Text fontWeight="bold" mb={2}>Your Lightning Affiliate Link:</Text>
                                        <Flex>
                                            <Input value={affiliateLink} isReadOnly mr={2} />
                                            <Button onClick={handleCopyLink}>Copy</Button>
                                        </Flex>
                                    </Box>
                                )}
                            </Box>
                        </VStack>
                    </Container>

                </>
            ) : (
                <>
                    <Text fontSize="xl">
                        You are not logged in. Please log in to view the educational content about getting started with Bitcoin.
                    </Text>
                    <Button colorScheme="blue" onClick={handleContinue}>
                        Continue to Login
                    </Button>
                </>
            )}
            <FooterWithFourColumns />
        </Flex>
    );
};

export default Earn;
// src/pages/BTCPayInvoices.tsx

import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
    Box,
    Container,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Text,
    useColorModeValue,
    Input,
    Select,
    Button,
    VStack,
    HStack,
    Badge,
    Link,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';

const GET_ALL_BTCPAY_INVOICES = gql`
  query GetAllBTCPayInvoices {
    getAllBTCPayInvoices {
      id
      status
      checkoutLink
      amount
      currency
    }
  }
`;

const BTCPayInvoices = () => {
    const { user } = useAuth();
    const [filters, setFilters] = useState({
        orderId: '',
        status: '',
        textSearch: '',
        startDate: '',
        endDate: '',
    });
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(50);

    const { loading, error, data } = useQuery(GET_ALL_BTCPAY_INVOICES);

    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    if (!user || !user.permissions?.includes('admin')) {
        return null;
    }

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    // const handleSearch = () => {
    //     refetch({
    //         ...filters,
    //         orderId: filters.orderId ? [filters.orderId] : undefined,
    //         status: filters.status || undefined,
    //         startDate: filters.startDate ? new Date(filters.startDate).getTime() / 1000 : undefined,
    //         endDate: filters.endDate ? new Date(filters.endDate).getTime() / 1000 : undefined,
    //         skip: 0,
    //         take,
    //     });
    //     setSkip(0);
    // };
    if (!user) return (<>Pleease log in to view this page.</>)
    if (loading) return <Spinner />;
    if (error) return <Text>Error: {error.message}</Text>;

    return (
        <Box>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={10}>
                <Box
                    bg={bgColor}
                    boxShadow="xl"
                    borderRadius="lg"
                    p={6}
                    borderWidth={1}
                    borderColor={borderColor}
                >
                    <Heading as="h1" size="xl" mb={6}>
                        BTCPay Invoices
                    </Heading>
                    {/* <VStack spacing={4} align="stretch" mb={6}>
                        <HStack>
                            <Input
                                placeholder="Order ID"
                                name="orderId"
                                value={filters.orderId}
                                onChange={handleFilterChange}
                            />
                            <Select
                                placeholder="Status"
                                name="status"
                                value={filters.status}
                                onChange={handleFilterChange}
                            >
                                <option value="New">New</option>
                                <option value="Processing">Processing</option>
                                <option value="Expired">Expired</option>
                                <option value="Invalid">Invalid</option>
                                <option value="Settled">Settled</option>
                            </Select>
                        </HStack>
                        <Input
                            placeholder="Text Search"
                            name="textSearch"
                            value={filters.textSearch}
                            onChange={handleFilterChange}
                        />
                        <HStack>
                            <Input
                                type="date"
                                placeholder="Start Date"
                                name="startDate"
                                value={filters.startDate}
                                onChange={handleFilterChange}
                            />
                            <Input
                                type="date"
                                placeholder="End Date"
                                name="endDate"
                                value={filters.endDate}
                                onChange={handleFilterChange}
                            />
                        </HStack>
                        <Button onClick={handleSearch}>Search</Button>
                    </VStack> */}
                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr>
                                <Th>ID</Th>
                                <Th>Amount</Th>
                                <Th>Status</Th>
                                <Th>Created</Th>
                                <Th>Checkout Link</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.getAllBTCPayInvoices.map((invoice: any) => (
                                <Tr key={invoice.id}>
                                    <Td>{invoice.id}</Td>
                                    <Td>{`${invoice.amount} ${invoice.currency}`}</Td>
                                    <Td>
                                        <Badge colorScheme={invoice.status === 'Settled' ? 'green' : 'orange'}>
                                            {invoice.status}
                                        </Badge>
                                    </Td>
                                    <Td>{new Date(invoice.createdTime * 1000).toLocaleString()}</Td>
                                    <Td>
                                        <Link href={invoice.checkoutLink} isExternal color="blue.500">
                                            Checkout
                                        </Link>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    <HStack justifyContent="space-between" mt={4}>
                        <Button onClick={() => setSkip(Math.max(0, skip - take))} disabled={skip === 0}>
                            Previous
                        </Button>
                        <Button onClick={() => setSkip(skip + take)} disabled={data.getAllBTCPayInvoices.length < take}>
                            Next
                        </Button>
                    </HStack>
                </Box>
            </Container>
            <FooterWithFourColumns />
        </Box>
    );
};

export default BTCPayInvoices;
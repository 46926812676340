import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    Button,
    VStack,
    UnorderedList,
    ListItem,
    useToast,
    Collapse,
    OrderedList,
    Badge,
    HStack,
    AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useInviteCode } from '../hooks/useInviteCode';
import { gql, useMutation } from '@apollo/client';
import { useColorModeValue } from '@chakra-ui/react';


const CREATE_BUY_BITCOIN_PURCHASE = gql`
    mutation CreateBuyBitcoinPurchase($userEmail: String!, $amountAUD: Float!, $walletAddress: String!) {
        createBuyBitcoinPurchase(userEmail: $userEmail, amountAUD: $amountAUD, walletAddress: $walletAddress) {
            id
        }
    }
`;


const Start = () => {

    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    const notLoggedInBgColor = useColorModeValue('blue.50', 'blue.900');
    const notLoggedInHeadingColor = useColorModeValue('blue.600', 'blue.200');
    const highlightBgColor = useColorModeValue('blue.50', 'blue.900');
    const highlightBorderColor = useColorModeValue('blue.200', 'blue.700');
    const { user } = useAuth();
    const navigate = useNavigate();
    const toast = useToast();
    useInviteCode();

    const [showWalletOfSatoshiSteps, setShowWalletOfSatoshiSteps] = useState(false);
    const [showAdvancedSteps, setShowAdvancedSteps] = useState(false);

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [selectedAmount, setSelectedAmount] = useState(0);
    const cancelRef = useRef<HTMLButtonElement>(null);

    const toggleWalletOfSatoshiSteps = () => {
        setShowWalletOfSatoshiSteps(!showWalletOfSatoshiSteps);
    };

    const [hasCompletedEasyWay, setHasCompletedEasyWay] = useState(false);

    const [createBuyBitcoinPurchase] = useMutation(CREATE_BUY_BITCOIN_PURCHASE);

    useEffect(() => {
        if (!user) {
            localStorage.setItem('startingProductURL', '/start');
        }
    }, [user]);

    const handleContinue = () => {
        if (!user) {
            navigate('/login');
        }
    };


    const handleStarterPackSelection = (amount: number) => {
        if (!user || !user.email) {
            toast({
                title: 'Not logged in',
                description: 'Please log in to make a purchase.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        setSelectedAmount(amount);
        setIsAlertOpen(true);
    };

    //     try {
    //         const result = await createBuyBitcoinPurchase({
    //             variables: {
    //                 userEmail: user.email,
    //                 amountAUD: amount,
    //                 walletAddress: 'placeholder', // You might want to generate this or get it from somewhere
    //             },
    //         });

    //         // Save the transaction ID to local storage
    //         if (result.data && result.data.createBuyBitcoinPurchase && result.data.createBuyBitcoinPurchase.id) {
    //             localStorage.setItem('lastTransactionID', result.data.createBuyBitcoinPurchase.id);
    //         }

    //         toast({
    //             title: 'Starter Pack Purchase Submitted',
    //             description: `Your $${amount} Bitcoin Starter Pack purchase has been submitted successfully.`,
    //             status: 'success',
    //             duration: 3000,
    //             isClosable: true,
    //         });

    //         setHasCompletedEasyWay(true);
    //         navigate('/mybtcpurchases');
    //     } catch (error) {
    //         console.error('Error submitting purchase:', error);
    //         toast({
    //             title: 'Purchase failed',
    //             description: 'An error occurred while submitting your purchase.',
    //             status: 'error',
    //             duration: 3000,
    //             isClosable: true,
    //         });
    //     }
    // };


    const handleConfirmPurchase = async () => {
        setIsAlertOpen(false);
        if (!user || !user.email) {
            toast({
                title: 'Error',
                description: 'User information is missing.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        try {
            const result = await createBuyBitcoinPurchase({
                variables: {
                    userEmail: user.email,
                    amountAUD: selectedAmount,
                    walletAddress: 'placeholder',
                },
            });

            if (result.data && result.data.createBuyBitcoinPurchase && result.data.createBuyBitcoinPurchase.id) {
                localStorage.setItem('lastTransactionID', result.data.createBuyBitcoinPurchase.id);
            }

            toast({
                title: 'Starter Pack Purchase Submitted',
                description: `Your $${selectedAmount} Bitcoin Starter Pack purchase has been submitted successfully.`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            setHasCompletedEasyWay(true);
            navigate('/mybtcpurchases');
        } catch (error) {
            console.error('Error submitting purchase:', error);
            toast({
                title: 'Purchase failed',
                description: 'An error occurred while submitting your purchase.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleCancelPurchase = () => {
        setIsAlertOpen(false);
        toast({
            title: 'Purchase Cancelled',
            description: 'Your starter pack purchase has been cancelled.',
            status: 'info',
            duration: 3000,
            isClosable: true,
        });
    };

    const handleWalletOfSatoshi = () => {
        window.open('https://www.walletofsatoshi.com/', '_blank');
        // You might want to mark this as completed as well
        setHasCompletedEasyWay(true);
    };

    const handleHardWay = () => {
        if (hasCompletedEasyWay) {
            navigate('/advanced-bitcoin-guide');
        } else {
            alert('We recommend completing the easy way first before trying this option.');
        }
    };

    return (
        <Box>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={8}>
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="2xl" textAlign="center" mb={6}>Getting Started with Bitcoin</Heading>
                    {user ? (
                        <>
                            <Text fontSize={["lg", "xl"]} textAlign="center" mb={6}>
                                Welcome to the world of Bitcoin! There are two ways to get started:
                            </Text>
                            <VStack spacing={6} align="stretch" mt={4}>
                                <Box
                                    borderWidth={2}
                                    borderRadius="lg"
                                    p={6}
                                    bg={highlightBgColor}
                                    borderColor={highlightBorderColor}
                                    boxShadow="xl"
                                    transition="all 0.3s"
                                    _hover={{ transform: 'scale(1.02)' }}
                                    position="relative"
                                    overflow="hidden" // Change back to "hidden"
                                >
                                    <Box
                                        position="absolute"
                                        top={-5}
                                        right={0}
                                        bg="green.500"
                                        color="white"
                                        px={[1, 4]}
                                        py={[0.5, 1]}
                                        borderBottomLeftRadius="md"
                                        transform="translateX(30%) translateY(-30%) rotate(45deg)"
                                        transformOrigin="bottom left"
                                        boxShadow="md"
                                        fontSize={["xs", "sm"]}
                                        zIndex={1}
                                    >
                                        Recommended
                                    </Box>
                                    <Heading as="h3" size={["md", "lg"]} mb={4} color="blue.500">
                                        Easy Way (Recommended for Beginners)
                                    </Heading>
                                    <Text fontSize={["sm", "md"]} mb={4}>
                                        Start with a Bitcoin Starter Pack. This is the easiest way to get comfortable holding and using Bitcoin.
                                    </Text>
                                    <Heading as="h4" size={["sm", "md"]} mb={2}>
                                        Choose Your Starter Pack:
                                    </Heading>
                                    <VStack align="stretch" spacing={2}>
                                        {/* {[1500, 1000, 500, 300].map((amount) => ( */}
                                        {[300].map((amount) => (
                                            <Button
                                                colorScheme="blue"
                                                onClick={() => handleStarterPackSelection(amount)}
                                                size={["md", "lg"]} // Smaller size on mobile, larger on desktop
                                                width="full"
                                                boxShadow="md"
                                                _hover={{ boxShadow: 'lg' }}
                                                whiteSpace="normal" // Allow text wrapping
                                                height="auto" // Allow button to expand vertically
                                                py={[2, 4]} // Adjust padding for better mobile appearance
                                            >
                                                <Text fontSize={["sm", "md"]}>
                                                    ${amount} Starter Pack
                                                    <br />
                                                    (As an educational experience)
                                                </Text>
                                            </Button>
                                        ))}
                                    </VStack>
                                    <Text mt={4} fontStyle="italic" fontSize={["xs", "sm"]}>
                                        We recommend starting with the $300 pack to learn the basics of holding and using Bitcoin.
                                    </Text>
                                </Box>
                                <Box
                                    borderWidth={1}
                                    borderRadius="lg"
                                    p={6}
                                    bg={bgColor}
                                    borderColor={borderColor}
                                    boxShadow="lg"
                                    transition="all 0.3s"
                                    _hover={{ transform: 'scale(1.01)' }}
                                >
                                    <Heading as="h3" size="lg" mb={4}>
                                        Alternative Easy Way
                                    </Heading>
                                    <Text mb={4}>
                                        If you don't want to buy a starter pack, we recommend creating an account with Wallet of Satoshi and buying some Bitcoin through them.
                                    </Text>
                                    <Button
                                        colorScheme="green"
                                        onClick={toggleWalletOfSatoshiSteps}
                                        size="lg"
                                        width="full"
                                        boxShadow="md"
                                        _hover={{ boxShadow: 'lg' }}
                                    >
                                        {showWalletOfSatoshiSteps ? 'Hide Steps' : 'Learn More About Wallet of Satoshi'}
                                    </Button>
                                    <Collapse in={showWalletOfSatoshiSteps} animateOpacity>
                                        <Box mt={4} ml={4}>
                                            <OrderedList spacing={2}>
                                                <ListItem>
                                                    {' '}
                                                    <a
                                                        href="https://www.walletofsatoshi.com/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ textDecoration: 'underline', color: 'blue' }}
                                                    >
                                                        Wallet of Satoshi app
                                                    </a>
                                                    : Download and install it.
                                                </ListItem>
                                                <ListItem>Pass the KYC requirements by using their automatic system or emailing them.</ListItem>
                                                <ListItem>Press "Buy BTC" or "BTC Lightning" and specify how much you want to buy.</ListItem>
                                                <ListItem>Make the bank transfer.</ListItem>
                                                <ListItem>Wait for your BTC to be credited (the exact time may vary).</ListItem>
                                            </OrderedList>
                                            <Text mt={2} fontStyle="italic">
                                                Note: We do not know exactly how long the crediting process will take.
                                            </Text>
                                        </Box>
                                    </Collapse>
                                </Box>
                                <Box
                                    borderWidth={1}
                                    borderRadius="lg"
                                    p={6}
                                    bg={bgColor}
                                    borderColor={borderColor}
                                    boxShadow="lg"
                                    transition="all 0.3s"
                                    _hover={{ transform: 'scale(1.01)' }}
                                >
                                    <Heading as="h3" size="lg" mb={4}>
                                        Hard Way (For Advanced Users)
                                    </Heading>
                                    <Text mb={4}>
                                        If you're already comfortable holding Bitcoin and want more control, you can create an account with a cryptocurrency exchange and use a hardware wallet.
                                    </Text>
                                    <Button
                                        colorScheme="red"
                                        onClick={() => setShowAdvancedSteps(!showAdvancedSteps)}
                                        size="md"
                                        width="full"
                                        boxShadow="md"
                                        _hover={{ boxShadow: 'lg' }}
                                    // isDisabled={!hasCompletedEasyWay}
                                    >
                                        {showAdvancedSteps ? "Hide Advanced Steps" : "Learn About Exchanges and Hardware Wallets"}
                                    </Button>
                                    <Collapse in={showAdvancedSteps} animateOpacity>
                                        <Box mt={4} ml={4}>
                                            <Text fontWeight="bold" mb={2}>Steps for using an exchange:</Text>
                                            <OrderedList spacing={2} mb={4}>
                                                <ListItem>Create an account at an exchange (we recommend <a href="https://www.swyftx.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>Swyftx</a>)</ListItem>
                                                <ListItem>Pass KYC requirements</ListItem>
                                                <ListItem>Transfer in AUD</ListItem>
                                                <ListItem>Start a trade</ListItem>
                                                <ListItem>Transfer your crypto from the centralized exchange to a hardware wallet</ListItem>
                                            </OrderedList>
                                            <Text fontWeight="bold" mb={2}>Steps for using a hardware wallet:</Text>
                                            <OrderedList spacing={2}>
                                                <ListItem>Set up the hardware wallet</ListItem>
                                                <ListItem>Generate an address</ListItem>
                                                <ListItem>Paste that address into the exchange</ListItem>
                                                <ListItem>Receive the crypto on your hardware wallet</ListItem>
                                            </OrderedList>
                                        </Box>
                                    </Collapse>
                                    <Text mt={4} fontStyle="italic" color="red.500">
                                        We recommend trying the easy way first before attempting this lengthier process.
                                    </Text>


                                </Box>
                            </VStack>
                        </>
                    ) : (
                        <>
                            <Box
                                bg={notLoggedInBgColor}
                                borderRadius="xl"
                                p={8}
                                boxShadow="xl"
                                textAlign="center"
                            >
                                <VStack spacing={6}>
                                    <Heading as="h2" size="xl" color={notLoggedInHeadingColor}>
                                        🚀 Welcome to PledgeBuy's Bitcoin Adventure! 🌟
                                    </Heading>
                                    <Text fontSize={["lg", "xl"]} maxWidth="600px">
                                        Oops! Looks like you're not logged in yet. Don't worry, the exciting world of Bitcoin is just a click away!
                                    </Text>
                                    <Box>
                                        <Text fontSize={["md", "lg"]} fontWeight="bold" mb={2}>
                                            Once you're in, you'll have two awesome paths to choose from:
                                        </Text>
                                        <HStack justify="center" spacing={4}>
                                            <Badge colorScheme="green" p={2} borderRadius="md">Easy Peasy Way 😎</Badge>
                                            <Badge colorScheme="purple" p={2} borderRadius="md">Pro Challenge Way 💪</Badge>
                                        </HStack>
                                    </Box>
                                    <Text fontSize={["sm", "md"]} fontStyle="italic">
                                        Whether you're a beginner or a crypto pro, we've got the perfect journey for you!
                                    </Text>
                                    <Button
                                        colorScheme="blue"
                                        size="lg"
                                        onClick={handleContinue}
                                        _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                                        transition="all 0.2s"
                                    >
                                        🔓 Unlock Your Bitcoin Adventure
                                    </Button>
                                </VStack>
                            </Box>
                        </>
                    )}
                </VStack>
            </Container>
            <FooterWithFourColumns />


            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={handleCancelPurchase}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirm Purchase
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to buy the ${selectedAmount} Bitcoin Starter Pack?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={handleCancelPurchase}>
                                Cancel
                            </Button>
                            <Button colorScheme="blue" onClick={handleConfirmPurchase} ml={3}>
                                Confirm Purchase
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
};

export default Start;
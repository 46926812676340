import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
    Box,
    Container,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Text,
    useColorModeValue,
    Badge,
    Link,
    HStack,
    Button,
    VStack,
    TableContainer,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';

const GET_BTCPAY_INVOICES_WITH_USER_DETAILS = gql`
  query GetBTCPayInvoicesWithUserDetails {
    getBTCPayInvoicesWithUserIds {
      id
      status
      checkoutLink
      amount
      currency
      createdTime
      userId
      storeId
      mongodbProductId
      user {
        firstName
        lastName
        email
        phoneNumber
      }
    }
  }
`;

const BTCPayInvoicesWithUserIdPage = () => {
    const { user } = useAuth();
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(50);

    const { loading, error, data } = useQuery(GET_BTCPAY_INVOICES_WITH_USER_DETAILS);

    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const pageBgColor = useColorModeValue('gray.50', 'gray.900');

    if (!user || !user.permissions?.includes('admin')) {
        return <Text>You do not have permission to view this page.</Text>;
    }

    if (loading) return (
        <Box>
            <NavbarWithCallToAction />
            <Box maxWidth="1200px" margin="auto" padding={8}>
                <Spinner />
            </Box>
            <FooterWithFourColumns />
        </Box>
    );

    if (error) return (
        <Box>
            <NavbarWithCallToAction />
            <Box maxWidth="1200px" margin="auto" padding={8}>
                <Text>Error: {error.message}</Text>
            </Box>
            <FooterWithFourColumns />
        </Box>
    );

    const invoices = data.getBTCPayInvoicesWithUserIds;

    return (
        <Box bg={pageBgColor}>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={10}>
                <VStack spacing={10} align="stretch">
                    <Box
                        bg={bgColor}
                        boxShadow="xl"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h1" size="xl" mb={6}>
                            All Orders
                        </Heading>
                        <TableContainer>
                            <Table variant="simple" size="sm">
                                <Thead>
                                    <Tr>
                                        <Th>Invoice ID</Th>
                                        <Th>User</Th>
                                        <Th>Amount</Th>
                                        <Th>Status</Th>
                                        <Th>Created</Th>
                                        <Th>Product ID</Th>
                                        <Th>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {invoices.slice(skip, skip + take).map((invoice: any) => (
                                        <Tr key={invoice.id}>
                                 
                                            <Td>{invoice.id}</Td>
                                            <Td>
                                                <VStack align="start" spacing={0}>
                                                    <Text fontWeight="bold">
                                                        {invoice.user ? `${invoice.user.firstName} ${invoice.user.lastName}` : 'N/A'}
                                                    </Text>
                                                    <Text fontSize="sm">{invoice.user?.email || 'N/A'}</Text>
                                                    <Text fontSize="sm">{invoice.user?.phoneNumber || 'N/A'}</Text>
                                                </VStack>
                                            </Td>
                                            <Td>{`${invoice.amount} ${invoice.currency}`}</Td>
                                            <Td>
                                                <Badge colorScheme={invoice.status === 'Settled' ? 'green' : 'orange'}>
                                                    {invoice.status}
                                                </Badge>
                                            </Td>
                                            <Td>{new Date(invoice.createdTime * 1000).toLocaleString()}</Td>
                                            <Td>{invoice.mongodbProductId || 'N/A'}</Td>
                                            <Td>
                                                <Link href={invoice.checkoutLink} isExternal color="blue.500">
                                                    Checkout
                                                </Link>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <HStack justifyContent="space-between" mt={4}>
                            <Button onClick={() => setSkip(Math.max(0, skip - take))} disabled={skip === 0}>
                                Previous
                            </Button>
                            <Button onClick={() => setSkip(skip + take)} disabled={invoices.length < skip + take}>
                                Next
                            </Button>
                        </HStack>
                    </Box>
                </VStack>
            </Container>
            <FooterWithFourColumns />
        </Box>
    );
};

export default BTCPayInvoicesWithUserIdPage;
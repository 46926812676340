import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box, Button, Container, Heading, Text, VStack, List, ListItem, ListIcon,
    useToast, Checkbox, Center, Flex, Image, Progress,
    HStack,
    useColorModeValue,
    WrapItem,
    Badge,
    Wrap,
    Grid,
} from '@chakra-ui/react';

import { CheckCircleIcon, SunIcon, MoonIcon, StarIcon, InfoIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { PaymentStatusModal } from '../components/PaymentStatusModal';
import { useAuth } from '../contexts/AuthContext';
import { useInviteCode } from '../hooks/useInviteCode';
import { useMutation, useQuery, gql } from '@apollo/client';
import { getBitcoinPrice, calculateBTCAmount } from '../utils/helpers';

import CountdownTimer from '../components/CountdownTimer';

const CREATE_BTCPAY_INVOICE = gql`
    mutation CreateBTCPayInvoice($amount: Float!, $currency: String!, $userId: String!, $mongodbProductId: ID!) {
        createBTCPayInvoice(amount: $amount, currency: $currency, userId: $userId, mongodbProductId: $mongodbProductId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const GET_BTCPAY_INVOICE = gql`
    query GetBTCPayInvoice($invoiceId: String!) {
        getBTCPayInvoice(invoiceId: $invoiceId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const MysteryMasteryCourse = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [checkoutLink, setCheckoutLink] = useState('');
    const [invoiceStatus, setInvoiceStatus] = useState('');
    const [paymentComplete, setPaymentComplete] = useState(false);

    const bgColor = useColorModeValue('green.50', 'green.900');
    const cardBgColor = useColorModeValue('white', 'green.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const accentColor = useColorModeValue('green.500', 'green.300');
    const borderColor = useColorModeValue('green.200', 'green.600');
    const anotherColor = useColorModeValue('green.100', 'green.700');

    const retreatStartDate = new Date('2024-09-20T00:00:00');


    const [btcPrice, setBtcPrice] = useState<number>(0);
    const [btcAmount, setBtcAmount] = useState<number>(0);
    const AUD_PRICE = 800; // Set the AUD price for the course

    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const { user, isLoading } = useAuth();
    useInviteCode();

    const [createBTCPayInvoice] = useMutation(CREATE_BTCPAY_INVOICE);
    const { data: invoiceData, startPolling, stopPolling } = useQuery(GET_BTCPAY_INVOICE, {
        variables: { invoiceId },
        skip: !invoiceId,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (invoiceId) {
            startPolling(1000);
        }
        return () => stopPolling();
    }, [invoiceId, startPolling, stopPolling]);


    useEffect(() => {
        const fetchBtcPrice = async () => {
            const price = await getBitcoinPrice();
            setBtcPrice(price);
            const btcAmount = calculateBTCAmount(AUD_PRICE, price);
            setBtcAmount(btcAmount);
        };

        fetchBtcPrice();
        const interval = setInterval(fetchBtcPrice, 60000); // Update price every minute

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (invoiceData) {
            const status = invoiceData.getBTCPayInvoice.status;
            setInvoiceStatus(status);
            if (['Paid', 'Confirmed', 'Settled'].includes(status)) {
                setPaymentComplete(true);
                toast({
                    title: "Payment Completed",
                    description: "Your enrollment has been successfully processed.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                stopPolling();
            }
        }
    }, [invoiceData, stopPolling, toast]);

    useEffect(() => {
        if (!user) {
            localStorage.setItem('startingProductURL', '/mystery-mastery-course');
        }
    }, [user]);

    const handleEnrollment = async () => {
        if (!agreeToTerms) {
            toast({
                title: "Agreement Required",
                description: "Please agree to the terms before enrolling.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (user) {
            try {
                console.log('Processing enrollment for user:', user);
                const { data } = await createBTCPayInvoice({
                    variables: {
                        // amount: 0.00940853,
                        amount: btcAmount,

                        currency: "BTC",
                        userId: user.id,
                        mongodbProductId: "66d548583bfa3a727704ef86"
                    },
                });
                console.log('BTCPay invoice created:', data);
                setInvoiceId(data.createBTCPayInvoice.id);
                setCheckoutLink(data.createBTCPayInvoice.checkoutLink);
                setInvoiceStatus(data.createBTCPayInvoice.status);
                setIsModalOpen(true);
            } catch (error: any) {
                console.error('Error creating BTCPay invoice:', error);
                toast({
                    title: "Error",
                    description: `Failed to process enrollment: ${error.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            // navigate('/login', { state: { from: location } });
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        if (paymentComplete) {
            toast({
                title: "Enrollment Confirmed",
                description: "Welcome to the Mystery Mastery Course! Your journey begins now.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Enrollment Pending",
                description: "Complete your payment to secure your spot in the course.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    return (
        <Box bg={bgColor} minHeight="100vh">
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    <Box
                        borderRadius="xl"
                        overflow="hidden"
                        boxShadow="2xl"
                        bg={cardBgColor}
                        position="relative"
                    >
                        <Image
                            src="/product_images/mystery-mastery.jpg"
                            alt="Mystery Mastery Nature Retreat"
                            width="100%"
                            height="300px"
                            objectFit="cover"
                            objectPosition="center"
                        />
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            right="0"
                            bottom="0"
                            bg="rgba(0,0,0,0.4)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            textAlign="center"
                            p={8}
                        >
                            <Heading as="h1" size="2xl" color="white" mb={4}>
                                Mystery Mastery Nature Retreat
                            </Heading>
                            <Text fontSize="xl" color="white">
                                Unlock the Secrets of Personal Transformation in Nature's Embrace
                            </Text>
                        </Box>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Text fontSize="xl" lineHeight="tall">
                            Embark on a life-changing journey with our Mystery Mastery Nature Retreat. Over three intense days, you'll discover hidden truths about yourself and unlock the potential you never knew you had. Immerse yourself in nature, find your flow, and experience true bliss in our boutique camping setting.
                        </Text>
                        <Text fontSize="md" mt={4} fontStyle="italic">
                            Note: Retreat sites can be provided if required. Please contact us for more information.
                        </Text>
                    </Box>

                    {/* Add the CountdownTimer component */}
                    <Box
                        borderRadius="lg"
                        p={6}
                        bg={cardBgColor}
                        color={textColor}
                        textAlign="center"
                        boxShadow="xl"
                    >
                        <CountdownTimer targetDate={retreatStartDate} />
                    </Box>

                    {/* Add the retreat dates Box */}
                    <Box
                        borderRadius="lg"
                        p={6}
                        bg={accentColor}
                        color="white"
                        textAlign="center"
                        boxShadow="xl"
                    >
                        <Heading as="h2" size="xl" mb={2}>
                            Upcoming Retreat Dates
                        </Heading>
                        <Text fontSize="2xl" fontWeight="bold">
                            September 20 - 22, 2024
                        </Text>
                        <Text fontSize="lg" mt={2}>
                            Join us for 3 transformative days in nature
                        </Text>
                    </Box>
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Retreat Overview</Heading>
                        <Text mb={6}>
                            This exclusive nature retreat is designed to take you on a transformative journey, exploring the depths of your mind while surrounded by the beauty of the natural world. Through a series of carefully crafted exercises, discussions, and experiences, you'll discover:
                        </Text>
                        <Grid
                            templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]}
                            gap={6}
                        >
                            {[
                                { icon: SunIcon, text: "The power of positive mindset in nature" },
                                { icon: MoonIcon, text: "Techniques to tap into your subconscious mind" },
                                { icon: StarIcon, text: "The art of transmuting energy for personal growth" },
                                { icon: CheckCircleIcon, text: "Strategies to overcome fear and self-doubt" }
                            ].map((item, index) => (
                                <Box
                                    key={index}
                                    p={4}
                                    borderRadius="md"
                                    bg={bgColor}
                                    boxShadow="md"
                                >
                                    <HStack spacing={4} align="center">
                                        <Box
                                            as={item.icon}
                                            fontSize="2xl"
                                            color={accentColor}
                                        />
                                        <Text
                                            fontWeight="bold"
                                            fontSize={["sm", "md"]}
                                        >
                                            {item.text}
                                        </Text>
                                    </HStack>
                                </Box>
                            ))}
                        </Grid>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Retreat Timeline</Heading>
                        <VStack spacing={8} align="stretch">
                            {[
                                { day: "Day 1", title: "Awakening in Nature", time: "9:00 AM - 5:00 PM", description: "Connect with nature and dive deep into the power of positive thinking. Learn techniques to reprogram your mind for success while enjoying guided nature walks." },
                                { day: "Day 2", title: "Harnessing Inner Strength", time: "9:00 AM - 5:00 PM", description: "Explore techniques to tap into your subconscious and overcome limitations. Discover the power of visualization and affirmations through outdoor meditation sessions." },
                                { day: "Day 3", title: "Mastering Transformation", time: "9:00 AM - 5:00 PM", description: "Learn the art of energy transmutation and solidify your personal growth plan. Create a roadmap for your continued success while engaging in team-building nature activities." }
                            ].map((item, index) => (
                                <Box
                                    key={index}
                                    borderLeft="4px solid"
                                    borderColor={accentColor}
                                    pl={4}
                                    py={2}
                                >
                                    <Heading size="md">{item.day}: {item.title}</Heading>
                                    <Text fontSize="sm" color={accentColor} mt={1}>{item.time}</Text>
                                    <Text mt={2}>{item.description}</Text>
                                </Box>
                            ))}
                        </VStack>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Why Join the Mystery Mastery Nature Retreat?</Heading>
                        <VStack spacing={4} align="stretch">
                            {[
                                "Experience personal transformation in a serene natural setting",
                                "Enjoy boutique camping accommodations for a unique retreat experience",
                                "Learn from experienced mentors who have mastered these techniques",
                                "Connect with like-minded individuals on a similar journey of self-discovery",
                                "Receive a certificate of completion and ongoing support post-retreat"
                            ].map((item, index) => (
                                <Box
                                    key={index}
                                    p={3}
                                    borderRadius="md"
                                    bg={anotherColor}
                                    color={textColor}
                                >
                                    <HStack alignItems="flex-start" spacing={3}>
                                        <Box as={CheckCircleIcon} mt={1} color={accentColor} />
                                        <Text fontSize={["sm", "md"]}>{item}</Text>
                                    </HStack>
                                </Box>
                            ))}
                        </VStack>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Retreat Details</Heading>
                        <HStack>
                            <Box as={InfoIcon} color={accentColor} />
                            <Text fontWeight="bold">Dates:</Text>
                            <Text>September 20 - 22, 2024</Text>
                        </HStack>
                        <HStack alignItems="flex-start">
                            <Box as={InfoIcon} color={accentColor} mt={1} />
                            <VStack align="start" spacing={0}>
                                <Text fontWeight="bold">Location:</Text>
                                <Text>Beautiful natural setting (exact location provided upon registration)</Text>
                            </VStack>
                        </HStack>
                       
                        <HStack alignItems="flex-start">
                            <Box as={InfoIcon} color={accentColor} mt={1} />
                            <VStack align="start" spacing={0}>
                                <Text fontWeight="bold">Price:</Text>
                                <Text>${AUD_PRICE} AUD ({btcAmount.toFixed(8)} BTC)</Text>
                                <Text fontSize="sm">Includes boutique camping accommodations, meals, and all retreat materials.</Text>
                            </VStack>
                        </HStack>
                        <Text fontSize="lg" mb={4}>
                            Secure your spot in this life-changing nature retreat for just ${AUD_PRICE} AUD ({btcAmount.toFixed(8)} BTC).
                        </Text>
                        <Text fontSize="sm" mb={2}>
                            Current BTC Price: 1 BTC = ${btcPrice.toFixed(2)} AUD
                        </Text>
                        <VStack align="start" spacing={1} fontSize="sm" mb={4}>
                            <Text fontWeight="bold">Calculation Breakdown:</Text>
                            <Text>1 BTC = ${btcPrice.toFixed(2)} AUD</Text>
                            <Text>1 AUD = {(1 / btcPrice).toFixed(8)} BTC</Text>
                            <Text>${AUD_PRICE} AUD = {AUD_PRICE} × {(1 / btcPrice).toFixed(8)} BTC</Text>
                            <Text fontWeight="bold">
                                ${AUD_PRICE} AUD = {btcAmount.toFixed(8)} BTC
                            </Text>
                        </VStack>
                        <Text fontSize="xs" fontStyle="italic" mb={4}>
                            (Price updates every minute to ensure accuracy)
                        </Text>
                        <Text fontSize="md" mb={6}>
                            Price includes boutique camping accommodations, meals, and all retreat materials. For those who prefer, we can provide information on nearby retreat sites.
                        </Text>
                        {user ? (
                            <VStack spacing={4} align="stretch">
                                <Checkbox
                                    isChecked={agreeToTerms}
                                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                                    colorScheme="green"
                                >
                                    I agree to the retreat terms and conditions
                                </Checkbox>
                                <Button
                                    colorScheme="green"
                                    size="lg"
                                    onClick={handleEnrollment}
                                    isDisabled={!agreeToTerms}
                                    width="full"
                                    boxShadow="md"
                                    _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                    transition="all 0.2s"
                                >
                                    Reserve Your Spot
                                </Button>
                            </VStack>
                        ) : (
                            <Button
                                colorScheme="green"
                                size="lg"
                                onClick={() => navigate('/login', { state: { from: location } })}
                                width="full"
                                boxShadow="md"
                                _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                transition="all 0.2s"
                            >
                                Login to Reserve
                            </Button>
                        )}
                    </Box>
                </VStack>
            </Container>

            <PaymentStatusModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                invoiceId={invoiceId}
                invoiceStatus={invoiceStatus}
                checkoutLink={checkoutLink}
                amount={invoiceData?.getBTCPayInvoice.amount}
                currency={invoiceData?.getBTCPayInvoice.currency}
            />

            <FooterWithFourColumns />
        </Box>
    );
};

export default MysteryMasteryCourse;
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box, Button, Container, Heading, Text, VStack, List, ListItem, ListIcon,
    useToast, Checkbox, Center, Flex, Image, Progress,
    HStack, useColorModeValue, WrapItem, Badge, Wrap, Grid,
    Spacer, Link, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark
} from '@chakra-ui/react';
import { CheckCircleIcon, PhoneIcon, EmailIcon, ExternalLinkIcon, DownloadIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { PaymentStatusModal } from '../components/PaymentStatusModal';
import { useAuth } from '../contexts/AuthContext';
import { useInviteCode } from '../hooks/useInviteCode';
import { useMutation, useQuery, gql } from '@apollo/client';

import CountdownTimer from '../components/CountdownTimer';
import SMSNotificationSignup from '../components/SMSNotificationSignup';

import { calculateBTCAmount } from '../utils/helpers';

// GraphQL Queries and Mutations
const GET_BITCOIN_PRICE = gql`
  query GetBitcoinPrice {
    getBitcoinPrice
  }
`;

const CREATE_BTCPAY_INVOICE = gql`
    mutation CreateBTCPayInvoice($amount: Float!, $currency: String!, $userId: String!, $mongodbProductId: ID!) {
        createBTCPayInvoice(amount: $amount, currency: $currency, userId: $userId, mongodbProductId: $mongodbProductId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const GET_BTCPAY_INVOICE = gql`
    query GetBTCPayInvoice($invoiceId: String!) {
        getBTCPayInvoice(invoiceId: $invoiceId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const GoldenHillCommunityButcher = () => {
    // State variables
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [checkoutLink, setCheckoutLink] = useState('');
    const [invoiceStatus, setInvoiceStatus] = useState('');
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [audAmount, setAudAmount] = useState(1000); // Default to 1000 AUD
    const [btcPrice, setBtcPrice] = useState(0);
    const [btcAmount, setBtcAmount] = useState(0);
    const [priceLoaded, setPriceLoaded] = useState(false);

    // Chakra UI color mode values
    const bgColor = useColorModeValue('yellow.50', 'yellow.900');
    const cardBgColor = useColorModeValue('white', 'yellow.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const accentColor = useColorModeValue('yellow.500', 'yellow.300');
    const borderColor = useColorModeValue('yellow.200', 'yellow.600');
    const yellow = useColorModeValue('yellow.100', 'yellow.700');

    // Hooks
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const { user, isLoading } = useAuth();
    useInviteCode();

    // Constants
    const AUD_PRICE = 1000; // Set the minimum AUD price here

    // GraphQL Mutations and Queries
    const [createBTCPayInvoice] = useMutation(CREATE_BTCPAY_INVOICE);
    const { data: invoiceData, startPolling, stopPolling } = useQuery(GET_BTCPAY_INVOICE, {
        variables: { invoiceId },
        skip: !invoiceId,
        fetchPolicy: 'network-only',
    });

    const { data: btcPriceData, loading: btcPriceLoading } = useQuery(GET_BITCOIN_PRICE, {
        pollInterval: 60000, // Poll every minute
    });

    // Effects

    // Fetch BTC price once on component mount
    useEffect(() => {
        if (btcPriceData) {
            setBtcPrice(btcPriceData.getBitcoinPrice);
            const newBtcAmount = calculateBTCAmount(audAmount, btcPriceData.getBitcoinPrice);
            setBtcAmount(newBtcAmount);
        }
    }, [btcPriceData, audAmount]);

    // Update BTC amount when AUD amount changes
    useEffect(() => {
        if (priceLoaded) {
            const newBtcAmount = calculateBTCAmount(audAmount, btcPrice);
            setBtcAmount(newBtcAmount);
        }
    }, [audAmount, btcPrice, priceLoaded]);

    // Store starting product URL if user is not logged in
    useEffect(() => {
        if (!user) {
            localStorage.setItem('startingProductURL', '/golden-hill-community-butcher-final');
        }
    }, [user]);

    // Start polling for invoice status when invoiceId is available
    useEffect(() => {
        if (invoiceId) {
            startPolling(1000);
        }
        return () => stopPolling();
    }, [invoiceId, startPolling, stopPolling]);

    // Handle invoice status changes
    useEffect(() => {
        if (invoiceData) {
            const status = invoiceData.getBTCPayInvoice.status;
            setInvoiceStatus(status);
            if (['Paid', 'Confirmed', 'Settled'].includes(status)) {
                setPaymentComplete(true);
                toast({
                    title: "Payment Completed",
                    description: "Your support for Golden Hill Community Butcher has been processed.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                stopPolling();
            }
        }
    }, [invoiceData, stopPolling, toast]);

    // Update BTC price and amount when new data is received
    useEffect(() => {
        if (btcPriceData && btcPriceData.getBitcoinPrice) {
            const price = btcPriceData.getBitcoinPrice;
            setBtcPrice(price);
            const newBtcAmount = calculateBTCAmount(audAmount, price);
            setBtcAmount(newBtcAmount);
        }
    }, [btcPriceData, audAmount]);

    // Handler Functions

    // Handle support button click
    const handleSupport = async () => {
        if (!agreeToTerms) {
            toast({
                title: "Agreement Required",
                description: "Please agree to the terms before pledging your support.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (user) {
            try {
                const { data } = await createBTCPayInvoice({
                    variables: {
                        amount: btcAmount,
                        currency: "BTC",
                        userId: user.id,
                        mongodbProductId: "66d6612070d2d94c266f3342"
                    },
                });
                setInvoiceId(data.createBTCPayInvoice.id);
                setCheckoutLink(data.createBTCPayInvoice.checkoutLink);
                setInvoiceStatus(data.createBTCPayInvoice.status);
                setIsModalOpen(true);
            } catch (error: any) {
                console.error('Error creating BTCPay invoice:', error);
                toast({
                    title: "Error",
                    description: `Failed to process support: ${error.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            // navigate('/login', { state: { from: location } });
        }
    };

    // Handle modal close
    const handleModalClose = () => {
        setIsModalOpen(false);
        if (paymentComplete) {
            toast({
                title: "Support Confirmed",
                description: "Thank you for supporting Golden Hill Community Butcher!",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Support Pending",
                description: "Complete your payment to finalize your support.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // Render loading state
    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    // Main component render
    return (
        <Box bg={bgColor} minHeight="100vh">
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    {/* Hero Section */}
                    <Box
                        borderRadius="xl"
                        overflow="hidden"
                        boxShadow="2xl"
                        bg={cardBgColor}
                        position="relative"
                    >
                        {/* Hero Image and Overlay */}
                        <Image
                            src="/product_images/community_butcher.png"
                            alt="Golden Hill Community Butcher & Food Hub"
                            width="100%"
                            height="300px"
                            objectFit="cover"
                            objectPosition="center"
                        />
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            right="0"
                            bottom="0"
                            bg="rgba(0,0,0,0.4)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            textAlign="center"
                            p={8}
                        >
                            <Heading as="h1" size="2xl" color="white" mb={4}>
                                Golden Hill Community Butcher & Food Hub
                            </Heading>
                            <Text fontSize="xl" color="white">
                                Supporting Local Farmers, Nourishing Our Community
                            </Text>
                        </Box>
                    </Box>

                    {/* Vision Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Our Vision</Heading>
                        <Text fontSize="xl" lineHeight="tall" mb={4}>
                            At Golden Hill Farm Community Butcher, we're on a mission to revolutionize our local food system. We aim to:
                        </Text>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Establish a community food hub with locally sourced produce
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Deepen connection between consumers and producers in our region
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Supply ethical and nutritious food from our region for our region
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Create a strong local food system, supporting local employment and our regional economy
                            </ListItem>
                        </List>
                    </Box>

                    {/* About Us Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>About Us</Heading>
                        <Text fontSize="lg" mb={4}>
                            Led by Luke Tresize and De'arne Chapman, we bring decades of experience in culinary arts and education to create a hub that not only provides high-quality, ethically-sourced meats but also supports our local farmers and creates opportunities for our community's youth.
                        </Text>
                        <Text fontSize="lg">
                            Our focus is on raising livestock using regenerative agriculture practices, emphasizing animal welfare, and showcasing locally sourced, wholesome ingredients.
                        </Text>
                    </Box>

                    {/* Our Pledge Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Our Pledge</Heading>
                        <Text fontSize="lg" mb={4}>
                            We commit to:
                        </Text>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Sourcing meats exclusively from within our designated radius
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Supporting clean, ethical farming practices
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Creating local jobs and opportunities
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Donating a portion of our profits to local charities and organizations
                            </ListItem>
                        </List>
                    </Box>

                    {/* Support Our Initiative Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Support Our Initiative</Heading>
                        <Text fontSize="lg" mb={4}>
                            Join us in building a stronger, more sustainable local food system. Your support will help us establish our community butcher and food hub, creating a vital link between local farmers and consumers.
                        </Text>
                        <Box
                            borderRadius="lg"
                            p={8}
                            bg={cardBgColor}
                            boxShadow="xl"
                            color={textColor}
                        >
                            <Heading as="h2" size="xl" mb={6}>Choose Your Support Amount</Heading>
                            <Text fontSize="lg" mb={4}>
                                Slide to select your support amount in AUD:
                            </Text>
                            <Slider
                                aria-label="slider-ex-1"
                                defaultValue={1000}
                                min={50}
                                max={50000}
                                step={50}
                                onChange={(val) => setAudAmount(val)}
                            >
                                <SliderMark value={50} mt='1' ml='-2.5' fontSize='sm'>
                                    $50
                                </SliderMark>
                                <SliderMark value={25000} mt='1' ml='-2.5' fontSize='sm'>
                                    $25000
                                </SliderMark>
                                <SliderMark value={50000} mt='1' ml='-7' fontSize='sm'>
                                    $50000
                                </SliderMark>
                                <SliderMark
                                    value={audAmount}
                                    textAlign='center'
                                    bg='blue.500'
                                    color='white'
                                    mt='-10'
                                    ml='-5'
                                    w='12'
                                >
                                    ${audAmount}
                                </SliderMark>
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                            <Text fontSize="lg" mt={6} mb={2}>
                                Your support: ${audAmount} AUD ≈ {btcAmount.toFixed(8)} BTC
                            </Text>
                            <Text fontSize="sm" mb={2}>
                                Current BTC Price: 1 BTC = ${btcPriceLoading ? 'Loading...' : btcPrice.toFixed(2)} AUD
                            </Text>
                            <Text fontSize="xs" fontStyle="italic" mb={4}>
                                (Price updates every minute to ensure accuracy)
                            </Text>
                        </Box>
                        <Text fontSize="sm" mb={2}>
                            Current BTC Price: 1 BTC = ${btcPrice.toFixed(2)} AUD
                        </Text>
                        {user ? (
                            <VStack spacing={4} align="stretch">
                                <Checkbox
                                    isChecked={agreeToTerms}
                                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                                    colorScheme="yellow"
                                >
                                    I agree to support Golden Hill Community Butcher & Food Hub
                                </Checkbox>
                                <Button
                                    colorScheme="yellow"
                                    size="lg"
                                    onClick={handleSupport}
                                    isDisabled={!agreeToTerms}
                                    width="full"
                                    boxShadow="md"
                                    _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                    transition="all 0.2s"
                                >
                                    Support Our Initiative
                                </Button>
                            </VStack>
                        ) : (
                            <Button
                                colorScheme="yellow"
                                size="lg"
                                onClick={() => navigate('/login', { state: { from: location } })}
                                width="full"
                                boxShadow="md"
                                _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                transition="all 0.2s"
                            >
                                Login to Support
                            </Button>
                        )}
                    </Box>

                    {/* Contact Us Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Contact Us</Heading>
                        <VStack spacing={4} align="start">
                            <HStack>
                                <PhoneIcon color={accentColor} />
                                <Text>Luke: 0408418104</Text>
                            </HStack>
                            <HStack>
                                <EmailIcon color={accentColor} />
                                <Text>info@goldenhillfarm.com.au</Text>
                            </HStack>
                            <HStack>
                                <ExternalLinkIcon color={accentColor} />
                                <Link href="https://www.goldenhillfarm.com.au" isExternal>
                                    www.goldenhillfarm.com.au
                                </Link>
                            </HStack>
                            <Text>5771 Lowmead Road Rosedale QLD</Text>
                        </VStack>
                    </Box>

                    {/* The Build Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>The Build</Heading>
                        <Text fontSize="lg" mb={4}>
                            Our unique and innovative Golden Hill Community Butcher Room utilizes three shipping containers (one forty-foot container and two twenty-foot containers). This cost-effective design overcomes existing regulatory roadblocks faced by local producers and offers flexibility for future operations.
                        </Text>
                    </Box>

                    {/* Funding Target Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Funding Target: $155,000</Heading>
                        <Text fontSize="lg" mb={4}>
                            Your support will help us cover the following costs:
                        </Text>
                        <List spacing={3}>
                            {[
                                "Footings and ground work",
                                "Containers",
                                "Electrical",
                                "Plumbing",
                                "Equipment",
                                "Administration costs",
                                "Build",
                                "Refrigeration",
                                "Fit out",
                                "Marketing",
                                "Generator/ back-up power",
                                "Miscellaneous"
                            ].map((item, index) => (
                                <ListItem key={index}>
                                    <ListIcon as={CheckCircleIcon} color="green.500" />
                                    {item}
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    {/* Benefits of Your Support Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Benefits of Your Support</Heading>
                        <Text fontSize="lg" mb={4}>
                            By supporting Golden Hill Farm Community Butcher, you're:
                        </Text>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Investing in the heart and soul of our community
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Nurturing a thriving local economy
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Creating jobs and fostering community connection
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Supporting local farmers and fair pricing
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Encouraging sustainable and ethical farming practices
                            </ListItem>
                        </List>
                        <Text fontSize="lg" mt={4}>
                            Together, we can build a brighter future for our community, where everyone—from the farmer to the consumer—reaps the rewards of a more sustainable and interconnected food system.
                        </Text>
                    </Box>

                    {/* Support Packages Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Support Packages</Heading>
                        <Text fontSize="lg" mb={6}>
                            Choose a support package that aligns with your goals. Whether you're a customer or a producer, we have options to enhance your experience with Golden Hill Community Butcher & Food Hub.
                        </Text>
                        <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} gap={6}>
                            {/* Customer Package */}
                            <Box
                                p={6}
                                borderRadius="md"
                                bg={yellow}
                                boxShadow="md"
                            >
                                <Heading as="h3" size="lg" mb={3}>Customer Package</Heading>
                                <Text fontWeight="bold" mb={3}>$50-$400</Text>
                                <List spacing={3}>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color="green.500" />
                                        5% discount on all purchases for 12 months
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color="green.500" />
                                        Receive a mystery meat pack
                                    </ListItem>
                                </List>
                                <Text mt={4} fontSize="sm">
                                    Perfect for individuals and families looking to support local, sustainable farming while enjoying premium quality meats.
                                </Text>
                            </Box>
                            {/* Premium Customer Package */}
                            <Box
                                p={6}
                                borderRadius="md"
                                bg={yellow}
                                boxShadow="md"
                            >
                                <Heading as="h3" size="lg" mb={3}>Premium Customer Package</Heading>
                                <Text fontWeight="bold" mb={3}>$1000-$5000</Text>
                                <List spacing={3}>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color="green.500" />
                                        10% discount on all purchases for 12 months
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color="green.500" />
                                        Receive a premium mystery meat pack
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color="green.500" />
                                        Priority access to limited edition products
                                    </ListItem>
                                </List>
                                <Text mt={4} fontSize="sm">
                                    Ideal for those who want to make a significant impact while enjoying substantial benefits and exclusive offers.
                                </Text>
                            </Box>
                            {/* Producer Package */}
                            <Box
                                p={6}
                                borderRadius="md"
                                bg={yellow}
                                boxShadow="md"
                            >
                                <Heading as="h3" size="lg" mb={3}>Producer Package</Heading>
                                <Text fontWeight="bold" mb={3}>$500</Text>
                                <List spacing={3}>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color="green.500" />
                                        Become a preferred supplier
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color="green.500" />
                                        Monthly Farmer Promotions
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color="green.500" />
                                        Networking opportunities with other local producers
                                    </ListItem>
                                </List>
                                <Text mt={4} fontSize="sm">
                                    Designed for local farmers and producers looking to expand their reach and connect with a community of conscious consumers.
                                </Text>
                            </Box>
                            {/* Premium Producer Package */}
                            <Box
                                p={6}
                                borderRadius="md"
                                bg={yellow}
                                boxShadow="md"
                            >
                                <Heading as="h3" size="lg" mb={3}>Premium Producer Package</Heading>
                                <Text fontWeight="bold" mb={3}>$1000</Text>
                                <List spacing={3}>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color="green.500" />
                                        Become a preferred supplier
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color="green.500" />
                                        Enhanced Monthly Farmer Promotions
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color="green.500" />
                                        Discounted personal processing
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={CheckCircleIcon} color="green.500" />
                                        Featured spotlights on our website and social media
                                    </ListItem>
                                </List>
                                <Text mt={4} fontSize="sm">
                                    The ultimate package for producers committed to sustainable farming and looking to maximize their partnership with Golden Hill Community Butcher.
                                </Text>
                            </Box>
                        </Grid>

                        {/* Detailed Project Information Section */}
                        <Box
                            borderRadius="lg"
                            p={8}
                            bg={cardBgColor}
                            boxShadow="xl"
                            color={textColor}
                            mt={8}
                        >
                            <Heading as="h2" size="xl" mb={6}>Detailed Project Information</Heading>
                            <Text fontSize="lg" mb={4}>
                                For a comprehensive overview of the Golden Hill Community Butcher & Food Hub project, including our vision, team, and detailed plans, we've prepared a detailed PDF document.
                            </Text>
                            <Button
                                leftIcon={<DownloadIcon />}
                                colorScheme="green"
                                size="lg"
                                onClick={() => window.open('/GoldenHillCommunityButcherfinal.pdf', '_blank')}
                            >
                                Download Project PDF
                            </Button>
                            <Text fontSize="sm" mt={4}>
                                This document provides in-depth information about our initiative, our team, and how your support will help shape the future of local, sustainable food production in our community.
                            </Text>
                        </Box>
                        <Box mt={8} p={6} borderRadius="md" bg={cardBgColor} boxShadow="md">
                            <Heading as="h3" size="lg" mb={4}>Accessing Your Benefits</Heading>
                            <Text mb={4}>
                                Once you've made your support purchase, you'll receive a unique proof of purchase. This will allow you to access your discounts and benefits directly on our website.
                            </Text>
                            <List spacing={3}>
                                <ListItem>
                                    <ListIcon as={CheckCircleIcon} color="green.500" />
                                    Log in to your account on our website
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckCircleIcon} color="green.500" />
                                    Navigate to the 'My Benefits' section
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckCircleIcon} color="green.500" />
                                    Enter your proof of purchase code to unlock your package benefits
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckCircleIcon} color="green.500" />
                                    Enjoy automatic discounts applied at checkout for eligible purchases
                                </ListItem>
                            </List>
                            <Text mt={4}>
                                For producers, additional instructions on accessing your benefits and promotional opportunities will be provided upon package activation.
                            </Text>
                        </Box>
                        <Text mt={6} fontStyle="italic">
                            By supporting Golden Hill Community Butcher & Food Hub, you're not just making a purchase
                            By supporting Golden Hill Community Butcher & Food Hub, you're not just making a purchase – you're investing in a sustainable future for local agriculture and food production. Thank you for being part of our community!
                        </Text>
                    </Box>

                    {/* Local Organizations We Hope to Support Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Local Organizations We Hope to Support</Heading>
                        <List spacing={3}>
                            {[
                                "SES",
                                "Local Hall",
                                "Local schools in the area",
                                "Rural Fire brigades",
                                "Charities",
                                "Community support groups",
                                "Junior sporting organizations"
                            ].map((item, index) => (
                                <ListItem key={index}>
                                    <ListIcon as={CheckCircleIcon} color="green.500" />
                                    {item}
                                </ListItem>
                            ))}
                        </List>
                    </Box>



                    {/* Support Our Initiative Section */}
                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Support Our Initiative</Heading>
                        <Text fontSize="lg" mb={4}>
                            Join us in building a stronger, more sustainable local food system. Your support will help us establish our community butcher and food hub, creating a vital link between local farmers and consumers.
                        </Text>
                        <Box
                            borderRadius="lg"
                            p={8}
                            bg={cardBgColor}
                            boxShadow="xl"
                            color={textColor}
                        >
                            <Heading as="h2" size="xl" mb={6}>Choose Your Support Amount</Heading>
                            <Text fontSize="lg" mb={4}>
                                Slide to select your support amount in AUD:
                            </Text>
                            <Slider
                                aria-label="slider-ex-1"
                                defaultValue={1000}
                                min={50}
                                max={50000}
                                step={50}
                                onChange={(val) => setAudAmount(val)}
                            >
                                <SliderMark value={50} mt='1' ml='-2.5' fontSize='sm'>
                                    $50
                                </SliderMark>
                                <SliderMark value={25000} mt='1' ml='-2.5' fontSize='sm'>
                                    $25000
                                </SliderMark>
                                <SliderMark value={50000} mt='1' ml='-7' fontSize='sm'>
                                    $50000
                                </SliderMark>
                                <SliderMark
                                    value={audAmount}
                                    textAlign='center'
                                    bg='blue.500'
                                    color='white'
                                    mt='-10'
                                    ml='-5'
                                    w='12'
                                >
                                    ${audAmount}
                                </SliderMark>
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                            <Text fontSize="lg" mt={6} mb={2}>
                                Your support: ${audAmount} AUD ≈ {btcAmount.toFixed(8)} BTC
                            </Text>
                            <Text fontSize="sm" mb={2}>
                                Current BTC Price: 1 BTC = ${btcPriceLoading ? 'Loading...' : btcPrice.toFixed(2)} AUD
                            </Text>
                            <Text fontSize="xs" fontStyle="italic" mb={4}>
                                (Price updates every minute to ensure accuracy)
                            </Text>
                        </Box>
                        <Text fontSize="sm" mb={2}>
                            Current BTC Price: 1 BTC = ${btcPrice.toFixed(2)} AUD
                        </Text>
                        {user ? (
                            <VStack spacing={4} align="stretch">
                                <Checkbox
                                    isChecked={agreeToTerms}
                                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                                    colorScheme="yellow"
                                >
                                    I agree to support Golden Hill Community Butcher & Food Hub
                                </Checkbox>
                                <Button
                                    colorScheme="yellow"
                                    size="lg"
                                    onClick={handleSupport}
                                    isDisabled={!agreeToTerms}
                                    width="full"
                                    boxShadow="md"
                                    _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                    transition="all 0.2s"
                                >
                                    Support Our Initiative
                                </Button>
                            </VStack>
                        ) : (
                            <Button
                                colorScheme="yellow"
                                size="lg"
                                onClick={() => navigate('/login', { state: { from: location } })}
                                width="full"
                                boxShadow="md"
                                _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                transition="all 0.2s"
                            >
                                Login to Support
                            </Button>
                        )}
                    </Box>

                    {/* Final Call to Action */}
                    <Box textAlign="center" mt={8}>
                        <Button
                            colorScheme="yellow"
                            size="lg"
                            onClick={handleSupport}
                            isDisabled={!user || !agreeToTerms}
                            width="50%"
                            boxShadow="md"
                            _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                            transition="all 0.2s"
                        >
                            Support Golden Hill Community Butcher Now
                        </Button>
                    </Box>
                </VStack>
            </Container>

            {/* Payment Status Modal */}
            <PaymentStatusModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                invoiceId={invoiceId}
                invoiceStatus={invoiceStatus}
                checkoutLink={checkoutLink}
                amount={invoiceData?.getBTCPayInvoice.amount}
                currency={invoiceData?.getBTCPayInvoice.currency}
            />

            <FooterWithFourColumns />
        </Box>
    );
};

export default GoldenHillCommunityButcher;
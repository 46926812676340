// contexts/AuthContext.tsx

import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { Magic } from 'magic-sdk';
import { User } from '../types';
import { getApiUrl } from '../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { ApolloClient, InMemoryCache, gql, createHttpLink } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';

const magic = new Magic('pk_live_9B9AD495258E9C9F');

const httpLink = createHttpLink({
    uri: getApiUrl(),
});

// Create the auth link
const authLink = setContext((_, { headers }) => {
    // Get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // Return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

// Create the error link
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

// Create the Apollo Client
export const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache()
});

const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      email
      firstName
      lastName
      phoneNumber
      issuer
      publicAddress
      isMfaEnabled
      addressETH
      permissions
      membershipLevel
      inviteCode
    }
  }
`;

const CREATE_USER = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      id
      email
      firstName
      lastName
      phoneNumber
      issuer
      publicAddress
      isMfaEnabled
      addressETH
      permissions
      membershipLevel
      inviteCode
    }
  }
`;

interface AuthContextProps {
    user: User | null;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    login: (email: string) => Promise<'success' | 'incomplete_profile'>;
    loginWithSMS: (phoneNumber: string) => Promise<'success' | 'incomplete_profile'>;
    logout: () => Promise<void>;
    isLoading: boolean;
    updateUserData: (updatedUser: Partial<User>) => void; // Add this line
    fetchUserData: (email: string, pendingInviteCode?: string, phoneNumber?: string) => Promise<User | null>;

}

export const AuthContext = createContext<AuthContextProps>({
    user: null,
    setUser: () => { },
    login: async () => 'success',
    loginWithSMS: async () => 'success',
    logout: async () => { },
    isLoading: false,
    updateUserData: () => { },
    fetchUserData: async () => null
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    /**
 * Fetches user data from the server or creates a new user if one doesn't exist.
 * This function is crucial for maintaining user state after authentication.
 * 
 * @param email - The email address of the user to fetch or create
 */
    const fetchUserData = async (email: string, pendingInviteCode?: string, phoneNumber?: string): Promise<User | null> => {
        try {
            const { data } = await client.query<{ getUserByEmail: User }>({
                query: GET_USER_BY_EMAIL,
                variables: { email },
            });

            if (data && data.getUserByEmail) {
                setUser(data.getUserByEmail);
                console.log('User data fetched:', data.getUserByEmail);
                console.log('todo: replace with getUserById instead of email:', email);

                localStorage.setItem('userEmail', email);
                localStorage.setItem('isLoggedIn', 'true');
                return data.getUserByEmail;
            } else {
                const { data: createData } = await client.mutate<{ createUser: User }>({
                    mutation: CREATE_USER,
                    variables: {
                        input: {
                            email,
                            phoneNumber,
                            inviteCode: pendingInviteCode || undefined
                        }
                    },
                });

                if (createData && createData.createUser) {
                    setUser(createData.createUser);
                    localStorage.setItem('userEmail', email);
                    localStorage.setItem('isLoggedIn', 'true');
                    console.log('New user created:', createData.createUser);
                    return createData.createUser;
                }
            }
            return null;
        } catch (error) {
            console.error('Error fetching/creating user data:', error);
            return null;
        }
    };
    const updateUserData = (updatedUser: Partial<User>) => {
        setUser(currentUser => currentUser ? { ...currentUser, ...updatedUser } : null);
    };

    const login = async (email: string): Promise<'success' | 'incomplete_profile'> => {
        setIsLoading(true);
        try {
            await magic.auth.loginWithMagicLink({ email });
            const metadata = await magic.user.getMetadata();
            localStorage.setItem('userMetadata', JSON.stringify(metadata));
            localStorage.setItem('userEmail', email);
            localStorage.setItem('isLoggedIn', 'true');

            const pendingInviteCode = localStorage.getItem('pendingInviteCode') || undefined;
            const userData = await fetchUserData(email, pendingInviteCode);

            if (pendingInviteCode) {
                localStorage.removeItem('pendingInviteCode');
            }

            // Check if user data exists and if first name and last name are missing
            if (!userData || !userData.firstName || !userData.lastName) {
                return 'incomplete_profile';
            }

            return 'success';
        } catch (error) {
            console.error('Login failed', error);
            throw error;
        } finally {
            setIsLoading(false);
        }
    };


    const loginWithSMS = async (phoneNumber: string): Promise<'success' | 'incomplete_profile'> => {
        setIsLoading(true);
        try {
            const did = await magic.auth.loginWithSMS({ phoneNumber });
            console.log(`DID Token: ${did}`);

            const userInfo = await magic.user.getInfo();
            console.log(`UserInfo: ${JSON.stringify(userInfo)}`);

            const email = `${phoneNumber}@placeholder.com`;
            localStorage.setItem('userMetadata', JSON.stringify(userInfo));
            localStorage.setItem('userEmail', email);
            localStorage.setItem('isLoggedIn', 'true');

            const pendingInviteCode = localStorage.getItem('pendingInviteCode') || undefined;
            const userData = await fetchUserData(email, pendingInviteCode, phoneNumber);

            if (pendingInviteCode) {
                localStorage.removeItem('pendingInviteCode');
            }

            if (!userData || !userData.firstName || !userData.lastName) {
                return 'incomplete_profile';
            }

            return 'success';
        } catch (error) {
            console.error('SMS Login failed', error);
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const logout = async () => {
        setIsLoading(true);
        try {
            await magic.user.logout();
            setUser(null);
            localStorage.removeItem('userMetadata');
            localStorage.removeItem('userEmail');
            localStorage.removeItem('isLoggedIn');
            navigate('/login');
        } catch (error) {
            console.error('Logout failed', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const initAuth = async () => {
            const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
            const cachedEmail = localStorage.getItem('userEmail');
            const cachedMetadata = localStorage.getItem('userMetadata');

            if (isLoggedIn && cachedEmail && cachedMetadata) {
                await fetchUserData(cachedEmail);
            }

            setIsLoading(false);
        };

        initAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ user, setUser, login, loginWithSMS, logout, isLoading, updateUserData, fetchUserData }}>
            {children}
        </AuthContext.Provider>
    );
};
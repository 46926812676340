import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box, Button, Container, Heading, Text, VStack, List, ListItem, ListIcon,
    useToast, Checkbox, Center, Flex, Image, Progress,
    HStack,
    useColorModeValue,
    WrapItem,
    Badge,
    Wrap,
    Grid,
} from '@chakra-ui/react';

import { CheckCircleIcon, SunIcon, MoonIcon, StarIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { PaymentStatusModal } from '../components/PaymentStatusModal';
import { useAuth } from '../contexts/AuthContext';
import { useInviteCode } from '../hooks/useInviteCode';
import { useMutation, useQuery, gql } from '@apollo/client';

const CREATE_BTCPAY_INVOICE = gql`
    mutation CreateBTCPayInvoice($amount: Float!, $currency: String!, $userId: String!, $mongodbProductId: ID!) {
        createBTCPayInvoice(amount: $amount, currency: $currency, userId: $userId, mongodbProductId: $mongodbProductId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const GET_BTCPAY_INVOICE = gql`
    query GetBTCPayInvoice($invoiceId: String!) {
        getBTCPayInvoice(invoiceId: $invoiceId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const SuccessPrinciplesMasteryCourse = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [checkoutLink, setCheckoutLink] = useState('');
    const [invoiceStatus, setInvoiceStatus] = useState('');
    const [paymentComplete, setPaymentComplete] = useState(false);

    const bgColor = useColorModeValue('yellow.50', 'yellow.900');
    const cardBgColor = useColorModeValue('white', 'yellow.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const accentColor = useColorModeValue('yellow.500', 'yellow.300');
    const borderColor = useColorModeValue('yellow.200', 'yellow.600');
    const anotherColor = useColorModeValue('yellow.100', 'yellow.700');

    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const { user, isLoading } = useAuth();
    useInviteCode();

    const [createBTCPayInvoice] = useMutation(CREATE_BTCPAY_INVOICE);
    const { data: invoiceData, startPolling, stopPolling } = useQuery(GET_BTCPAY_INVOICE, {
        variables: { invoiceId },
        skip: !invoiceId,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (invoiceId) {
            startPolling(1000);
        }
        return () => stopPolling();
    }, [invoiceId, startPolling, stopPolling]);

    useEffect(() => {
        if (invoiceData) {
            const status = invoiceData.getBTCPayInvoice.status;
            setInvoiceStatus(status);
            if (['Paid', 'Confirmed', 'Settled'].includes(status)) {
                setPaymentComplete(true);
                toast({
                    title: "Payment Completed",
                    description: "Your enrollment has been successfully processed.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                stopPolling();
            }
        }
    }, [invoiceData, stopPolling, toast]);

    useEffect(() => {
        if (!user) {
            localStorage.setItem('startingProductURL', '/mystery-mastery-course');
        }
    }, [user]);

    const handleEnrollment = async () => {
        if (!agreeToTerms) {
            toast({
                title: "Agreement Required",
                description: "Please agree to the terms before enrolling.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (user) {
            try {
                console.log('Processing enrollment for user:', user);
                const { data } = await createBTCPayInvoice({
                    variables: {
                        // amount: 0.00940853,
                        amount: 0.00001000,

                        currency: "BTC",
                        userId: user.id,
                        mongodbProductId: "66d548583bfa3a727704ef86"
                    },
                });
                console.log('BTCPay invoice created:', data);
                setInvoiceId(data.createBTCPayInvoice.id);
                setCheckoutLink(data.createBTCPayInvoice.checkoutLink);
                setInvoiceStatus(data.createBTCPayInvoice.status);
                setIsModalOpen(true);
            } catch (error: any) {
                console.error('Error creating BTCPay invoice:', error);
                toast({
                    title: "Error",
                    description: `Failed to process enrollment: ${error.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            navigate('/login', { state: { from: location } });
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        if (paymentComplete) {
            toast({
                title: "Enrollment Confirmed",
                description: "Welcome to the Mystery Mastery Course! Your journey begins now.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Enrollment Pending",
                description: "Complete your payment to secure your spot in the course.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    return (
        <Box bg={bgColor} minHeight="100vh">
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    <Box
                        borderRadius="xl"
                        overflow="hidden"
                        boxShadow="2xl"
                        bg={cardBgColor}
                        position="relative"
                    >
                        <Image
                            src="/product_images/success-principles-mastery.jpg"
                            alt="Success Principles Mastery Course"
                            width="100%"
                            height="300px"
                            objectFit="cover"
                            objectPosition="center"
                        />
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            right="0"
                            bottom="0"
                            bg="rgba(0,0,0,0.4)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            textAlign="center"
                            p={8}
                        >
                            <Heading as="h1" size="2xl" color="white" mb={4}>
                                Success Principles Mastery
                            </Heading>
                            <Text fontSize="xl" color="white">
                                Unlock the Secrets of Personal and Professional Success
                            </Text>
                        </Box>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Text fontSize="xl" lineHeight="tall">
                            Embark on a transformative journey with our Success Principles Mastery Course. Over three intensive days, you'll discover the timeless wisdom from "Think and Grow Rich" and learn how to apply these principles to achieve unprecedented success in your personal and professional life.
                        </Text>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Course Overview</Heading>
                        <Text mb={6}>
                            This exclusive course is designed to take you on a journey of self-discovery and empowerment. Through a series of carefully crafted exercises, discussions, and experiences, you'll master:
                        </Text>
                        <Grid
                            templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]}
                            gap={6}
                        >
                            {[
                                { icon: SunIcon, text: "The power of desire and goal setting" },
                                { icon: MoonIcon, text: "Faith and auto-suggestion techniques" },
                                { icon: StarIcon, text: "Specialized knowledge acquisition" },
                                { icon: CheckCircleIcon, text: "Decision-making and persistence" }
                            ].map((item, index) => (
                                <Box
                                    key={index}
                                    p={4}
                                    borderRadius="md"
                                    bg={bgColor}
                                    boxShadow="md"
                                >
                                    <HStack spacing={4} align="center">
                                        <Box
                                            as={item.icon}
                                            fontSize="2xl"
                                            color={accentColor}
                                        />
                                        <Text
                                            fontWeight="bold"
                                            fontSize={["sm", "md"]}
                                        >
                                            {item.text}
                                        </Text>
                                    </HStack>
                                </Box>
                            ))}
                        </Grid>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Course Timeline</Heading>
                        <VStack spacing={8} align="stretch">
                            {[
                                { day: "Day 1", title: "Desire and Faith", time: "9:00 AM - 5:00 PM", description: "Develop SMART goals, practice positive mindset meditation, and strengthen your faith through auto-suggestion techniques." },
                                { day: "Day 2", title: "Knowledge and Planning", time: "9:00 AM - 5:00 PM", description: "Identify your specialized knowledge, create strategic plans, and master the art of decision-making." },
                                { day: "Day 3", title: "Persistence and Transmutation", time: "9:00 AM - 5:00 PM", description: "Cultivate persistence through mindfulness, learn energy transmutation techniques, and create your personal success roadmap." }
                            ].map((item, index) => (
                                <Box
                                    key={index}
                                    borderLeft="4px solid"
                                    borderColor={accentColor}
                                    pl={4}
                                    py={2}
                                >
                                    <Heading size="md">{item.day}: {item.title}</Heading>
                                    <Text fontSize="sm" color={accentColor} mt={1}>{item.time}</Text>
                                    <Text mt={2}>{item.description}</Text>
                                </Box>
                            ))}
                        </VStack>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Why Join the Success Principles Mastery Course?</Heading>
                        <VStack spacing={4} align="stretch">
                            {[
                                "Master timeless success principles from 'Think and Grow Rich'",
                                "Develop a success-oriented mindset and unshakeable faith",
                                "Learn practical techniques for goal achievement and decision-making",
                                "Connect with like-minded individuals on a journey of self-improvement",
                                "Receive a certificate of completion and ongoing support post-course"
                            ].map((item, index) => (
                                <Box
                                    key={index}
                                    p={3}
                                    borderRadius="md"
                                    bg={anotherColor}
                                    color={textColor}
                                >
                                    <HStack alignItems="flex-start" spacing={3}>
                                        <Box as={CheckCircleIcon} mt={1} color={accentColor} />
                                        <Text fontSize={["sm", "md"]}>{item}</Text>
                                    </HStack>
                                </Box>
                            ))}
                        </VStack>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Enrollment Details</Heading>
                        <Text fontSize="lg" mb={6}>
                            Secure your spot in this life-changing course for just $800 AUD (0.00940853 BTC).
                        </Text>
                        {user ? (
                            <VStack spacing={4} align="stretch">
                                <Checkbox
                                    isChecked={agreeToTerms}
                                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                                    colorScheme="yellow"
                                >
                                    I agree to the course terms and conditions
                                </Checkbox>
                                <Button
                                    colorScheme="yellow"
                                    size="lg"
                                    onClick={handleEnrollment}
                                    isDisabled={!agreeToTerms}
                                    width="full"
                                    boxShadow="md"
                                    _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                    transition="all 0.2s"
                                >
                                    Enroll Now
                                </Button>
                            </VStack>
                        ) : (
                            <Button
                                colorScheme="yellow"
                                size="lg"
                                onClick={() => navigate('/login', { state: { from: location } })}
                                width="full"
                                boxShadow="md"
                                _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                transition="all 0.2s"
                            >
                                Login to Enroll
                            </Button>
                        )}
                    </Box>
                </VStack>
            </Container>

            <PaymentStatusModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                invoiceId={invoiceId}
                invoiceStatus={invoiceStatus}
                checkoutLink={checkoutLink}
                amount={invoiceData?.getBTCPayInvoice.amount}
                currency={invoiceData?.getBTCPayInvoice.currency}
            />

            <FooterWithFourColumns />
        </Box>
    );
};

export default SuccessPrinciplesMasteryCourse;
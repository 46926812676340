import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box, Button, Container, Heading, Text, VStack, List, ListItem, ListIcon,
    useToast, Checkbox, Center, Flex, Image, Progress,
    HStack, useColorModeValue, WrapItem, Badge, Wrap, Grid,
    Spacer,
} from '@chakra-ui/react';
import { CheckCircleIcon, PhoneIcon, CalendarIcon, TimeIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { PaymentStatusModal } from '../components/PaymentStatusModal';
import { useAuth } from '../contexts/AuthContext';
import { useInviteCode } from '../hooks/useInviteCode';
import { useMutation, useQuery, gql } from '@apollo/client';

import CountdownTimer from '../components/CountdownTimer';
import SMSNotificationSignup from '../components/SMSNotificationSignup';

import { getBitcoinPrice, calculateBTCAmount } from '../utils/helpers';

const CREATE_BTCPAY_INVOICE = gql`
    mutation CreateBTCPayInvoice($amount: Float!, $currency: String!, $userId: String!, $mongodbProductId: ID!) {
        createBTCPayInvoice(amount: $amount, currency: $currency, userId: $userId, mongodbProductId: $mongodbProductId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const GET_BTCPAY_INVOICE = gql`
    query GetBTCPayInvoice($invoiceId: String!) {
        getBTCPayInvoice(invoiceId: $invoiceId) {
            id
            status
            checkoutLink
            amount
            currency
        }
    }
`;

const SmallFruitAndVegBox = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [checkoutLink, setCheckoutLink] = useState('');
    const [invoiceStatus, setInvoiceStatus] = useState('');
    const [paymentComplete, setPaymentComplete] = useState(false);

    const bgColor = useColorModeValue('green.50', 'green.900');
    const cardBgColor = useColorModeValue('white', 'green.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const accentColor = useColorModeValue('green.500', 'green.300');
    const borderColor = useColorModeValue('green.200', 'green.600');
    const green = useColorModeValue('green.100', 'green.700');

    const nextDeliveryDate = new Date();
    nextDeliveryDate.setDate(nextDeliveryDate.getDate() + (4 - nextDeliveryDate.getDay() + 7) % 7);

    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const { user, isLoading } = useAuth();
    useInviteCode();

    const [btcPrice, setBtcPrice] = useState<number>(0);
    const [btcAmount, setBtcAmount] = useState<number>(0);
    const AUD_PRICE = 0.5; // Set the AUD price here

    const [createBTCPayInvoice] = useMutation(CREATE_BTCPAY_INVOICE);
    const { data: invoiceData, startPolling, stopPolling } = useQuery(GET_BTCPAY_INVOICE, {
        variables: { invoiceId },
        skip: !invoiceId,
        fetchPolicy: 'network-only',
    });


    useEffect(() => {
        const fetchBtcPrice = async () => {
            const price = await getBitcoinPrice();
            setBtcPrice(price);
            const btcAmount = calculateBTCAmount(AUD_PRICE, price);
            setBtcAmount(btcAmount);
        };

        fetchBtcPrice();
        const interval = setInterval(fetchBtcPrice, 60000); // Update price every minute

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (!user) {
            localStorage.setItem('startingProductURL', '/small-fruit-and-veg-box');
        }
    }, [user]);

    useEffect(() => {
        if (invoiceId) {
            startPolling(1000);
        }
        return () => stopPolling();
    }, [invoiceId, startPolling, stopPolling]);

    useEffect(() => {
        if (invoiceData) {
            const status = invoiceData.getBTCPayInvoice.status;
            setInvoiceStatus(status);
            if (['Paid', 'Confirmed', 'Settled'].includes(status)) {
                setPaymentComplete(true);
                toast({
                    title: "Payment Completed",
                    description: "Your order has been successfully processed.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                stopPolling();
            }
        }
    }, [invoiceData, stopPolling, toast]);

    const handleOrder = async () => {
        if (!agreeToTerms) {
            toast({
                title: "Agreement Required",
                description: "Please agree to the terms before placing your order.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (user) {
            try {
                const { data } = await createBTCPayInvoice({
                    variables: {
                        amount: btcAmount, // 35 AUD worth of BTC (approximate)
                        currency: "BTC",
                        userId: user.id,
                        mongodbProductId: "66d55ff5efac7e4b360c2543"
                    },
                });
                setInvoiceId(data.createBTCPayInvoice.id);
                setCheckoutLink(data.createBTCPayInvoice.checkoutLink);
                setInvoiceStatus(data.createBTCPayInvoice.status);
                setIsModalOpen(true);
            } catch (error: any) {
                console.error('Error creating BTCPay invoice:', error);
                toast({
                    title: "Error",
                    description: `Failed to process order: ${error.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            // navigate('/login', { state: { from: location } });
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        if (paymentComplete) {
            toast({
                title: "Order Confirmed",
                description: "Thank you for your order! Your fresh produce will be on its way soon.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Order Pending",
                description: "Complete your payment to secure your fresh produce box.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    return (
        <Box bg={bgColor} minHeight="100vh">
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={12}>
                <VStack spacing={12} align="stretch">
                    <Box
                        borderRadius="xl"
                        overflow="hidden"
                        boxShadow="2xl"
                        bg={cardBgColor}
                        position="relative"
                    >
                        <Image
                            src="/product_images/smallfruitandveg.png"
                            alt="Small Fruit & Veg Box"
                            width="100%"
                            height="300px"
                            objectFit="cover"
                            objectPosition="center"
                        />
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            right="0"
                            bottom="0"
                            bg="rgba(0,0,0,0.4)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            textAlign="center"
                            p={8}
                        >
                            <Heading as="h1" size="2xl" color="white" mb={4}>
                                Small Fruit & Veg Box
                            </Heading>
                            <Text fontSize="xl" color="white">
                                Fresh, Local, and Bursting with Flavor!
                            </Text>
                        </Box>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Text fontSize="xl" lineHeight="tall">
                            Dive into a world of vibrant flavors and nourishing goodness with our Small Fruit & Veg Box! Packed with 7-8 kgs of nature's finest offerings, this box is your ticket to a week of delicious, healthy meals. Perfect for couples or small families, our curated selection brings the farmer's market right to your doorstep.
                        </Text>
                        <Text fontSize="lg" mt={4} fontStyle="italic">
                            "Eat fresh, eat local, and get to know the hands that feed you!"
                        </Text>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={6}
                        bg={accentColor}
                        color="white"
                        textAlign="center"
                        boxShadow="xl"
                    >
                        <Heading as="h2" size="xl" mb={2}>
                            Next Delivery Date
                        </Heading>
                        <Text fontSize="2xl" fontWeight="bold">
                            {nextDeliveryDate.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}
                        </Text>
                        <Text fontSize="lg" mt={2}>
                            Order by Monday for Thursday delivery!
                        </Text>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>What's in the Box?</Heading>
                        <Text mb={4}>
                            Each week brings a new adventure in fresh produce! While contents may vary based on seasonal availability, here's a taste of what you might find in your Small Fruit & Veg Box:
                        </Text>
                        <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} gap={6}>
                            {[
                                "Crisp Lettuce", "Juicy Tomatoes", "Crunchy Cucumber", "Vibrant Rainbow Chard",
                                "Sweet Corn", "Hearty Butternut Pumpkin", "Colorful Heirloom Carrots", "Versatile Zucchini",
                                "Creamy Eggplant", "Fresh Bananas", "Succulent Nectarines", "Zesty Oranges",
                                "Aromatic Onions", "Earthy Potatoes"
                            ].map((item, index) => (
                                <Box
                                    key={index}
                                    p={4}
                                    borderRadius="md"
                                    bg={bgColor}
                                    boxShadow="md"
                                >
                                    <HStack spacing={4} align="center">
                                        <CheckCircleIcon color={accentColor} />
                                        <Text fontWeight="bold" fontSize={["sm", "md"]}>
                                            {item}
                                        </Text>
                                    </HStack>
                                </Box>
                            ))}
                        </Grid>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Why Choose Our Small Fruit & Veg Box?</Heading>
                        <VStack spacing={4} align="stretch">
                            {[
                                "Support local farmers and boost community resilience",
                                "Enjoy fresh, seasonal produce at its peak flavor",
                                "Reduce food miles and support sustainable agriculture",
                                "Discover new fruits and vegetables to expand your palate",
                                "Save time with convenient home delivery",
                                "Perfect portion for 2-3 people, minimizing food waste"
                            ].map((item, index) => (
                                <Box
                                    key={index}
                                    p={3}
                                    borderRadius="md"
                                    bg={green}
                                    color={textColor}
                                >
                                    <HStack alignItems="flex-start" spacing={3}>
                                        <Box as={CheckCircleIcon} mt={1} color={accentColor} />
                                        <Text fontSize={["sm", "md"]}>{item}</Text>
                                    </HStack>
                                </Box>
                            ))}
                        </VStack>
                    </Box>

                    <Box
                        borderRadius="lg"
                        p={8}
                        bg={cardBgColor}
                        boxShadow="xl"
                        color={textColor}
                    >
                        <Heading as="h2" size="xl" mb={6}>Order Details</Heading>
                        <HStack mb={4}>
                            <Box as={CalendarIcon} color={accentColor} />
                            <Text fontWeight="bold">Order by:</Text>
                            <Text>Every Monday for Thursday delivery</Text>
                        </HStack>
                        <HStack mb={4}>
                            <Box as={TimeIcon} color={accentColor} />
                            <Text fontWeight="bold">Delivery:</Text>
                            <Text>Every Thursday</Text>
                        </HStack>
                        <HStack mb={4}>
                            <Box as={PhoneIcon} color={accentColor} />
                            <Text fontWeight="bold">Questions?</Text>
                            <Text>Call Tom at 0458 944 327</Text>
                        </HStack>
                        <Text fontSize="lg" mb={4}>
                            Secure your box of fresh, local goodness for just ${AUD_PRICE} AUD ({btcAmount.toFixed(8)} BTC).
                        </Text>
                        <Text fontSize="sm" mb={2}>
                            Current BTC Price: 1 BTC = ${btcPrice.toFixed(2)} AUD
                        </Text>
                        <VStack align="start" spacing={1} fontSize="sm" mb={4}>
                            <Text fontWeight="bold">Calculation Breakdown:</Text>
                            <Text>1 BTC = ${btcPrice.toFixed(2)} AUD</Text>
                            <Text>1 AUD = {(1 / btcPrice).toFixed(8)} BTC</Text>
                            <Text>${AUD_PRICE} AUD = {AUD_PRICE} × {(1 / btcPrice).toFixed(8)} BTC</Text>
                            <Text fontWeight="bold">
                                ${AUD_PRICE} AUD = {btcAmount.toFixed(8)} BTC
                            </Text>
                        </VStack>
                        <Text fontSize="xs" fontStyle="italic" mb={4}>
                            (Price updates every minute to ensure accuracy)
                        </Text>
                        <Text fontSize="md" mb={6}>
                            Join us in building a resilient community, one delicious bite at a time!
                        </Text>
                        {user ? (
                            <VStack spacing={4} align="stretch">
                                <Checkbox
                                    isChecked={agreeToTerms}
                                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                                    colorScheme="green"
                                >
                                    I agree to the order terms and conditions
                                </Checkbox>
                                <Button
                                    colorScheme="green"
                                    size="lg"
                                    onClick={handleOrder}
                                    isDisabled={!agreeToTerms}
                                    width="full"
                                    boxShadow="md"
                                    _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                    transition="all 0.2s"
                                >
                                    Order Your Box Now
                                </Button>
                            </VStack>
                        ) : (
                            <Button
                                colorScheme="green"
                                size="lg"
                                onClick={() => navigate('/login', { state: { from: location } })}
                                width="full"
                                boxShadow="md"
                                _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                transition="all 0.2s"
                            >
                                Login to Order
                            </Button>
                        )}
                        <Spacer m={10}></Spacer>
                        <SMSNotificationSignup buttonText="Get SMS Notifications" buttonColorScheme="green" />
                    </Box>
                </VStack>
            </Container>

            <PaymentStatusModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                invoiceId={invoiceId}
                invoiceStatus={invoiceStatus}
                checkoutLink={checkoutLink}
                amount={invoiceData?.getBTCPayInvoice.amount}
                currency={invoiceData?.getBTCPayInvoice.currency}
            />

            <FooterWithFourColumns />
        </Box>
    );
};

export default SmallFruitAndVegBox;
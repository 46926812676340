import { Container, Flex, FlexProps } from '@chakra-ui/react'
import { useAuth } from '../../../contexts/AuthContext';

export const Main = (props: FlexProps) => {
  const { user, logout } = useAuth();
  return (
    <Flex as="main" role="main" direction="column" flex="1" py="16" {...props}>
      <Container >
      

      </Container>
      <Container flex="1">
        {user ? (
          <div>
            <p>Welcome, {user.email}!</p>
            <button onClick={logout}>Logout</button>
          </div>
        ) : (
          <p>Please login to access your account.</p>
        )}
      </Container>
    </Flex>
  )
}

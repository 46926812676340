// src/pages/MyBTCPurchases.tsx
import React from 'react';
import { useQuery, useMutation, ApolloQueryResult } from '@apollo/client';
import { gql } from '@apollo/client';
import {
    Box,
    Container,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Center,
    Text,
    useColorModeValue,
    Badge,
    TableCaption,
    TableContainer,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,

} from '@chakra-ui/react';
import { BuyBitcoinPurchaseSchema } from '../types';
import { useAuth } from '../contexts/AuthContext';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const GET_BUY_BITCOIN_PURCHASES_BY_USER = gql`
    query GetBuyBitcoinPurchasesByUser($userEmail: String!) {
        getBuyBitcoinPurchasesByUser(userEmail: $userEmail) {
            id
            amountAUD
            amountBTC
            status
            createdAt
            updatedAt
            walletAddress
            bsb
            accountNumber
            paymentReference



        }
    }
`;


const UPDATE_BUY_BITCOIN_PURCHASE_STATUS = gql`
    mutation UpdateBuyBitcoinPurchaseStatus($id: ID!, $status: BuyBitcoinPurchaseStatus!) {
        updateBuyBitcoinPurchaseStatus(id: $id, status: $status) {
            id
            status
        }
    }
`;

export const MyBTCPurchases = () => {


    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedPurchase, setSelectedPurchase] = React.useState<BuyBitcoinPurchaseSchema | null>(null);

    const handleViewPaymentDetails = (purchase: BuyBitcoinPurchaseSchema) => {
        setSelectedPurchase(purchase);
        onOpen();
    };

    const toast = useToast();

    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const { user } = useAuth();
    const { loading, error, data, refetch } = useQuery(GET_BUY_BITCOIN_PURCHASES_BY_USER, {
        variables: { userEmail: user?.email },
        skip: !user?.email,
    });
    const [updateStatus] = useMutation(UPDATE_BUY_BITCOIN_PURCHASE_STATUS);














    const handleMarkAsPaid = async (id: string) => {
        try {
            await updateStatus({
                variables: { id, status: "USER_MARKED_AS_PAID" },
            });
            await refetch();
            toast({
                title: "Status updated",
                description: "Your purchase has been marked as paid.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update the purchase status.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };





    if (loading) {
        return (
            <Center height="100vh">
                <Spinner size="xl" />
            </Center>
        );
    }

    if (error) {
        return (
            <Center height="100vh">
                <Text>Error: {error.message}</Text>
            </Center>
        );
    }

    const purchases = data?.getBuyBitcoinPurchasesByUser || [];

    return (
        <>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={8}>
                <Box
                    bg={bgColor}
                    boxShadow="lg"
                    borderRadius="lg"
                    p={6}
                    borderWidth={1}
                    borderColor={borderColor}
                >
                    <Heading as="h2" size="lg" mb={6}>
                        My Starter Pack Purchases
                    </Heading>
                    {purchases.length > 0 ? (
                        <TableContainer>
                            <Table variant="simple" size="sm">
                                <TableCaption>Your Starter Pack purchase history</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th>Amount AUD</Th>
                                        <Th>Amount BTC</Th>
                                        <Th>Status</Th>
                                        <Th>Created At</Th>
                                        <Th>Updated At</Th>
                                        {/* <Th>Wallet Address</Th> */}
                                        <Th>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {purchases.map((purchase: BuyBitcoinPurchaseSchema) => {
                                        const createdDate = new Date(parseInt(purchase.createdAt)).toLocaleString();
                                        const updatedDate = new Date(parseInt(purchase.updatedAt)).toLocaleString();
                                        return (
                                            <Tr key={purchase.id} bg={purchase.status === 'PENDING' ? 'yellow.100' : undefined}>
                                                <Td>${purchase.amountAUD.toFixed(2)}</Td>
                                                <Td>{purchase.amountBTC.toFixed(8)}</Td>
                                                <Td>
                                                    <Badge colorScheme={purchase.status === 'COMPLETED' ? 'green' : 'yellow'}>
                                                        {purchase.status}
                                                    </Badge>
                                                </Td>
                                                <Td>{createdDate}</Td>
                                                <Td>{updatedDate}</Td>
                                                {/* <Td>{purchase.walletAddress}</Td> */}
                                                <Td>
                                                    <Button
                                                        size="sm"
                                                        colorScheme="teal"
                                                        onClick={() => handleViewPaymentDetails(purchase)}
                                                        mr={2}
                                                    >
                                                        View Payment Details
                                                    </Button>
                                                    {purchase.status === 'PENDING' && (
                                                        <Button
                                                            size="sm"
                                                            colorScheme="blue"
                                                            onClick={() => handleMarkAsPaid(purchase.id)}
                                                        >
                                                            Mark as Paid
                                                        </Button>
                                                    )}
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Text>No purchases found.</Text>
                    )}
                </Box>
            </Container>


            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Payment Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedPurchase && (
                            <> <Text><strong>Account Name:</strong> TM </Text>
                                <Text><strong>BSB:</strong> {selectedPurchase.bsb}</Text>
                                <Text><strong>Account Number:</strong> {selectedPurchase.accountNumber}</Text>
                                <Text><strong>Payment Reference:</strong> {selectedPurchase.paymentReference}</Text>
                                <Text><strong>Amount:</strong> ${selectedPurchase.amountAUD.toFixed(2)} AUD</Text>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            <FooterWithFourColumns />
        </>
    );
};

export default MyBTCPurchases;
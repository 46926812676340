import React from 'react';
import { useQuery, gql } from '@apollo/client';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Heading,
    Text,
    Spinner,
    Stat,
    StatGroup,
    StatLabel,
    StatNumber,
    StatHelpText,
    Badge,
    Container,
    HStack,
    useColorModeValue,
    VStack,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Adjust the import path as needed

// import {User} from '../types';


// Define types for our data
interface User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    issuer: string;
    publicAddress: string;
    isMfaEnabled: boolean;
    addressETH: string;
    inviteCode: string;
    membershipLevel: string;
    invitedBy: {
        id: string;
        email: string;
    } | null;
    createdAt: string; // Add this of type  2024-08-15T01:28:47.881Z
}



interface InvitationStats {
    [key: string]: {
        invitees: string[];
        count: number;
    };
}

interface InvitationStatsById {
    [key: string]: {
        count: number;
        email: string;
    };
}



interface SummaryStats {
    totalUsers: number;
    totalInvites: number;
    usersWithMfa: number;
    mfaPercentage: string;
    usersLast24Hours: number;
    usersLast7Days: number;
    usersLast30Days: number;
    growthRate7Days: string;
    growthRate30Days: string;
    usersPerWeek: { [key: string]: number };
    usersPerMonth: { [key: string]: number };
}



const GET_ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers {
      id
      email
      firstName
      lastName
      phoneNumber
      issuer
      publicAddress
      isMfaEnabled
      addressETH
      inviteCode
      membershipLevel
      invitedBy {
        id
        email
      }
      createdAt
      
    }
  }
`;

// Helper function to get week number
function getWeekNumber(d: Date): number {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
}



const getCurrentWeekAndMonth = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentWeek = `${currentYear}-W${getWeekNumber(now).toString().padStart(2, '0')}`;
    const currentMonth = `${currentYear}-${(now.getMonth() + 1).toString().padStart(2, '0')}`;
    return { currentWeek, currentMonth };
};

const AllUsers = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { loading, error, data } = useQuery<{ getAllUsers: User[] }>(GET_ALL_USERS);

    const { currentWeek, currentMonth } = getCurrentWeekAndMonth();

    // Move all useColorModeValue calls here
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const pageBgColor = useColorModeValue('gray.50', 'gray.900');
    const insightsBgColor = useColorModeValue('gray.50', 'gray.700');

    useEffect(() => {
        if (!user || !user.permissions?.includes('admin')) {
            navigate('/');
        }
    }, [user, navigate]);


    const { invitationStats, invitationStatsById, summaryStats } = useMemo(() => {
        if (!data || !data.getAllUsers) return { invitationStats: {}, invitationStatsById: {}, summaryStats: {} as SummaryStats };

        const stats: InvitationStats = {};
        const statsById: InvitationStatsById = {};
        let totalUsers = 0;
        let totalInvites = 0;
        let usersWithMfa = 0;
        let usersLast24Hours = 0;
        let usersLast7Days = 0;
        let usersLast30Days = 0;
        let latestUser: User | null = null;

        const now = new Date();
        const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);


        const usersPerWeek: { [key: string]: number } = {};
        const usersPerMonth: { [key: string]: number } = {};
        const currentYear = new Date().getFullYear();

        // Initialize all weeks and months for the current year
        for (let week = 1; week <= 52; week++) {
            usersPerWeek[`${currentYear}-W${week.toString().padStart(2, '0')}`] = 0;
        }
        for (let month = 1; month <= 12; month++) {
            usersPerMonth[`${currentYear}-${month.toString().padStart(2, '0')}`] = 0;
        }


        data.getAllUsers.forEach((user: User) => {
            console.log('Processing user:', user);

            totalUsers++;
            if (user.isMfaEnabled) usersWithMfa++;

            console.log(`User ${user.email}: MFA Enabled: ${user.isMfaEnabled}`);

            const createdDate = new Date(parseInt(user.createdAt));
            console.log(`User ${user.email} created at: ${createdDate.toLocaleString()}`);

            if (createdDate >= oneDayAgo) usersLast24Hours++;
            if (createdDate >= sevenDaysAgo) usersLast7Days++;
            if (createdDate >= thirtyDaysAgo) usersLast30Days++;

            if (!latestUser || createdDate > new Date(parseInt(latestUser.createdAt))) {
                latestUser = user;
                console.log(`New latest user: ${user.email}, Created At: ${createdDate.toLocaleString()}`);
            }

            if (user.invitedBy) {
                totalInvites++;
                const inviterEmail = user.invitedBy.email;
                const inviterId = user.invitedBy.id;

                console.log(`User ${user.email} was invited by ${inviterEmail} (ID: ${inviterId})`);

                if (!stats[inviterEmail]) {
                    stats[inviterEmail] = { invitees: [], count: 0 };
                    console.log(`Created new stats entry for inviter ${inviterEmail}`);
                }
                stats[inviterEmail].invitees.push(user.email);
                stats[inviterEmail].count += 1;

                if (!statsById[inviterId]) {
                    statsById[inviterId] = { count: 0, email: inviterEmail };
                }
                statsById[inviterId].count += 1;
            }

            if (createdDate.getFullYear() === currentYear) {
                const weekKey = `${currentYear}-W${getWeekNumber(createdDate).toString().padStart(2, '0')}`;
                const monthKey = `${currentYear}-${(createdDate.getMonth() + 1).toString().padStart(2, '0')}`;

                usersPerWeek[weekKey] = (usersPerWeek[weekKey] || 0) + 1;
                usersPerMonth[monthKey] = (usersPerMonth[monthKey] || 0) + 1;
            }

        });

        const growthRate7Days = ((usersLast7Days / totalUsers) * 100).toFixed(2);
        const growthRate30Days = ((usersLast30Days / totalUsers) * 100).toFixed(2);

        const summaryStats: SummaryStats = {
            totalUsers,
            totalInvites,
            usersWithMfa,
            mfaPercentage: (usersWithMfa / totalUsers * 100).toFixed(2),
            usersLast24Hours,
            usersLast7Days,
            usersLast30Days,
            growthRate7Days,
            growthRate30Days,
            usersPerWeek,
            usersPerMonth,
        };

        return { invitationStats: stats, invitationStatsById: statsById, summaryStats };
    }, [data]);





    if (!user || !user.permissions?.includes('admin')) {
        return null;
    }

    if (loading) return (
        <Box>
            <NavbarWithCallToAction />
            <Box maxWidth="1200px" margin="auto" padding={8}>
                <Spinner />
            </Box>
            <FooterWithFourColumns />
        </Box>
    );

    if (error) return (
        <Box>
            <NavbarWithCallToAction />
            <Box maxWidth="1200px" margin="auto" padding={8}>
                <Text>Error: {error.message}</Text>
            </Box>
            <FooterWithFourColumns />
        </Box>
    );

    if (!data || !data.getAllUsers) return (
        <Box>
            <NavbarWithCallToAction />
            <Box maxWidth="1200px" margin="auto" padding={8}>
                <Text>No user data available</Text>
            </Box>
            <FooterWithFourColumns />
        </Box>
    );

    return (
        // Old code before refactoring
        //     <Box>
        //     <NavbarWithCallToAction />


        //     <Box maxWidth="1200px" margin="auto" padding={8}>

        //         <Heading as="h1" size="xl" marginBottom={6}>
        //             User Statistics
        //         </Heading>
        //         <StatGroup>
        //             <Stat>
        //                 <StatLabel>Total Users</StatLabel>
        //                 <StatNumber>{summaryStats.totalUsers || 0}</StatNumber>
        //             </Stat>
        //             <Stat>
        //                 <StatLabel>Total Invites</StatLabel>
        //                 <StatNumber>{summaryStats.totalInvites || 0}</StatNumber>
        //             </Stat>
        //             <Stat>
        //                 <StatLabel>Users with MFA</StatLabel>
        //                 <StatNumber>{summaryStats.usersWithMfa || 0}</StatNumber>
        //                 <Text>({summaryStats.mfaPercentage || '0'}%)</Text>
        //             </Stat>
        //             <Stat>
        //                 <StatLabel>Latest User</StatLabel>
        //                 {/* <StatNumber>{summaryStats.latestUser || 'N/A'}</StatNumber> */}
        //                 {/* <Text>{summaryStats.latestUserDate ? new Date(summaryStats.latestUserDate).toLocaleDateString() : 'N/A'}</Text> */}
        //             </Stat>
        //         </StatGroup>


        //         <Heading as="h2" size="lg" marginTop={8} marginBottom={4}>
        //             User Growth
        //         </Heading>
        //         <StatGroup>
        //             <Stat>
        //                 <StatLabel>New Users (Last 24 Hours)</StatLabel>
        //                 <StatNumber>{summaryStats.usersLast24Hours}</StatNumber>
        //             </Stat>
        //             <Stat>
        //                 <StatLabel>New Users (Last 7 Days)</StatLabel>
        //                 <StatNumber>{summaryStats.usersLast7Days}</StatNumber>
        //                 <StatHelpText>{summaryStats.growthRate7Days}% of total users</StatHelpText>
        //             </Stat>
        //             <Stat>
        //                 <StatLabel>New Users (Last 30 Days)</StatLabel>
        //                 <StatNumber>{summaryStats.usersLast30Days}</StatNumber>
        //                 <StatHelpText>{summaryStats.growthRate30Days}% of total users</StatHelpText>
        //             </Stat>
        //         </StatGroup>

        //         {/* Motivation Section */}
        //         <Box marginTop={8} padding={4} borderWidth={1} borderRadius="lg">
        //             <Heading as="h3" size="md" marginBottom={4}>Platform Growth Insights</Heading>
        //             <Text>
        //                 🚀 Our platform is growing! We've welcomed {summaryStats.usersLast24Hours} new users in the last 24 hours.
        //             </Text>
        //             <Text marginTop={2}>
        //                 📈 In the past week, we've seen a {summaryStats.growthRate7Days}% increase in our user base.
        //             </Text>
        //             <Text marginTop={2}>
        //                 🎉 Keep up the great work! Let's aim to increase our daily sign-ups and engage our new users.
        //             </Text>
        //         </Box>



        //         <TableContainer marginTop={8}>
        //             <Heading as="h2" size="lg" marginBottom={4}>
        //                 Top Inviters by Email
        //             </Heading>
        //             <Table variant="simple">
        //                 <TableCaption>Invitation statistics</TableCaption>
        //                 <Thead>
        //                     <Tr>
        //                         <Th>Inviter Email</Th>
        //                         <Th>Invitees</Th>
        //                         <Th>Total Invitations</Th>
        //                     </Tr>
        //                 </Thead>
        //                 <Tbody>
        //                     {Object.entries(invitationStats).map(([inviterEmail, stats]) => (
        //                         <Tr key={inviterEmail}>
        //                             <Td>{inviterEmail}</Td>
        //                             <Td>{stats.invitees.join(', ')}</Td>
        //                             <Td>{stats.count}</Td>
        //                         </Tr>
        //                     ))}
        //                 </Tbody>
        //             </Table>
        //         </TableContainer>





        //         <TableContainer marginTop={8}>
        //             <Heading as="h2" size="lg" marginBottom={4}>
        //                 Top Inviters by ID
        //             </Heading>
        //             <Table variant="simple">
        //                 <TableCaption>Invitation statistics by ID</TableCaption>
        //                 <Thead>
        //                     <Tr>
        //                         <Th>Inviter ID</Th>
        //                         <Th>Inviter Email</Th>
        //                         <Th>Total Invitations</Th>
        //                     </Tr>
        //                 </Thead>
        //                 <Tbody>
        //                     {Object.entries(invitationStatsById)
        //                         .sort(([, a], [, b]) => b.count - a.count) // Sort by count in descending order
        //                         .map(([inviterId, stats]) => (
        //                             <Tr key={inviterId}>
        //                                 <Td>{inviterId}</Td>
        //                                 <Td>{stats.email}</Td>
        //                                 <Td>{stats.count}</Td>
        //                             </Tr>
        //                         ))}
        //                 </Tbody>
        //             </Table>
        //         </TableContainer>






        //         <Heading as="h1" size="xl" marginBottom={6}>
        //             All Users
        //         </Heading>
        //         <TableContainer>
        //             <Table variant="simple">
        //                 <TableCaption>All users in the system</TableCaption>
        //                 <Thead>
        //                     <Tr>
        //                         <Th>ID</Th>
        //                         <Th>Email</Th>
        //                         <Th>Created At</Th>
        //                         <Th>First Name</Th>
        //                         <Th>Last Name</Th>
        //                         <Th>Phone Number</Th>
        //                         <Th>Issuer</Th>
        //                         <Th>Public Address</Th>
        //                         <Th>MFA Enabled</Th>
        //                         <Th>ETH Address</Th>
        //                         <Th>Invite Code</Th>
        //                         <Th>Membership Level</Th>
        //                         <Th>Invited By</Th>
        //                         <Th>InvitedByMongoDBID</Th>

        //                     </Tr>
        //                 </Thead>
        //                 <Tbody>
        //                     {data.getAllUsers.map((user: User) => {
        //                         console.log(`Rendering user row for: ${user.email}`);
        //                         console.log(`Raw createdAt: ${user.createdAt}`);
        //                         const createdDate = new Date(parseInt(user.createdAt));
        //                         const formattedDate = createdDate.toLocaleString();
        //                         console.log(`Formatted createdAt: ${formattedDate}`);

        //                         return (
        //                             <Tr key={user.id}>
        //                                 <Td>{user.id}</Td>
        //                                 <Td>{user.email}</Td>
        //                                 <Td>{formattedDate}</Td>
        //                                 <Td>{user.firstName}</Td>
        //                                 <Td>{user.lastName}</Td>
        //                                 <Td>{user.phoneNumber}</Td>
        //                                 <Td>{user.issuer}</Td>
        //                                 <Td>{user.publicAddress}</Td>
        //                                 <Td>{user.isMfaEnabled === null ? 'Unknown' : user.isMfaEnabled ? 'Yes' : 'No'}</Td>
        //                                 <Td>{user.addressETH}</Td>
        //                                 <Td>{user.inviteCode}</Td>
        //                                 <Td>{user.membershipLevel}</Td>
        //                                 <Td>{user.invitedBy ? user.invitedBy.email : 'N/A'}</Td>
        //                                 <Td>{user.invitedBy ? user.invitedBy.id : 'N/A'}</Td>
        //                                 {/* <Td>{formattedDate}</Td> */}
        //                             </Tr>
        //                         );
        //                     })}
        //                 </Tbody>
        //             </Table>
        //         </TableContainer>
        //     </Box>
        //     <FooterWithFourColumns />
        // </Box>
        <Box bg={pageBgColor}>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={10}>
                <VStack spacing={10} align="stretch">
                    <Box
                        bg={bgColor}
                        boxShadow="xl"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h1" size="xl" mb={6}>
                            User Growth
                        </Heading>
                        <StatGroup>
                            <Stat>
                                <StatLabel>New Users (Last 24 Hours)</StatLabel>
                                <StatNumber>{summaryStats.usersLast24Hours}</StatNumber>
                            </Stat>
                            <Stat>
                                <StatLabel>New Users (Last 7 Days)</StatLabel>
                                <StatNumber>{summaryStats.usersLast7Days}</StatNumber>
                                <StatHelpText>{summaryStats.growthRate7Days}% of total users</StatHelpText>
                            </Stat>
                            <Stat>
                                <StatLabel>New Users (Last 30 Days)</StatLabel>
                                <StatNumber>{summaryStats.usersLast30Days}</StatNumber>
                                <StatHelpText>{summaryStats.growthRate30Days}% of total users</StatHelpText>
                            </Stat>
                        </StatGroup>
                        <Box mt={6} p={4} borderWidth={1} borderRadius="md" borderColor={borderColor} bg={insightsBgColor}>
                            <Heading as="h3" size="md" mb={4}>Platform Growth Insights</Heading>
                            <Text>
                                🚀 Our platform is growing! We've welcomed {summaryStats.usersLast24Hours} new users in the last 24 hours.
                            </Text>
                            <Text mt={2}>
                                📈 In the past week, we've seen a {summaryStats.growthRate7Days}% increase in our user base.
                            </Text>
                            <Text mt={2}>
                                🎉 Keep up the great work! Let's aim to increase our daily sign-ups and engage our new users.
                            </Text>
                        </Box>
                    </Box>




                    <Box
                        bg={bgColor}
                        boxShadow="lg"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h2" size="lg" mb={6}>
                            User Statistics
                        </Heading>
                        <StatGroup>
                            <Stat>
                                <StatLabel>Total Users</StatLabel>
                                <StatNumber>{summaryStats.totalUsers || 0}</StatNumber>
                            </Stat>
                            <Stat>
                                <StatLabel>Total Invites</StatLabel>
                                <StatNumber>{summaryStats.totalInvites || 0}</StatNumber>
                            </Stat>
                            <Stat>
                                <StatLabel>Users with MFA</StatLabel>
                                <StatNumber>{summaryStats.usersWithMfa || 0}</StatNumber>
                                <StatHelpText>({summaryStats.mfaPercentage || '0'}%)</StatHelpText>
                            </Stat>
                        </StatGroup>
                    </Box>

                    <Box
                        bg={bgColor}
                        boxShadow="lg"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h2" size="lg" mb={6}>
                            Top Inviters
                        </Heading>
                        <VStack spacing={8} align="stretch">
                            <Box>
                                <Heading as="h3" size="md" mb={4}>
                                    By Email
                                </Heading>
                                <TableContainer>
                                    <Table variant="simple" size="sm">
                                        <Thead>
                                            <Tr>
                                                <Th>Email</Th>
                                                <Th isNumeric>Invitations</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {Object.entries(invitationStats)
                                                .sort(([, a], [, b]) => b.count - a.count)
                                                .slice(0, 5)
                                                .map(([inviterEmail, stats]) => (
                                                    <Tr key={inviterEmail}>
                                                        <Td>{inviterEmail}</Td>
                                                        <Td isNumeric>{stats.count}</Td>
                                                    </Tr>
                                                ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box>
                                <Heading as="h3" size="md" mb={4}>
                                    By ID
                                </Heading>
                                <TableContainer>
                                    <Table variant="simple" size="sm">
                                        <Thead>
                                            <Tr>
                                                <Th>ID</Th>
                                                <Th>Email</Th>
                                                <Th isNumeric>Invitations</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {Object.entries(invitationStatsById)
                                                .sort(([, a], [, b]) => b.count - a.count)
                                                .slice(0, 5)
                                                .map(([inviterId, stats]) => (
                                                    <Tr key={inviterId}>
                                                        <Td>{inviterId}</Td>
                                                        <Td>{stats.email}</Td>
                                                        <Td isNumeric>{stats.count}</Td>
                                                    </Tr>
                                                ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </VStack>
                    </Box>

                    <Box
                        bg={bgColor}
                        boxShadow="lg"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h2" size="lg" mb={6}>
                            All Users
                        </Heading>
                        <TableContainer>
                            <Table variant="simple" size="sm">
                                <TableCaption>All users in the system</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th>Email</Th>
                                        <Th>Created At</Th>
                                        <Th>Name</Th>
                                        <Th>MFA</Th>
                                        <Th>Invite Code</Th>
                                        <Th>Invited By</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {data.getAllUsers.map((user: User) => {
                                        const createdDate = new Date(parseInt(user.createdAt));
                                        const formattedDate = createdDate.toLocaleString();
                                        return (
                                            <Tr key={user.id}>
                                                <Td>{user.email}</Td>
                                                <Td>{formattedDate}</Td>
                                                <Td>{`${user.firstName} ${user.lastName}`}</Td>
                                                <Td>
                                                    <Badge colorScheme={user.isMfaEnabled ? 'green' : 'red'}>
                                                        {user.isMfaEnabled ? 'Enabled' : 'Disabled'}
                                                    </Badge>
                                                </Td>
                                                <Td>{user.inviteCode}</Td>
                                                <Td>{user.invitedBy ? user.invitedBy.email : 'N/A'}</Td>
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>




                    <Box
                        bg={bgColor}
                        boxShadow="lg"
                        borderRadius="lg"
                        p={6}
                        borderWidth={1}
                        borderColor={borderColor}
                    >
                        <Heading as="h2" size="lg" mb={6}>
                            User Growth Over Time
                        </Heading>
                        <VStack spacing={8}>
                            <Box width="100%">
                                <Heading as="h3" size="md" mb={4}>
                                    Users per Week
                                </Heading>
                                <TableContainer>
                                    <Table variant="simple" size="sm">
                                        <Thead>
                                            <Tr>
                                                <Th>Week</Th>
                                                <Th isNumeric>New Users</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {Object.entries(summaryStats.usersPerWeek)
                                                .sort(([a], [b]) => a.localeCompare(b))
                                                .map(([week, count]) => (
                                                    <Tr key={week} bg={week === currentWeek ? "yellow.100" : undefined}>
                                                        <Td>{week}</Td>
                                                        <Td isNumeric>{count}</Td>
                                                    </Tr>
                                                ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box width="100%">
                                <Heading as="h3" size="md" mb={4}>
                                    Users per Month
                                </Heading>
                                <TableContainer>
                                    <Table variant="simple" size="sm">
                                        <Thead>
                                            <Tr>
                                                <Th>Month</Th>
                                                <Th isNumeric>New Users</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {Object.entries(summaryStats.usersPerMonth)
                                                .sort(([a], [b]) => a.localeCompare(b))
                                                .map(([month, count]) => (
                                                    <Tr key={month} bg={month === currentMonth ? "yellow.100" : undefined}>
                                                        <Td>{month}</Td>
                                                        <Td isNumeric>{count}</Td>
                                                    </Tr>
                                                ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
            <FooterWithFourColumns />
        </Box>
    );
};

export default AllUsers;
export const categories = [
  {
    name: 'Weekly Food Shoping',
    imageUrl:
      '/shop_categories/food.png',
    loggedin_url: '/marketplace',
    url: '/login',
  },
  {
    name: 'Holidays',
    imageUrl:
      '/shop_categories/holidays.png',
    loggedin_url: '/marketplace',
    url: '/login',
  },
  {
    name: 'Life Extention',
    imageUrl:
      '/shop_categories/life_extention.png',
    loggedin_url: '/marketplace',
    url: '/login',
  },
  {
    name: 'Enviroment Monitoring',
    imageUrl:
      '/shop_categories/enviroment_monitoring.png',
    loggedin_url: '/marketplace',
    url: '/login',
  },
  {
    name: 'Group Activities',
    imageUrl:
      '/shop_categories/group_activities.png',
    loggedin_url: '/marketplace',
    url: '/login',
  },
  {
    name: 'Land',
    imageUrl:
      '/shop_categories/land.png',
    loggedin_url: '/marketplace',
    url: '/login',
  },
  {
    name: 'Precious Metals',
    imageUrl:
      '/shop_categories/metals.png',
    loggedin_url: '/marketplace',
    url: '/login',
  },
  {
    name: 'Home Schooling Deals',
    imageUrl:
      '/shop_categories/home_schooling_deals.png',
    loggedin_url: '/marketplace',
    url: '/login',
  },
  // {
  //   name: 'Baby',
  //   imageUrl:
  //     'https://images.unsplash.com/photo-1574175679797-9fc9eade1450?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGJhYnklMjB0b3lzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
  //   url: '#',
  // },
  // {
  //   name: 'Flooring',
  //   imageUrl:
  //     'https://images.unsplash.com/photo-1581858726788-75bc0f6a952d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80',
  //   url: '#',
  // },
]

export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never

export type Category = ElementType<typeof categories>
